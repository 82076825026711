import React from "react";
import { useSelector } from "react-redux";

import { Preloader, SeatItem } from "../../../../components";
import { hallsSelector } from "../../../../redux/halls/selectors";
import { setSelectedHalls } from "../../../../redux/halls/slice";

import { ISelectedHall, ITime } from "../../../../redux/halls/types";
import { useAppDispatch } from "../../../../redux/store";
import { setSelectedHallsToStorage } from "../../../../utils/storage/halls";

import styles from "./TimesTable.module.scss";

export const TimesTable: React.FC = () => {
  const { date, lastSelectedHall, hallTimes, isLoading } = useSelector(hallsSelector);
  const dispatch = useAppDispatch();

  const seats = renderSeats(hallTimes);

  function renderSeats(hallTimes: ITime[]) {
    return hallTimes.map((time) => {
      const timesUpThisSeat = seatTimesUp(time.title);
      const unAvailableThisSeat = time.active !== 1;

      return (
        <div key={time.id}>
          <SeatItem
            data={{
              date: date,
              id: lastSelectedHall?.id,
              time: time.title,
              hallTitle: lastSelectedHall?.title,
              price: time.price,
            }}
            onClickFunc={chooseHallTime}
            disabled={timesUpThisSeat || unAvailableThisSeat}
          />
        </div>
      );
    });
  }

  function chooseHallTime(newSelectedHalls: { [key: string]: ISelectedHall }) {
    dispatch(setSelectedHalls(newSelectedHalls));
    setSelectedHallsToStorage(newSelectedHalls);
  }

  function seatTimesUp(seatTime: string) {
    const currentDay = new Date(Date.parse(date)).getDate();
    const nowDay = new Date().getDate();
    if (currentDay !== nowDay) return false;

    const seatHour = +seatTime.substring(0, 2).replace(":", "");

    const seatDate = new Date();
    seatDate.setHours(seatHour, 0, 0, 0);

    return seatDate < new Date();
  }

  return (
    <div className={styles.wrapper}>
      {isLoading ? <Preloader /> : <div className={styles.table}>{seats}</div>}
    </div>
  );
};
