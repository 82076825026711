import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FormNames } from "../../../../resources/constants";
import { IChangePassState, ICodeResp, IPasswordsPayload } from "./types";

import { fetchSendPhone, fetchSendCode, fetchSendNewPassword } from "./asyncActions";

const initialState: IChangePassState = {
  token: "", // why we got it?! Token doesn't work on whis process -___-
  phone: "",
  email: "",
  code: "",
  password: "",
  passwordRepeat: "",
  showForm: "",
  isLoading: false,
};

const changePassSlice = createSlice({
  name: "changePass",
  initialState,
  reducers: {
    addPhone(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
    addCode(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
    addEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    addPassword(state, action: PayloadAction<IPasswordsPayload>) {
      state.password = action.payload.password;
      state.passwordRepeat = action.payload.passwordRepeat;
    },
    setShowForm(state, action: PayloadAction<string>) {
      state.showForm = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Send phone
    builder.addCase(fetchSendPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSendPhone.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.showForm = action.payload;
    });
    builder.addCase(fetchSendPhone.rejected, (state) => {
      state.isLoading = false;
      state.showForm = FormNames.ERROR;
    });
    // Send code
    builder.addCase(fetchSendCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchSendCode.fulfilled,
      (state, action: PayloadAction<ICodeResp>) => {
        state.isLoading = false;
        state.showForm = action.payload.form;
        state.token = action.payload.token; // why we got it?! Token doesn't work on whis process -___-
      }
    );
    builder.addCase(fetchSendCode.rejected, (state) => {
      state.isLoading = false;
      state.showForm = FormNames.ERROR;
    });
    // Send new password
    builder.addCase(fetchSendNewPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchSendNewPassword.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.showForm = action.payload;
      }
    );
    builder.addCase(fetchSendNewPassword.rejected, (state) => {
      state.isLoading = false;
      state.showForm = FormNames.ERROR;
    });
  },
});

export const { addPhone, addCode, addPassword, addEmail, setShowForm, reset } =
  changePassSlice.actions;

export default changePassSlice.reducer;
