import React from "react";
import { Field } from "formik";
import { nanoid } from "nanoid";

import styles from "./PhoneInput.module.scss";

import { phoneMask } from "../../../utils/form/phoneMask";
import { FieldPropsType, PhoneInputPropsType } from "./types";

export const PhoneInput: React.FC<PhoneInputPropsType> = (props) => {
  const {
    name,
    focusThisInput = false,
    placeholder,
    onChangeFunc,
    isFilledFormsTest,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (focusThisInput) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldProps: FieldPropsType,
    name: string
  ) => {
    const { form } = fieldProps;
    if (onChangeFunc) onChangeFunc({ phone: phoneMask(e) });
    form.setFieldValue(name, phoneMask(e));
  };

  return (
    <Field name={name} >
      {(fieldProps: FieldPropsType) => {
        const { field, form, meta } = fieldProps;
        return (
          <div>
            <input
              ref={inputRef}
              type="tel"
              id={`${name}-${nanoid()}`}
              inputMode="numeric"
              placeholder={placeholder}
              className={`${styles.input}
							${meta.touched && meta.error ? styles.error : null}
							${isFilledFormsTest && meta.error ? styles.error : null}`}
              {...field}
              onChange={(e) => handleChange(e, fieldProps, name)}
              onFocus={(e) => handleChange(e, fieldProps, name)}
            />
          </div>
        );
      }}
    </Field>
  );
};
