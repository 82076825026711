import React from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { addPassword } from "../../../../redux/profile/settings/changePass/slice";
import { changePassSelector } from "../../../../redux/profile/settings/changePass/selectors";
import { fetchSendNewPassword } from "../../../../redux/profile/settings/changePass/asyncActions";

import { Button, PasswordInput } from "../../../../components";

import styles from "./NewPasswordForm.module.scss";

import passwordIcon from "../../../../assets/icons/changePasswordIcon.svg";

import { ValidMessages } from "../../../../resources/locale";
import { SubmitValueType } from "./types";

export const NewPasswordForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { phone, isLoading } = useSelector(changePassSelector);

  const initialValues = {
    password: "",
    passwordRepeat: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(ValidMessages.inputs.password.REQUIRED)
      .min(6, ValidMessages.inputs.password.NOT_FULL),
    passwordRepeat: Yup.string()
      .required(ValidMessages.inputs.passwordRepeat.REQUIRED)
      .oneOf([Yup.ref("password"), null], ValidMessages.inputs.passwordRepeat.NOT_MATCH),
  });

  const handleSubmit = (values: SubmitValueType) => {
    const data = Object.assign({ phone }, values);
    dispatch(addPassword(values));
    dispatch(fetchSendNewPassword(data));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles.formSection}>
              <label htmlFor="password">
                <img src={passwordIcon} alt="password" />
              </label>
              <PasswordInput
                name="password"
                focusThisInput={true}
                placeholder="Введите новый пароль"
              />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="password"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="passwordRepeat">
                <img src={passwordIcon} alt="password" />
              </label>
              <PasswordInput name="passwordRepeat" placeholder="Повторите пароль" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="passwordRepeat"
              />
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={
                // !formik.dirty ||
                formik.isSubmitting
              }
            >
              Готово
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
