import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import AuthCode from 'react-auth-code-input';

import { useAppDispatch } from "../../../../redux/store";
import { addCode } from "../../../../redux/profile/settings/changePass/slice";
import { changePassSelector } from "../../../../redux/profile/settings/changePass/selectors";
import {} from "../../../../redux/profile/settings/changePass/asyncActions";

import { Button, CodeInput } from "../../../../components";

import styles from "./SmsRegCodeForm.module.scss";

import { ValidMessages } from "../../../../resources/locale";
import { SubmitValueType } from "./types";
import {
  fetchApplyCode,
  fetchRequestCode,
} from "../../../../redux/profile/authAndReg/asyncActions";
import {useInterval} from "../../../../hooks";

export const SmsRegCodeForm: React.FC = () => {
  const { phone, isLoading, email} = useSelector(changePassSelector);
  const dispatch = useAppDispatch();
  const [timer, setTimer] = React.useState(60)

  const initialValues = {
    code: [""],
  };

  const validationSchema = Yup.object({
    code: Yup.array().of(Yup.string().required("Required")),
  });

  const handleSubmit = (values: SubmitValueType) => {
    const code = values.code.join("");

    const data = {
      phone,
      code,
      email: `${email}`
    };
    dispatch(addCode(code));
    dispatch(fetchApplyCode(data));
  };

  useInterval(() => {
    setTimer(timer - 1)
  }, timer > 0? 1000 : null)

  const testClick = async () => {
    await dispatch(fetchRequestCode({ phone: phone }));
  };

  const errorValidateRender = (errors: string | string[] | undefined) => {
    let notFullCode = false;

    if (errors) {
      Array.from(errors).forEach((item) => {
        if (item === undefined) {
          notFullCode = true;
        }
      });
    }

    if (errors !== undefined) {
      if (notFullCode) {
        return (
          <div className={styles.errorMessage}>
            {ValidMessages.inputs.pinCode.NOT_FULL}
          </div>
        );
      } else {
        return (
          <div className={styles.errorMessage}>
            {ValidMessages.inputs.pinCode.REQUIRED}
          </div>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => {
        const errorMessageValidate = errorValidateRender(formik.errors.code);
        return (
          <Form>
            <div className={styles.formSection}>
              <div className={styles.inputsWrapper}>
                <AuthCode autoFocus={true} containerClassName={styles.inputsWrapper} length={4} inputClassName={styles.input} allowedCharacters='numeric' onChange={(v)=>{formik.setValues({code:[v]})}} />
              </div>
              {errorMessageValidate}
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <p className={styles.description}>
              На указанный телефон будет отправлено смс с кодом для регистрации
            </p>
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={
                // !formik.dirty ||
                formik.isSubmitting
              }
            >
              Далее
            </Button>
            <div className={styles.description}>
              Не пришел код? <br/>
              {
                timer !== 0?
                  <span className={styles.timer}>Отправить повторно через <span className={styles.timer_time}>{timer} секунд</span></span>
                  :
                  <span className={styles.resend} onClick={async () => {
                    await testClick();
                    setTimer(60)
                  }}>Отправить повторно</span>
              }
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
