import React from "react";
import { Field } from "formik";

import styles from "./EmailInput.module.scss";

import { FieldPropsType, EmailInputPropsType } from "./types";

export const EmailInput: React.FC<EmailInputPropsType> = (props) => {
  const { name, focusThisInput = false, placeholder } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (focusThisInput) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Field name={name}>
      {(fieldProps: FieldPropsType) => {
        const { field, meta } = fieldProps;
        return (
          <div>
            <input
              ref={inputRef}
              type="email"
              id={name}
              placeholder={placeholder}
              // autoComplete="on"
              className={`${styles.input} ${
                meta.touched && meta.error ? styles.error : null
              } `}
              {...field}
            />
          </div>
        );
      }}
    </Field>
  );
};
