const getFullValue = (e: React.ChangeEvent<HTMLInputElement> | any) => {
  // type any - cant resolve it
  const inputValueFull = e.currentTarget.value;
  const inputValueCurrent = e.nativeEvent.data;

  if (inputValueFull.length < 2 && inputValueCurrent === "+") {
    return "+";
  } else {
    return inputValueFull.replace(/\D/g, "");
  }
};

export const phoneMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  const fullValue = getFullValue(e);
  const firstSimbol = fullValue[0] || '7';

  let resultInputValue;

  if (["7", "8", "9"].includes(firstSimbol)) {
    switch (firstSimbol) {
      case "7":
        resultInputValue = "+7";
        break;
      case "8":
        resultInputValue = "+7";
        break;
      case "9":
        resultInputValue = "+7 (9";
    }

    if (fullValue.length > 1) {
      resultInputValue += " (" + fullValue.slice(1, 4);
    }
    if (fullValue.length >= 5) {
      resultInputValue += ") " + fullValue.slice(4, 7);
    }
    if (fullValue.length >= 8) {
      resultInputValue += "-" + fullValue.slice(7, 9);
    }
    if (fullValue.length >= 10) {
      resultInputValue += "-" + fullValue.slice(9, 11);
    }
  } else if (firstSimbol === "+") {
    resultInputValue = "+";
  } else if (!fullValue) {
    resultInputValue = "";
  } else {
    resultInputValue = "+ 7 (" + fullValue.substring(0, 16);
  }

  return resultInputValue;
};
