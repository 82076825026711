import React from "react";
import { Field } from "formik";

import styles from "./TextareaInput.module.scss";

import { TextareaInputPropsType } from "./types";

export const TextareaInput: React.FC<TextareaInputPropsType> = (props) => {
  const { name, placeholder, className = null } = props;

  const classes = `${styles.input} ${className}`;

  return (
    <Field
      id={name}
      name={name}
      placeholder={placeholder}
      as="textarea"
      className={classes}
    />
  );
};
