import React from "react";
import debounce from "lodash.debounce";

export const useCurrentWidth = () => {
  const [windowWidth, setWindowWidth] = React.useState(
    document.documentElement.clientWidth
  );

  React.useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", debounceResizeWindow);

    return () => {
      window.removeEventListener("resize", debounceResizeWindow);
    }; // eslint-disable-next-line
  }, []);

  const resizeWindow = () => {
    setWindowWidth(document.documentElement.clientWidth);
  };

  const debounceResizeWindow = React.useCallback(debounce(resizeWindow, 200), []);

  return windowWidth;
};
