import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Styles from "./About.module.scss"
import {Header, Title} from "../../components";
import {AboutPropsType} from "./types";
import {useCurrentWidth} from "../../hooks";
import {MediaBreakPoints} from "../../resources/constants";
import arrowRight from "../../assets/icons/MobileArrow.svg"

export const About: React.FC<AboutPropsType> = ({title}) => {
    const navigate = useNavigate();
    const windowWidth = useCurrentWidth();
    const breakPoint = MediaBreakPoints.MOBILE_LARGE;
    const [activePage, setActivePage] = useState("");
    const [titleMobile, setTitle] = useState('О клубе');

    const handleNavigate = (route: string) => {
        setActivePage(route);
        navigate(route);
    };
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if(windowWidth >= breakPoint && location.pathname === "/about"){
            navigate("clubFoto")
            setActivePage("clubFoto")
        }
        else if(location.pathname === "/about/clubFoto"){
            setActivePage("clubFoto")
        }
        else if(location.pathname === "/about/hallsFoto"){
            setActivePage("hallsFoto")
        }
    },[])

    const handleClickPhoto = () => {
        navigate("clubFoto")
        setTitle("Фото клуба")
    }
    const handleClickSchema = () => {
        navigate("hallsFoto")
        setTitle("Фото спортивных залов")
    }

    return (
        <>
            <main>
                {
                    windowWidth > breakPoint ? (
                        <Header title={title} />
                    ) : (
                        <Header title={titleMobile} />
                    )
                }

                <section className="container">
                    <Title>{title}</Title>
                    {windowWidth > breakPoint ? (
                        <div className={Styles.wrapper}>
                            <div>
                                    <nav>
                                        <ul className={`${Styles.wrapper_menu}`}>
                                            <li
                                                className={activePage === "clubFoto" ? Styles.history : `${Styles.history_Opacity}`}
                                                onClick={() => handleNavigate("clubFoto")}
                                            >
                                                Фото клуба
                                            </li>
                                            <li
                                                className={activePage === "hallsFoto" ? Styles.cards : `${Styles.cards_Opacity}`}
                                                onClick={() => handleNavigate("hallsFoto")}
                                            >
                                                Фото спортивных залов
                                            </li>
                                        </ul>
                                    </nav>
                            </div>
                                <Outlet/>
                        </div>
                    ) : (
                        location.pathname === "/about" && windowWidth < breakPoint ? (
                            <div className={Styles.mobileMenu}>
                                <div onClick={handleClickPhoto} className={Styles.mobileItem}>
                                    Фото клуба
                                    <div>
                                        <img className={`${Styles.mobileItem_icon}`} src={arrowRight}/>
                                    </div>
                                </div>
                                <div onClick={handleClickSchema} className={Styles.mobileItem}>
                                    Фото спортивных залов
                                    <div>
                                        <img className={`${Styles.mobileItem_icon}`} src={arrowRight}/>
                                    </div>
                                </div>
                            </div>
                        ) :  <div className={Styles.wrapper}>
                            <Outlet/>
                        </div>
                    )
                    }
                </section>
            </main>
        </>
    );
};

