import React from "react";
import { useLocation } from "react-router-dom";

import { Button, Header } from "../../../components";

import styles from "./PaymentSuccess.module.scss";
import paymentErrorImg from "../../../assets/img/paymentErrorImg.svg";

export const PaymentError: React.FC = () => {
  const location = useLocation();

  return (
    <>
      <Header title={""} />
      <main>
        <section className="container">
          <p className={styles.subTitile}>Неудачная оплата</p>
          <p className={styles.subTitile}>{location.state ? location.state : null}</p>
          <div className={styles.image}>
            <img src={paymentErrorImg} alt="payment success" />
          </div>
          <div className={styles.linkButtons}>
            <Button newStyles={styles.button} to="/cart">
              Корзина
            </Button>
            <Button newStyles={styles.button} to="/subscriptions">
              Купить абонемент
            </Button>
            <Button newStyles={styles.button} to="/halls">
              Арендовать зал
            </Button>
          </div>
        </section>
      </main>
    </>
  );
};
