// Adult

export const setSelectedAdultSubscrToStorage = (date: string) => {
  sessionStorage.setItem("selectedAdultSubscr", date);
};

export const getSelectedAdultSubscrFromStorage = () => {
  const data = sessionStorage.getItem("selectedAdultSubscr");
  if (data) return JSON.parse(data);
};

export const removeSelectedAdultSubscrFromStorage = () => {
  sessionStorage.removeItem("selectedAdultSubscr");
};

// Kid

export const setSelectedKidSubscrToStorage = (date: string) => {
  sessionStorage.setItem("selectedKidSubscr", date);
};

export const getSelectedKidSubscrFromStorage = () => {
  const data = sessionStorage.getItem("selectedKidSubscr");
  if (data) return JSON.parse(data);
};

export const removeSelectedKidSubscrFromStorage = () => {
  sessionStorage.removeItem("selectedKidSubscr");
};

// Family

export const setSelectedFamilySubscrToStorage = (date: string) => {
  sessionStorage.setItem("selectedFamilySubscr", date);
};

export const getSelectedFamilySubscrFromStorage = () => {
  const data = sessionStorage.getItem("selectedFamilySubscr");
  if (data) return JSON.parse(data);
};

export const removeSelectedFamilySubscrFromStorage = () => {
  sessionStorage.removeItem("selectedFamilySubscr");
};

// selectedSubscr

export const setSelectedSubscrToStorage = (date: object) => {
  sessionStorage.setItem("selectedSubscr", JSON.stringify(date));
};

export const getSelectedSubscrFromStorage = () => {
  const data = sessionStorage.getItem("selectedSubscr");
  if (data) return JSON.parse(data);
};

export const removeSelectedSubscrFromStorage = () => {
  sessionStorage.removeItem("selectedSubscr");
};

// subscrFormsData

export const setSubscrFormsDataToStorage = (date: object) => {
  sessionStorage.setItem("subscrFormsData", JSON.stringify(date));
};

export const getSubscrFormsDataFromStorage = () => {
  const data = sessionStorage.getItem("subscrFormsData");
  if (data) return JSON.parse(data);
};

export const removeSubscrFormsDataFromStorage = () => {
  sessionStorage.removeItem("subscrFormsData");
};
