import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSubscrFormsDataToStorage } from "../../utils";

import { fetchGetSubscriptions } from "./asyncActions";
import {
  ISelectedAdultSubscr,
  ISelectedFamilySubscr,
  ISelectedKidSubscr,
  ISelectedSubscr,
  ISubscrFormData,
  ISubscription,
  ISubscriptionsState,
} from "./types";

const initialState: ISubscriptionsState = {
  subscriptions: [],
  selectedAdultSubscr: [],
  selectedKidSubscr: [],
  selectedFamilySubscr: [],
  selectedSubscr: {},
  subscrFormsData: [],
  isLoading: false,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSelectedAdultSubscr(state, action: PayloadAction<ISelectedAdultSubscr>) {
      state.selectedAdultSubscr = state.selectedAdultSubscr.filter(
        (item) => item.subscription.id !== action.payload.subscription.id
      );

      if (action.payload.quantity !== 0) state.selectedAdultSubscr.push(action.payload);
    },
    setSelectedKidSubscr(state, action: PayloadAction<ISelectedKidSubscr>) {
      state.selectedKidSubscr = state.selectedKidSubscr.filter(
        (item) => item.subscription.id !== action.payload.subscription.id
      );

      if (action.payload.quantity !== 0) state.selectedKidSubscr.push(action.payload);
    },
    setSelectedFamilySubscription(state, action: PayloadAction<ISelectedFamilySubscr>) {
      state.selectedFamilySubscr = state.selectedFamilySubscr.filter(
        (item) => item.subscription.id !== action.payload.subscription.id
      );

      if (action.payload.quantity[0] !== 0) state.selectedFamilySubscr.push(action.payload);
    },
    setSubscrFormsData(state, action: PayloadAction<ISubscrFormData>) {
      const curSubscrWithProfile = state.subscrFormsData.find((item) => {
        return (
          item.subscrData.id === action.payload.subscrData.id &&
          item.subscrNum === action.payload.subscrNum
        );
      });

      if (!curSubscrWithProfile) {
        state.subscrFormsData.push(action.payload);
      } else {
        curSubscrWithProfile.profileValues = {
          ...curSubscrWithProfile.profileValues,
          ...action.payload.profileValues,
        };
      }
      setSubscrFormsDataToStorage(state.subscrFormsData);
    },
    setSelectedSubscr(state, action: PayloadAction<{ [key: string]: ISelectedSubscr }>) {
      state.selectedSubscr = action.payload;
    },

    resetSelectedAdultSubscr(state) {
      state.selectedAdultSubscr = [];
    },
    resetSelectedKidSubscr(state) {
      state.selectedKidSubscr = [];
    },
    resetSelectedFamilySubscr(state) {
      state.selectedFamilySubscr = [];
    },
    resetSelectedSubscr(state) {
      state.selectedSubscr = {};
    },
    resetSubscrFormsData(state) {
      state.subscrFormsData = [];
    },
  },
  extraReducers: (builder) => {
    // Get Subscriptions
    builder.addCase(fetchGetSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchGetSubscriptions.fulfilled,
      (state, action: PayloadAction<ISubscription[]>) => {
        state.isLoading = false;
        state.subscriptions = action.payload;
      }
    );
    builder.addCase(fetchGetSubscriptions.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setSelectedAdultSubscr,
  setSelectedKidSubscr,
  setSelectedFamilySubscription,
  setSelectedSubscr,
  setSubscrFormsData,
  resetSelectedAdultSubscr,
  resetSelectedKidSubscr,
  resetSelectedFamilySubscr,
  resetSelectedSubscr,
  resetSubscrFormsData,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
