import React, { useEffect, useState } from "react";
import Styles from "./MyProfilesList.module.scss";
import PlusIcon from "../../assets/icons/plus.svg";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import preloader from "../../assets/img/load.gif";
import { CardListPropsType } from "./types";
import {
  fetchAddProfile,
  fetchMyProfiles,
} from "../../redux/profile/profileInfo/asyncActions";
import { getProfileInfoMenu } from "../../redux/profile/profileInfo/selectors";
import { MyProfileItem } from "../MyProfilesItem/MyProfiles";
import { Modal } from "../Modal";
import { ProfileAdultForm } from "../ProfileAdultForm";
import { ProfileKidForm } from "../ProfileKidForm";
import { Button } from "../Button";
import { IMyprofile } from "../../redux/profile/profileInfo/types";

export const MyProfilesList: React.FC<CardListPropsType> = ({
  onPage = "profile",
  selectFunc,
  filterType,
}) => {
  const { myProfiles, isLoading } = useSelector(getProfileInfoMenu);
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [clicked, setIsClicked] = React.useState<boolean>(false);
  const [isAdult, setIsAdult] = useState(true);
  const [errorStatus, setErrorStatus] = React.useState<boolean>(false);

  let selectedItem: any = null;

  useEffect(() => {
    dispatch(fetchMyProfiles());
  }, []);

  const handleAddClick = () => {
    setIsOpenModal(true);
  };

  const addSelectStyles = React.useCallback((e: any) => {
    if (onPage === "payment") {
      const target = e.target.closest("[data-type]");

      if (target) {
        if (selectedItem) {
          selectedItem.style.boxShadow = "";
        }

        selectedItem = target;
        selectedItem.style.boxShadow = "2px 2px 15px rgba(140, 179, 70, 1)";
      }
    }
  }, []);

  const handleClickOtherMethods = (e: any) => {
    addSelectStyles(e);
  };

  const handleSelectItem = (profileData: IMyprofile) => {
    if (selectFunc) selectFunc(profileData);
  };

  const handleAdultClick = () => {
    setIsAdult(true);
    setIsClicked(false);
  };

  const handleKidClick = () => {
    setIsAdult(false);
    setIsClicked(true);
  };

  return (
      <>
        <div className={Styles.wrapper}>
          {onPage === "profile" ? <p className={Styles.subTitile}>Мои Профили</p> : null}
          {isLoading ? (
              <div className={Styles.preloader}>
                <img className={Styles.preloader_img} src={preloader} alt="preloader" />
              </div>
          ) : (
              <div className={Styles.item} onClick={addSelectStyles}>
                {myProfiles.length > 0 &&
                    myProfiles.map((item) => {
                      return filterType && item.type !== filterType ? null : (
                          <MyProfileItem
                              data={item}
                              key={item.id}
                              dataType="profileItem"
                              onPage={onPage}
                              selectFunc={handleSelectItem}
                          />
                      );
                    })}
                {
                  onPage === "profile" ? (
                      <div onClick={handleAddClick} className={`${Styles.item_plusItem}`}>
                        <img src={PlusIcon} />
                        <span className={Styles.title}>Добавить еще профиль</span>
                      </div>
                  ) : null
                }
              </div>
          )}
          {isOpenModal ? (
              <Modal
                  title="Новый профиль"
                  isOpen={isOpenModal}
                  setIsOpen={setIsOpenModal}
                  subTitle=""
              >
                {clicked ? (
                    <div className={Styles.newStyles}>
                      <Button newStyles={Styles.greyButton} onClickFunc={handleAdultClick}>
                        Взрослый
                      </Button>
                      <Button newStyles={Styles.activeButton} onClickFunc={handleKidClick}>
                        Детский
                      </Button>
                    </div>
                ) : (
                    <div className={Styles.modalButton}>
                      <Button newStyles={Styles.activeButton} onClickFunc={handleAdultClick}>
                        Взрослый
                      </Button>
                      <Button newStyles={Styles.greyButton} onClickFunc={handleKidClick}>
                        Детский
                      </Button>
                    </div>
                )}
                <div className={Styles.textModal}>Заполните данные нового профиля</div>
                {isAdult ? (
                    <ProfileAdultForm
                        page={"profile"}
                        error={errorStatus}
                        close={async (values) => {
                          const status = await dispatch(
                              fetchAddProfile({ data: values, type: "adult" })
                          );
                          if (status.payload !== 422) {
                            setErrorStatus(false);
                            await dispatch(fetchMyProfiles());
                            setIsOpenModal(false);
                          } else {
                            setErrorStatus(true);
                          }
                        }}
                    />
                ) : (
                    <ProfileKidForm
                        page={"profile"}
                        close={async (values) => {
                          await dispatch(fetchAddProfile({ data: values, type: "kid" }));
                          await dispatch(fetchMyProfiles());
                          setIsOpenModal(false);
                        }}
                    />
                )}
              </Modal>
          ) : null}
        </div>
      </>

  );
};
