import { Helmet } from "react-helmet"
import { MetaSettings } from "./metaSettings"
interface Props{
  path:string
}
const MetaTags = (props:Props)=>{
  console.log(props.path)

  return(
    <Helmet>
      {
        props.path == '/'?
          <title>Tennis.ru - Фитнес-клуб</title>
          :
          <title>Tennis.ru - Фитнес-клуб - {MetaSettings[props.path as keyof typeof MetaSettings]?.title || ''}</title>
      }
    </Helmet>
  )
}
export default MetaTags
