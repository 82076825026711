import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WindowNames } from "../../../resources/constants";

import { fetchAuth, fetchReg, fetchApplyCode } from "./asyncActions";

import { IAuthAndReg, IRespData } from "./types";

const initialState: IAuthAndReg = {
  auth: false,
  isLoading: false,
  showWindow: "",
};

const authAndRegSlice = createSlice({
  name: "authAndReg",
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<string | null>) {
      state.auth = action.payload ? true : false;
    },
    setShowWindow(state, action: PayloadAction<string>) {
      state.showWindow = action.payload;
    },
    resetShowWindow(state) {
      state.showWindow = "";
    },
  },
  extraReducers: (builder) => {
    // auth
    builder.addCase(fetchAuth.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAuth.fulfilled, (state, action: PayloadAction<IRespData>) => {
      state.isLoading = false;
      state.auth = action.payload.auth;
      if (action.payload.showWindow) state.showWindow = action.payload.showWindow;
    });
    builder.addCase(fetchAuth.rejected, (state) => {
      state.isLoading = false;
      state.auth = false;
      state.showWindow = WindowNames.ERROR;
    });
    // reg
    builder.addCase(fetchReg.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReg.fulfilled, (state, action: PayloadAction<IRespData>) => {
      state.isLoading = false;
      state.auth = action.payload.auth;
      if (action.payload.showWindow) state.showWindow = action.payload.showWindow;
    });
    builder.addCase(fetchReg.rejected, (state) => {
      state.isLoading = false;
      state.showWindow = WindowNames.ERROR;
    });
    // code apply
    builder.addCase(fetchApplyCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchApplyCode.fulfilled,
      (state, action: PayloadAction<IRespData>) => {
        state.isLoading = false;
        state.auth = action.payload.auth;
        if (action.payload.showWindow) state.showWindow = action.payload.showWindow;
      }
    );
    builder.addCase(fetchApplyCode.rejected, (state) => {
      state.isLoading = false;
      state.showWindow = WindowNames.ERROR;
    });
  },
});

export const { setAuth, setShowWindow, resetShowWindow } = authAndRegSlice.actions;

export default authAndRegSlice.reducer;
