import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {useLocation, useNavigate } from "react-router-dom";

import { Button, Header, Title, CardList, Modal } from "../../components";

import { useAppDispatch } from "../../redux/store";
import { getCardSelector } from "../../redux/profile/cards/selectors";
import { cartSelector } from "../../redux/cart/selectors";
import { setCartItems } from "../../redux/cart/slice";

import styles from "./Payment.module.scss";

import { api } from "../../resources/api";
import {
  getTokenFromStorage,
  getTotalPriceFromStorage,
  getCartItemsFromStorage,
  removeDateFromStorage,
  removeLastSelectedHallFromStorage,
  removeSubscrFormsDataFromStorage,
  removeSelectedAdultSubscrFromStorage,
  removeSelectedKidSubscrFromStorage,
} from "../../utils";

import { PaymentPropsType } from "./types";
import { ModalTexts } from "../../resources/locale";
import { resetDate, resetLastSelectedHall } from "../../redux/halls/slice";
import {
  resetSelectedAdultSubscr,
  resetSelectedKidSubscr,
  resetSubscrFormsData,
} from "../../redux/subscriptions/slice";

export const Payment: React.FC<PaymentPropsType> = ({ title }) => {
  const { cardId } = useSelector(getCardSelector);
  const { totalCartItems } = useSelector(cartSelector);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [totalPrice, setTotalPrice] = React.useState<number>();
  const [methodSelected, setMethodSelected] = React.useState<boolean>(false);
  const [isOpenModalPaymentError, setIsOpenModalPaymentError] = React.useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    const storageTotalPrice = getTotalPriceFromStorage();
    const storageCartItems = getCartItemsFromStorage();
    if (storageTotalPrice) setTotalPrice(storageTotalPrice);
    if (storageCartItems) dispatch(setCartItems(storageCartItems));

    // eslint-disable-next-line
  }, []);

  const handleStartPayment = async () => {
    const token = getTokenFromStorage();
    const url = `${api.BASE_URL}${api.payment.CREATE_PAYMENT_LINKED_CARD}`;
    const payAll = {
      cartTotal: totalCartItems,
      cardId,
    };

    if (cardId === 0) {
      setIsLoading(true);

      await axios
        .post(url, payAll, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setIsLoading(false);
          if ("confirmation_token" in res.data) {
            window.location.assign(
              api.payment.GET_PAYMENT_URL(res.data.confirmation_token)
            );

            dispatch(resetDate());
            removeDateFromStorage();
            dispatch(resetLastSelectedHall());
            removeLastSelectedHallFromStorage();
            dispatch(resetSubscrFormsData());
            removeSubscrFormsDataFromStorage();
            dispatch(resetSelectedAdultSubscr());
            removeSelectedAdultSubscrFromStorage();
            dispatch(resetSelectedKidSubscr());
            removeSelectedKidSubscrFromStorage();
          }
          if ("error" in res.data) {
            setPaymentErrorMessage(res.data.error);
            setIsOpenModalPaymentError(true);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setPaymentErrorMessage(
            "Что-то пошло не так. Возможно проблемы с соединением. Попробуйте позже или свяжитесь с нами"
          );
          setIsOpenModalPaymentError(true);
        })
        .finally(() => setIsLoading(false));
    }

    if (cardId !== 0) {
      setIsLoading(true);

      await axios
        .post(url, payAll, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setIsLoading(false);
          if ("done" in res.data) navigate("/payment-success");
          if ("error" in res.data) navigate("/payment-error", { state: res.data.error });
        })
        .catch((e) => {
          setIsLoading(false);
          navigate("/payment-error", {
            state: "Что-то пошло не так. Попробуйте позже или свяжитесь с нами",
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSelectCard = () => setMethodSelected(true);
  const handleCloseModalPayemntError = () => setIsOpenModalPaymentError(false);

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>
          <div className={styles.paymentMethods}>
            <div className={styles.paymentMethods_method}>
              <CardList onPage={"payment"} onClickFunc={handleSelectCard} />
            </div>
          </div>
          <Button
            newStyles={styles.button}
            onClickFunc={handleStartPayment}
            disabled={isLoading || !methodSelected}
          >
            <span>Оплатить</span>
          </Button>
          {isLoading ? (
            <div className={styles.loadingMessage}>данные отправляются</div>
          ) : null}
        </section>
      </main>

      {/* Modal - payment errors */}
      {isOpenModalPaymentError ? (
        <Modal
          title={ModalTexts.cart.paymentError.TITLE}
          subTitle={paymentErrorMessage}
          isOpen={isOpenModalPaymentError}
          setIsOpen={setIsOpenModalPaymentError}
        >
          <Button newStyles={styles.button} onClickFunc={handleCloseModalPayemntError}>
            Ок
          </Button>
        </Modal>
      ) : null}
    </>
  );
};
