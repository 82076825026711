import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICardState, ICard } from "./types";
import { fetchGetCards } from "./asyncActions";

const initialState: ICardState = {
  cards: [],
  cardId: 0,
  isLoading: false,
};

const GetCards = createSlice({
  name: "getCards",
  initialState,
  reducers: {
    setCardid(state, action: PayloadAction<number>) {
      state.cardId = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get confirmToken
    builder.addCase(fetchGetCards.fulfilled, (state, action: PayloadAction<ICard[]>) => {
      state.cards = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchGetCards.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export default GetCards.reducer;
export const { setCardid } = GetCards.actions;
