// lastSelectedHall
export const setLastSelectedHallToStorage = (date: object) => {
  sessionStorage.setItem("lastSelectedHall", JSON.stringify(date));
};

export const getLastSelectedHallFromStorage = () => {
  const result = sessionStorage.getItem("lastSelectedHall");
  if (result) return JSON.parse(result);
};

export const removeLastSelectedHallFromStorage = () => {
  sessionStorage.removeItem("lastSelectedHall");
};

//selectedHalls
export const setSelectedHallsToStorage = (date: object) => {
  sessionStorage.setItem("selectedHalls", JSON.stringify(date));
};

export const getSelectedHallsFromStorage = () => {
  const result = sessionStorage.getItem("selectedHalls");
  if (result) return JSON.parse(result);
};

export const removeSelectedHallsFromStorage = () => {
  sessionStorage.removeItem("selectedHalls");
};

//date
export const getDateFromStorage = () => {
  return sessionStorage.getItem("date");
};

export const removeDateFromStorage = () => {
  sessionStorage.removeItem("date");
};

export const setDateToStorage = (date: string) => {
  sessionStorage.setItem("date", date);
};
