import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { fetchSendPhone } from "../../../../redux/profile/settings/changePass/asyncActions";
import { addPhone } from "../../../../redux/profile/settings/changePass/slice";
import { changePassSelector } from "../../../../redux/profile/settings/changePass/selectors";

import { Button, PhoneInput } from "../../../../components";

import styles from "./PhoneForm.module.scss";
import phoneIcon from "../../../../assets/icons/phoneInput.svg";

import { ValidMessages } from "../../../../resources/locale";
import { SubmitValueType } from "./types";

export const PhoneForm: React.FC = () => {
  const { isLoading } = useSelector(changePassSelector);
  const dispatch = useAppDispatch();

  const initialValues = {
    phone: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
  });

  const handleSubmit = (values: SubmitValueType) => {
    dispatch(addPhone(values.phone));
    dispatch(fetchSendPhone(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <p className={styles.description}>
              На указанный номер телефона будет отправлено письмо с кодом восстановления
            </p>
            <div className={styles.formSection}>
              <label htmlFor="phone">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput name="phone" placeholder="+7 (___) ___-__-__" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone"
              />
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={formik.isSubmitting}
            >
              Отправить
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
