import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IEditNameState} from "./types"

import {fetchEditEmail, fetchEditName, fetchEditPassword, fetchEditPhone} from "./asyncActions";

const initialState: IEditNameState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  isLoading: false,
};

const editNameSlice = createSlice({
  name: "changeName",
  initialState,
  reducers: {
    editName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    editEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    editPhone(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
    editPassword(state, action:PayloadAction<string>){
      state.password = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEditName.pending, (state) => {
      state.isLoading = true
    });
    builder.addCase(fetchEditName.fulfilled, (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.isLoading = false
    });
    builder.addCase(fetchEditEmail.pending, (state) => {
      state.isLoading = true
    });
    builder.addCase(fetchEditEmail.fulfilled, (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      state.isLoading = false
    });
    builder.addCase(fetchEditPhone.pending, (state) => {
      state.isLoading = true
    });
    builder.addCase(fetchEditPhone.fulfilled, (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
      state.isLoading = false
    });
    builder.addCase(fetchEditPassword.pending, (state) => {
      state.isLoading = true
    });
    builder.addCase(fetchEditPassword.fulfilled, (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      state.isLoading = false;
    });
  },
});

export const { editEmail,reset,editPhone,editName,editPassword} = editNameSlice.actions;

export default editNameSlice.reducer;
