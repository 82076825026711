import React, {useState} from "react";
import styles from "./ChangePhone.module.scss";
import {useAppDispatch} from "../../../../redux/store";
import {IProps} from "./types";
import {ErrorMessage, Form, Formik} from "formik";
import phoneIcon from "../../../../assets/icons/phoneInput.svg";
import {Button, PhoneInput} from "../../../../components";
import * as Yup from "yup";
import {ValidMessages} from "../../../../resources/locale";
import {editPhone} from "../../../../redux/profile/settings/changeDataProfile/slice";
import {useSelector} from "react-redux";
import {changeNameSelector} from "../../../../redux/profile/settings/changeDataProfile/selectors";

export const ChangePhone: React.FC<IProps> = ({close}) => {
    const dispatch = useAppDispatch();
    const { isLoading } = useSelector(changeNameSelector);


    const initialValues = {
        phone: "",
    };
    type SubmitValueType = {
        phone: string;
    };

    const validationSchema = Yup.object({
        phone: Yup.string()
            .min(18, ValidMessages.inputs.phone.NOT_FULL)
            .required(ValidMessages.inputs.phone.REQUIRED),
    });

    const handleSubmit = (values: SubmitValueType) => {
        dispatch(editPhone(values.phone))
        close(values.phone)
    }

    return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={true}
            >
                {(formik) => {
                    return (
                        <div>
                            <Form>
                                <div className={styles.formSection}>
                                    <label htmlFor="phone">
                                        <img src={phoneIcon} alt="phone icon" />
                                    </label>
                                    <PhoneInput
                                        name="phone"
                                        placeholder="+7 (___) ___-__-__"
                                    />
                                    <ErrorMessage
                                        component="div"
                                        className={styles.errorMessage}
                                        name="phone"
                                    />
                                </div>
                                {isLoading ? (
                                    <div className={styles.loadingMessage}>данные отправляются</div>
                                ) : null}
                                <Button
                                    type="submit"
                                    newStyles={styles.button}
                                    disabled={formik.isSubmitting}
                                >
                                    Отправить
                                </Button>
                            </Form>
                        </div>

                    );
                }}
            </Formik>
    );
};
