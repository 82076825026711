import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchTennisSchoolOrder, fetchPersonalTrainingOrder } from "./asyncActions";
import { ISchoolState } from "./types";

const initialState: ISchoolState = {
  isLoading: false,
  isErrorFetch: false,
};

const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    resetErrorFetch(state) {
      state.isErrorFetch = false;
    },
  },
  extraReducers: (builder) => {
    // TennisSchoolOrder
    builder.addCase(fetchTennisSchoolOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchTennisSchoolOrder.fulfilled,
      (state, action: PayloadAction<string>) => {
        if (action.payload === "Error") state.isErrorFetch = true;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchTennisSchoolOrder.rejected, (state) => {
      state.isErrorFetch = true;
      state.isLoading = false;
    });
    // PersonalTrainingOrder
    builder.addCase(fetchPersonalTrainingOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchPersonalTrainingOrder.fulfilled,
      (state, action: PayloadAction<string>) => {
        if (action.payload === "Error") state.isErrorFetch = true;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchPersonalTrainingOrder.rejected, (state) => {
      state.isErrorFetch = true;
      state.isLoading = false;
    });
  },
});

export const { resetErrorFetch } = schoolSlice.actions;

export default schoolSlice.reducer;
