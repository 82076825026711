import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchGetHall, fetchGetHalls } from "./asyncActions";

import { IHall, IHallsState, ISelectedHall, ITime } from "./types";

const initialState: IHallsState = {
  date: "",
  halls: [],
  hallTimes: [],
  lastSelectedHall: null,
  selectedHalls: {},
  isLoading: false,
};

const hallsSlice = createSlice({
  name: "halls",
  initialState,
  reducers: {
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setLastSelectedHall(state, action: PayloadAction<IHall>) {
      state.lastSelectedHall = action.payload;
    },
    setSelectedHalls(state, action: PayloadAction<{ [key: string]: ISelectedHall }>) {
      state.selectedHalls = action.payload;
    },
    resetDate(state) {
      state.date = "";
    },
    resetLastSelectedHall(state) {
      state.lastSelectedHall = null;
    },
    resetSelectedHalls(state) {
      state.selectedHalls = {};
    },
  },
  extraReducers: (builder) => {
    // get halls
    builder.addCase(fetchGetHalls.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGetHalls.fulfilled, (state, action: PayloadAction<IHall[]>) => {
      state.halls = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchGetHalls.rejected, (state) => {
      state.isLoading = false;
    });
    //get hall
    builder.addCase(fetchGetHall.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGetHall.fulfilled, (state, action: PayloadAction<ITime[]>) => {
      state.hallTimes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchGetHall.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setDate,
  setSelectedHalls,
  setLastSelectedHall,
  resetDate,
  resetLastSelectedHall,
  resetSelectedHalls,
} = hallsSlice.actions;

export default hallsSlice.reducer;
