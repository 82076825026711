import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../resources/api";

import {IItem} from "./types";
import {getTokenFromStorage} from "../../../utils";

export const fetchHistory = createAsyncThunk<IItem[]>(
  "getCards/fetchGetCards",
  async () => {
    try {
        const url = `${api.BASE_URL}${api.profile.history.GET_SUBSCRIPTION_HISTORY}`;
        const token = getTokenFromStorage();
      const response = await axios.get(url,{"headers": {
              "Authorization": `Bearer ${token}`
          }});
      return response.data;

    } catch (error) {
      console.log("error fetchHistory >>>", error);
    }
  }
);
