import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../../resources/api";

import {IProfile} from "./types";
import {getTokenFromStorage} from "../../../../utils";

export const fetchProfileInfo = createAsyncThunk<IProfile[]>(
  "getProfile/fetchGetProfileInfo",
  async () => {
    try {
        const url = `${api.BASE_URL}${api.profile.settings.getProfile.GET_PROFILE}`;
      const token = getTokenFromStorage();
      const response = await axios.get(url,{"headers": {
              "Authorization": `Bearer ${token}`
          }});
      return response.data;
    } catch (error) {
      console.log("error fetchProfileInfo >>>", error);
    }
  }
);
