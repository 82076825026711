import React, { useEffect } from "react";
import { useCurrentWidth } from "../../../../../hooks";
import { MediaBreakPoints } from "../../../../../resources/constants";
import Styles from "./PhotoRenderPage.module.scss";
import {fetchGetClubImages} from "../../../../../redux/images/asyncActions";
import {useAppDispatch} from "../../../../../redux/store";
import arrowLeft from "../../../../../assets/icons/arrowCalendarLeft.svg";
import {useNavigate} from "react-router-dom";
import {getLastSelectedHallFromStorage} from "../../../../../utils/storage/halls";
import {DateInput, Slider} from "../../../../../components";
import styles from "./../OurClub/OurClub.module.scss";
export const PhotoRenderPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const windowWidth = useCurrentWidth();
    const breakPoint = MediaBreakPoints.MOBILE_LARGE;
    const navigate = useNavigate();
    const lstHall = getLastSelectedHallFromStorage();


    useEffect(() => {
        dispatch(fetchGetClubImages());
    }, []);

    const renderSubTitle = () => {
        if (windowWidth > breakPoint) {
            return <p className={Styles.subTitile}>{lstHall.title}</p>;
        }
        return null;
    };

    const renderSmallImages = () => {
        const imagesArray = lstHall.images || [];
        return imagesArray.slice(1, 13).map((image:any, index:any) => {
            return <img key={index} className={Styles.small} src={image.url} />;
        });
    };

    const imagesArray = lstHall.images || [];
    const sliderImages = imagesArray.map((image: any) => image.url);


    const handleClick = () => {
        navigate(-1);
    };

    return (
        <div className={Styles.wrapper}>
            {
                windowWidth > breakPoint ? (
                    <div className={Styles.back} onClick={handleClick}>
                        <div>
                            <img className={Styles.img} src={arrowLeft}/>
                        </div>
                        Назад
                    </div>
                ) : null
            }
            {renderSubTitle()}
                <div className={Styles.slider}>
                    <Slider newStyle={'slider_medium_img'} images={sliderImages}/>
                </div>
            <div className={Styles.imgContainer}>{renderSmallImages()}</div>
        </div>

    );
};

