import React from "react";
import Styles from "./Rules.module.scss"
import {RulesPropsType} from "./types";
import {Header, Title} from "../../components";
import { useLocation } from "react-router-dom";
export const Rules: React.FC<RulesPropsType> = ({title}) => {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <>
            <Header title={title} />
            <main>
                <section className="container">
                    <Title>{title}</Title>
                    <div className={Styles.textWrapper}>
                        <div className={Styles.title}>
                            Публичная оферта №001T
                        </div>
                        <div>
                            <ul>
                                <li>
                                    Приложение к Публичной оферте №001<br/>
                                    Утверждено приказом ООО «Фитнес-Спорт»<br/>
                                    От 01 сентября 2020
                                </li>
                                <li>
                                    Физическое лицо принимает условия данной оферты и соглашается с условиями, изложенными на официальном сайте Клуба или в мобильном приложении, путем совершения оплаты.
                                </li>
                                <li>
                                    В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации в случае принятия изложенных ниже условий и оплаты услуг, физическое лицо производит акцепт этой оферты и, в соответствии с пунктом 3 статьи 438 ГК РФ, акцепт оферты равносилен заключению договора на условиях, изложенных в оферте.
                                </li>
                                <li>
                                    Оферта является официальным документом и публикуется в официальном сайте arenda.tennis.ru, а также на официальном сайте клуба www.теннис.рф и в мобильном приложении «фитнес-клуб tennis.ru».
                                </li>
                                <li>
                                    Совершение действий, указанных в данной оферте, направленных на ее принятие означает принятие лицом всех условий оферты в полном объеме, без исключений и оговорок.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    1.
                                </div>
                                Общие положения
                            </div>
                            <div className={Styles.liText}>
                                <ul>
                                    <li>
                                        <span>1.1.</span> Допуск в клуб разрешается только при наличии действующей клубной карты (абонемента) и зарегистрированном у администраторов клуба посещении.
                                    </li>
                                    <li>
                                        <span>1.2.</span> Часы работы Клуба: ежедневно, кроме 1-го января, с 8:00 до 23:00 часов в будние дни и с 9:00 до 23:00 часов в выходные и праздничные дни. Допуск в Клуб прекращается, если до окончания работы Клуба осталось менее 60 минут. Тренировочные зоны заканчивают свою работу за 20 минут до закрытия Клуба.
                                    </li>
                                    <li>
                                        <span>1.3.</span> Член Клуба, обязан покинуть Клуб до наступления обозначенного времени, в случае задержки, член клуба обязан уплатить штраф согласно прейскуранту. В случае несоблюдения режима работы и неоднократного нарушения, Клуб в праве расторгнуть договор в одностороннем порядке без возвращения средств.
                                    </li>
                                    <li>
                                        <span>1.4.</span> Клуб имеет право изменять часы работы Клуба, и/или порядок и/или часы пользования отдельными зонами/студиями/залами и т.д. Информация об указанных изменениях размещается на информационных стендах Клуба и/или иных носителях и/или на рецепции, и/или на сайтах Клуба: arenda.tennis.ru, tennis.ru, и/или в мобильном приложении «фитнес-клуб «tennis.ru» . Часы работы Клуба, размещаются в Клубе на рецепции, информационных стендах, и/или иным предусмотренным Договором способом доводятся до Членов Клуба.
                                    </li>
                                    <li>
                                        <span>1.5.</span> Клуб вправе приостановить свою работу на срок не более 4 дней в году для проведения мероприятий по улучшению качества обслуживания Членов Клуба, а также на необходимый срок для проведения ремонтных и профилактических работ, без предоставления компенсации. О приостановлении работы Клуб обязуется заранее проинформировать Членов Клуба. Клуб оставляет за собой право в течение года полностью закрывать зону бассейна для проведения ремонтных и профилактических работ с оборудованием, чашей и помещениями бассейна не более 30 дней в году. Дни такого закрытия не компенсируются.
                                    </li>
                                    <li>
                                        <span>1.6.</span> Полотенца являются собственностью Клуба и выдаются во временное пользование в специально отведенных местах согласно прейскуранту дополнительных услуг. Перед выходом из Клуба использованное полотенце и следует сдавать в специально отведенные места. Просим Вас обращаться бережно с предоставляемыми полотенцами. Пользование полотенцами не входит в перечень основных услуг и могут выдаваться для пользования в течение посещения за отдельную плату. В случае порчи или утраты полотенец Член клуба обязан возместить стоимость, оплатив штраф, согласно действующему прейскуранту.
                                    </li>
                                    <li>
                                        <span>1.7.</span> При входе в Клуб Члены Клуба оставляют верхнюю одежду (куртки, плащи, шубы, пальто, проч.) и головные уборы (кепки, шляпы, шапки и пр.) в гардеробе.
                                    </li>
                                    <li>
                                        <span>1.8.</span> Проход в верхней одежде в зону раздевалок не разрешается.
                                    </li>
                                    <li>
                                        <span>1.9.</span> В зоне раздевалок посетителю будет предоставлен отдельный шкафчик для хранения личных вещей (одежды).
                                    </li>
                                </ul>
                            </div>
                            <div className={Styles.liTextMore}>
                                <li>
                                    <span>1.10.</span> Лицам мужского пола, старше 5 лет запрещен вход в женские раздевалки. В клубе для переодевания детей существуют семейные раздевалки. При входе в Клуб следует надевать бахилы.
                                </li>
                                <li>
                                    <span>1.11.</span> Для тренировок в Клубе необходимо иметь предназначенную для этого обувь и одежду.
                                </li>
                                <li>
                                    <span>1.12.</span> В период нахождения в помещениях Клуба личные вещи и одежда Члена Клуба должны храниться в шкафчиках раздевалки. Не оставляйте личные вещи и одежду без присмотра. При необходимости Член Клуба может воспользоваться персональной ячейкой (при наличии свободных ячеек).
                                </li>
                                <li>
                                    <span>1.13.</span> Вход в залы Клуба со спортивными сумками, пакетами, рюкзаками запрещается. Об утере личных вещей, либо в случае обнаружения чужих забытых вещей необходимо незамедлительно сообщить Администрации Клуба. Найденные в Клубе вещи передаются на рецепцию Клуба.
                                </li>
                                <li>
                                    <span>1.14.</span> Клуб не несет ответственности за оставленные и утерянные на территории Клуба одежду, документы, мобильные телефоны, фото- и видеокамеры, деньги, драгоценности и другие вещи Члена Клуба, гостей, иных посетителей помещений Клуба, включая случаи утери в раздевалках и сейфах на рецепции.
                                </li>
                                <li>
                                    <span>1.15.</span> В случае утери браслета/ключа от шкафчика и необходимости вскрыть шкафчик, следует обратиться к сотруднику администрации, уплатить штраф за утерю, написать заявление с просьбой вскрыть шкафчик мастер-ключом и подробным описанием содержимого. Вскрытие шкафчика может быть произведено только после закрытия клуба, когда остальные члены клуба покинули раздевалки. Вскрытие шкафчика происходит в присутствии сотрудника клуба и члена клуба.
                                </li>
                                <li>
                                    <span>1.16.</span> Оплата клубного членства принимается в безналичной форме или в рублях в кассе Клуба или через официальный сайт Клуба, или в мобильном приложении Клуба.
                                </li>
                                <li>
                                    <span>1.17.</span> По окончании пребывания в Клубе Член Клуба должен освободить соответствующий шкафчик от своих личных вещей и вернуть ключ/замок или иное отрывающее/запирающее устройство от него, жетон на полотенца на рецепцию Клуба, после чего забрать Карту Члена Клуба. В случае, если Член Клуба не сдал ключ и покинул Клуб, он обязан по требованию Клуба заплатить штраф в размере, предусмотренном Договором.
                                </li>
                                <li>
                                    <span>1.18.</span> После окончания времени посещения Клуба Член Клуба обязан освободить сейфовую ячейку и сдать ключ от сейфовой ячейки администратору Клуба. В случае утери членской Карты, ключа от сейфовой ячейки или шкафчика, а также полотенца, номерка от гардероба, или халата, или жетона на полотенца необходимо обратиться к дежурному администратору Клуба и оплатить компенсацию согласно Прейскуранту Клуба.
                                </li>
                                <li>
                                    <span>1.19.</span> В случае выявления фактов использования третьими лицами Клубных карт/средств идентификации, оформленных на Членов Клуба, Клуб вправе потребовать, а Член Клуба обязан оплатить штраф согласно Прейскуранту Клуба, действующему на момент выявления указанного нарушения, и/или Клуб вправе изъять, а также в одностороннем порядке отказать в предоставлении Услуг.
                                </li>
                                <li>
                                    <span>1.20.</span> Клуб рекомендует Членам Клуба:
                                </li>
                                <div className={Styles.subText}>
                                    <ul>
                                        <li>
                                            <span>1.20.1.</span> До начала посещения Клуба, пользования Услугами, Дополнительными услугами пройти медицинское обследование, так как Член Клуба несёт персональную ответственность за своё здоровье.
                                        </li>
                                        <li>
                                            <span>1.20.2.</span> По требованию Клуба Член Клуба обязан предоставить медицинскую справку установленного образца о допуске к участию в физкультурных, физкультурно-оздоровительных мероприятиях, проводимых в бассейне.
                                        </li>
                                    </ul>
                                </div>
                                <li>
                                    <span>1.21.</span> Все риски причинения вреда своему здоровью в процессе самостоятельных занятий Член Клуба принимает на себя.
                                </li>
                                <li>
                                    <span>1.22.</span> Член Клуба и его гости, другие посетители Клуба обязаны соблюдать правила общей гигиены и чистоту во всех помещениях Клуба, которые используются ими до, во время и после тренировок, а также на прилегающей к Клубу территории, бережно относиться к имуществу Клуба на всей его территории. По окончании самостоятельных занятий или участия в физкультурных, физкультурно-оздоровительных и иных мероприятиях Член Клуба обязан вернуть используемый инвентарь, оборудование Клуба на специально отведённое место в Клубе, зафиксировав и/или приведя его в безопасное нерабочее положение.
                                </li>
                                <li>
                                    <span>1.23.</span> Член Клуба несет материальную ответственность за утерю или порчу используемого оборудования и инвентаря Клуба, а также за порчу любого имущества Клуба.
                                </li>
                                <li>
                                    <span>1.24.</span> О любом повреждении имущества Клуба необходимо незамедлительно информировать сотрудников Клуба. Ущерб, причиненный имуществу Клуба Членом Клуба, пользователем, гостем, посетителем должен быть возмещён виновным лицом в полном объеме. Если Член Клуба при получении имущества Клуба не предъявил претензий, данное имущество считается выданным ему в исправном состоянии.
                                </li>
                                <li>
                                    <span>1.25.</span> Кино- и фотосъемка в Клубе запрещена, если отсутствует специальная договоренность с администрацией Клуба.
                                </li>
                                <li>
                                    <span>1.26.</span> Гостевые визиты в Клуб запрещены, за исключением случаев, когда данная Услуга включена в комплекс Услуг клубной карты.
                                </li>
                                <li>
                                    <span>1.27.</span> В Клубе запрещается:
                                </li>
                                <div className={Styles.subText}>
                                    <ul>
                                        <li>
                                            <span>1.27.1.</span> Находиться лицам в состоянии алкогольного, наркотического, токсического опьянения, в психически неуравновешенном состоянии.
                                        </li>
                                        <li>
                                            <span>1.27.2.</span> Распивать спиртные напитки на территории Клуба, принимать наркотические и психотропные средства, не рекомендованные лечащим врачом медикаменты.
                                        </li>
                                        <li>
                                            <span>1.27.3.</span> Проносить и/или хранить любые виды огнестрельного, газового и холодного оружия, специальные средства (электрошокеры, баллончики с газом и другими защитными наполнителями), легко воспламеняющиеся, ядовитые, взрывчатые вещества, средства взрывания и предметы, ими начиненные, сжатые и сжиженные газы, легковоспламеняющиеся жидкости, воспламеняющиеся твердые вещества, окисляющие вещества и органические перекиси, токсичные вещества, радиоактивные материалы, едкие и коррозирующие вещества, ядовитые и отравляющие вещества, иные взрывоопасные, пожароопасные, токсичные и сильно пахнущие вещества, наркотические, психотропные и сильнодействующие препараты, медикаменты (за исключением назначенных врачом Члену Клуба), стеклянную тару.
                                        </li>
                                        <li>
                                            <span>1.27.4.</span> Оставлять вещи в шкафчиках раздевалок после окончания занятий.
                                        </li>
                                        <li>
                                            <span>1.27.5.</span> Курить, за исключением территории, специально предназначенной для этого.
                                        </li>
                                        <li>
                                            <span>1.27.6.</span> Оскорблять посетителей и персонал Клуба, провоцировать или участвовать в драке, совершать иные противоправные действия, нарушающие общественный порядок, угрожающие жизни и здоровью, имуществу, затрагивающие честь и достоинство любых лиц, находящихся на территории Клуба, включая персонал Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.7.</span> Громко, неуважительно и/или агрессивно разговаривать, выражаться нецензурной бранью, громко кричать или петь, и совершать действия, которые могут помешать окружающим.
                                        </li>
                                        <li>
                                            <span>1.27.8.</span> Беспокоить других посетителей Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.9.</span> Сорить и нарушать чистоту, порядок, санитарное состояние (выбрасывать мусор, плевать на пол, бросать жевательную резинку).
                                        </li>
                                    </ul>
                                </div>
                                <div className={Styles.subTextMore}>
                                    <ul>
                                        <li>
                                            <span>1.27.10.</span> Залезать на ограждения платформ, террас и свисать с них.
                                        </li>
                                        <li>
                                            <span>1.27.11.</span> Заходить на газоны (зоны зеленых насаждений), отламывать ветки и выдергивать растения;<br/>
                                            осуществлять какие-либо другие вандальные действия в отношении оборудования, имуществ и растений Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.12.</span> В Клуб запрещено приносить пищевые продукты (исключение - детское и специальное питание в пластиковой/картонной упаковке), напитки, и алкогольные напитки.
                                        </li>
                                        <li>
                                            <span>1.27.13.</span> Принимать пищу в зонах, предназначенных для тренировок, отдыха и в раздевалках. Исключение составляют безалкогольные напитки в закрывающихся, небьющихся емкостях. При необходимости Члену Клуба рекомендуется воспользоваться услугами кафе, расположенного в Клубе. Запрещается выносить посуду из фитнес-кафе на тренировочные территории.
                                        </li>
                                        <li>
                                            <span>1.27.14.</span> Вести на территории Клуба частную предпринимательскую деятельность: размещать надписи, объявления, рекламные материалы, проводить опросы или осуществлять рекламную, маркетинговую, консультационную и иную деятельность и распространять товары без письменного разрешения Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.15.</span> Самостоятельно пользоваться музыкальной и другой аппаратурой Клуба, в том числе включать и/или демонстрировать аудио-, видео- и иные произведения с использованием/применением любых носителей и/или оборудования в помещениях Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.16.</span> Приносить с собой аудиоаппаратуру и иные источники шума.
                                        </li>
                                        <li>
                                            <span>1.27.17.</span> Перемещать имущество Клуба без согласования с администрацией Клуба (за исключением мелкого спортивного инвентаря, перемещение которого необходимо для осуществления тренировки).
                                        </li>
                                        <li>
                                            <span>1.27.18.</span> Входить на территорию Клуба с животными.
                                        </li>
                                        <li>
                                            <span>1.27.19.</span> Препятствовать соблюдению режимов уборки и проветривания в помещениях Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.20.</span> Использовать помещения Клуба и прилегающие территории в политических и религиозных целях, в т. ч. проведения собраний, митингов, обрядов и т.п.
                                        </li>
                                        <li>
                                            <span>1.27.21.</span> Пользоваться Услугами, Дополнительными услугами при плохом самочувствии, в острый период заболевания (в том числе Членам Клуба, являющимся носителями передающихся инфекционных заболеваний) и/или в период обострения хронического заболевания.
                                        </li>
                                        <li>
                                            <span>1.27.22.</span> Играть, прыгать, нырять и иным образом активно вести себя в бассейне, раздевалке, душевой, джакузи, бане, сауне, на лестницах и иных помещениях Клуба, не предназначенных для организации физкультурных, физкультурно-оздоровительных мероприятий.
                                        </li>
                                        <li>
                                            <span>1.27.23.</span> Оставлять собственный инвентарь на хранение в Клубе без предварительной договоренности и согласия Клуба.
                                        </li>
                                        <li>
                                            <span>1.27.24.</span> Во избежание несчастных случаев, самостоятельно использовать тренажеры, предназначенные только для физкультурных, физкультурно-оздоровительных мероприятий в форме индивидуальных занятий с персональным тренером (например, Pilates Allegro, Reformer, Cadillac, Chair, Gyrotonic, RPM и др.).
                                        </li>
                                        <li>
                                            <span>1.27.25.</span> Использовать парфюмерию, мази и другую косметическую продукцию с резким запахом, а также средства, портящие или загрязняющие оборудование, помещения и интерьер Клуба (мёд, грязевые маски, масла и т. п.).
                                        </li>
                                        <li>
                                            <span>1.27.26.</span> Пользоваться Услугами, Дополнительными услугами, в т. ч. бассейном, банными комплексами Членам Клуба с открытыми ранами, травмами, грибковыми и другими кожными заболеваниями.
                                        </li>
                                        <li>
                                            <span>1.27.27.</span> Находиться на территории Клуба, за исключением раздевалок, душевых и расположенных при них банных комплексах, с обнаженными частями тела. Запрещается находиться на территории аквазоны женщинам без верхней части купальника (топлес).
                                        </li>
                                    </ul>
                                </div>
                                <li>
                                    <span>1.28.</span> Клуб не несет ответственность за состояние здоровья и возможный травматизм посетителей и членов клуба.
                                </li>
                                <li>
                                    <span>1.29.</span> Член Клуба и гости обязаны соблюдать настоящие Правила посещения Клуба, а также иные правила и рекомендации Клуба, вывешенные в виде объявлений в отдельных зонах Клуба.
                                </li>
                                <li>
                                    <span>1.30.</span> Администрация Клуба не несет ответственности, за вред, причиненный действиями (бездействиями) третьих лиц, а также, если причиной нанесения вреда, в том числе здоровью, стало нарушение правил пользования тренажерами, а также положений настоящих Правил.
                                </li>
                                <li>
                                    <span>1.31.</span> Член Клуба полностью берет на себя ответственность за состояние своего здоровья и состояние здоровья своих несовершеннолетних детей, посещающих Клуб вместе с ним. Член Клуба не вправе требовать от Клуба какой-либо компенсации морального, материального вреда или вреда, причиненного здоровью Члену Клуба, не по вине Клуба, как в течение срока действия клубной карты, так и по истечении срока ее действия.
                                </li>
                                <li>
                                    <span>1.32.</span> Член Клуба не вправе требовать возврата полной или частичной стоимости Услуг, в том числе в случае, когда Член Клуба не воспользуется предоставляемыми Услугами в течение действия клубной карты.
                                </li>
                                <li>
                                    <span>1.33.</span> Администрация Клуба не несет ответственности перед Членом Клуба за приостановку или прекращение работы Клуба, обусловленных действием обстоятельств непреодолимой силы, т.е. чрезвычайных и непредотвратимых при данных условиях обстоятельств, возникших помимо воли и желания сторон и которые нельзя предвидеть или избежать (объявленная или фактическая война, гражданские волнения, террористические акты, эпидемии, блокада, пожары, землетрясения, наводнения и другие природные стихийные бедствия, а также издание актов государственных органов и прочие явления форс-мажорного характера). Возврат денежных средств за неиспользованный период Клуб не производит.
                                </li>
                                <li>
                                    <span>1.34.</span> Временное или постоянное изменение места жительства не является основанием расторжения Договора.
                                </li>
                                <li>
                                    <span>1.35.</span> За технические неудобства, вызванные проведением городскими властями или администрацией здания сезонных профилактических и ремонтно-строительных работ, Клуб ответственности не несёт.
                                </li>
                                <li>
                                    <span>1.36.</span> В случае нарушения Членом Клуба положений Правил посещения Клуба действие клубной карты прекращается в одностороннем порядке по решению Администрации Клуба без возврата денежных средств.
                                </li>
                                <li>
                                    <span>1.37.</span> Администрация Клуба уполномочена предпринимать меры к посетителям, находящимся в состоянии алкогольного, и/или наркотического и/или токсического опьянения и/или в психически неуравновешенном состоянии, а также игнорирующим замечания работников Клуба и/или не соблюдающим настоящие Правила, а именно, ограничить пользование зонами Клуба или удаление посетителя из Клуба. Несоблюдение настоящих Правил является основанием для удаления посетителя из Клуба, при этом денежное возмещение за неиспользованное время не производится. Указанным посетителям может быть отказано Клубом в праве последующего посещения Клуба.
                                </li>
                                <li>
                                    <span>1.38.</span> Правила Клуба и текущие административные объявления, вывешенные в Клубе в общедоступных местах и на всеобщее обозрение, обязательны для соблюдения наряду с данными Правилами.
                                </li>
                                <li>
                                    <span>1.39.</span> Клуб оставляет за собой право отказать в продаже карты без объяснения причин.
                                </li>
                                <li>
                                    <span>1.40.</span> Клуб рекомендует, а Член Клуба вправе заключить от своего имени и за свой счет в качестве страхователя и выгодоприобретателя Договор добровольного страхования от несчастных случаев и болезней.
                                </li>
                                <li>
                                    <span>1.41.</span> Член Клуба подтверждает, что уведомлен о возможном временном отсутствии горячей и холодной воды в душевых Клуба по техническим причинам и претензий не имеет. Член Клуба подтверждает, что технические причины подобного характера не являются причиной для его заявления о досрочном прекращении Договора на оказание физкультурно-оздоровительных услуг с Клубом и возврата внесенных денежных средств за Клубную карту.
                                </li>
                                <li>
                                    <span>1.42.</span> Для надлежащего исполнения контракта при его заключении, Владелец контрата обязан предоставить о Члене клуба следующую информацию: ФИО паспортные данные, контактные телефоны, e-mail - адрес. С согласия Владельца контракта Клуб информирует его о состоянии Клубной карты, о проходящих мероприятиях и проч., в т. ч. через мобильную и Интернет - связь. Согласием Владельца контракта является акцепт оферты на сайте или в мобильном приложении. В случае изменения данных, предоставленных при покупке Контракта, Владелец контракта обязан оповестить об этом Администрацию Клуба. В противном случае Клуб снимает с себя ответственность за непредоставление информации.
                                </li>
                                <li>
                                    <span>1.43.</span> Какая-либо информация (в том числе: ФИО, дата рождения, адреса, телефоны, информация о работе, порядке посещения, пользования Услугами Клуба и прочая) о Владельцах контрактов, Членах Клуба, потенциальных или возможных Членах Клуба, а также о работниках Клуба является конфиденциальной и предоставлению/разглашению Клубом, а равно работниками Клуба, Членам Клуба, потенциальным Членам Клуба и третьим лицам не подлежит, за исключением случаев, предусмотренных действующим законодательством Российской Федерации.
                                </li>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    2.
                                </div>
                                Тренажерный зал
                            </div>
                            <div className={Styles.liText}>
                                <ul>
                                    <li>
                                        <span>2.1.</span> Запрещается самостоятельное посещение тренажерного зала детям до 16 лет. Дети младше 16 лет могут посещать тренажерный зал (заниматься на силовых тренажерах) только с персональным тренером или родителем. Дети после 16 лет могут заниматься на силовых тренажерах и кардиотренажерах самостоятельно. Дети после 14 лет могут самостоятельно заниматься на кардиотренажерах при условии обязательного присутствия родителей в этой зоне.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    3.
                                </div>
                                Бассейн
                            </div>
                            <div className={Styles.liText}>
                                <ul>
                                    <li>
                                        <span>3.1.</span> Территория бассейна относится к зоне повышенной опасности. Посетители бассейна обязаны неуклонно соблюдать правила безопасности пользования бассейном.
                                    </li>
                                    <li>
                                        <span>3.2.</span> Перед посещением бассейна, купели и солярия, а также до и после пользования баней/сауной, необходимо принимать душ. Перед выходом в бассейн посетители обязаны принять душ, используя личные моющие средства или предоставленные Клубом.
                                    </li>
                                    <li>
                                        <span>3.3.</span> Не разрешается нахождение в бассейне в не предназначенной для этого одежде и обуви. Вход в зону бассейна разрешается только в соответствующей обуви для бассейна и в купальных костюмах: плавки, плавки-шорты для мужчин, купальники для женщин, купальная одежда для детей, детям до двух лет - специальные непромокаемые подгузники / трусики для бассейна.
                                    </li>
                                    <li>
                                        <span>3.4.</span> Переодевание и раздевание запрещено вне предназначенных для этого мест.
                                    </li>
                                    <li>
                                        <span>3.5.</span> Мальчикам, достигшим 5 летнего возраста запрещен проход через женскую раздевалку. Для переодевания мальчиков старше 5 лет существует семейная раздевалка.
                                    </li>
                                    <li>
                                        <span>3.6.</span> Из гигиенических соображений следует посещать занятия в бассейне в плавательной шапочке, волосы убирать под шапочку, скреплять резинкой длинные волосы в хвост или пучок. Желательно иметь справку от врача, разрешающую посещать бассейн.
                                    </li>
                                    <li>
                                        <span>3.7.</span> Во избежание травм не разрешается бегать по кафельному покрытию бассейна и по бортику бассейна.
                                    </li>
                                    <li>
                                        <span>3.8.</span> В бассейне запрещено:
                                    </li>
                                        <div className={Styles.subText}>
                                            <ul>
                                                <li>
                                                    <span>3.8.1.</span> Использовать жевательную резинку.
                                                </li>
                                                <li>
                                                    <span>3.8.2.</span> Плавать в бассейне в нижнем белье и другой, не предназначенной для спортивного плавания одежде, а также без нее.
                                                </li>
                                                <li>
                                                    <span>3.8.3.</span> Плавать поперек бассейна.
                                                </li>
                                                <li>
                                                    <span>3.8.4.</span> Сидеть и висеть на разделительных дорожках бассейна.
                                                </li>
                                                <li>
                                                    <span>3.8.5.</span> Пользоваться бассейном людям с открытыми ранами, травмами, при наличии признаков кожных заболеваний, ссадин и порезов кожи, с наложенными на части тела бинтами, медицинскими повязками или лейкопластырями, в гипсе, с грибковыми заболеваниями, а также людям, страдающим лихорадкой, конъюнктивитом, кожными и инфекционными заболеваниями или другими недугами, которые могут представлять собой угрозу здоровью и/или жизни других людей.
                                                </li>
                                                <li>
                                                    <span>3.8.6.</span> Плавать без шапочки или с распущенными волосами.
                                                </li>
                                                <li>
                                                    <span>3.8.7.</span> Втирать в кожу мази и крема перед посещением бассейна.
                                                </li>
                                                <li>
                                                    <span>3.8.8.</span> Находиться около или непосредственно в бассейнах с едой, напитками.
                                                </li>
                                                <li>
                                                    <span>3.8.9.</span> Засорять воду бассейнов, бросать в бассейны посторонние предметы, затыкать специальные распылители и форсунки, прыгать на решетки сливных отверстий и желобов.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={Styles.subTextMore}>
                                            <li>
                                                <span>3.8.10.</span> Плевать на пол и в воду, справлять естественную нужду вне туалетов. Штраф за нарушение настоящего пункта оплачивается согласно действующему в клубе прейскуранту.
                                            </li>
                                            <li>
                                                <span>3.8.11.</span> Приносить с собой надувные бассейны, надувные круги, матрасы и прочее пляжное и плавательное снаряжение (исключение составляют надувные нарукавники и жилеты для малышей).
                                            </li>
                                            <li>
                                                <span>3.8.12.</span> Проходить в аквазону и сауны, бани с детскими колясками.
                                            </li>
                                            <li>
                                                <span>3.8.13.</span> Прыгать с бортика в бассейн, за исключением, когда прыжки проводятся с разрешения и под контролем работника Клуба.
                                            </li>
                                            <li>
                                                <span>3.8.14.</span> На территории бассейна бегать, быстро передвигаться, играть, прыгать по прилегающим платформам, нырять в воду с бортиков бассейнов и джакузи, удерживать друг друга под водой, выполнять акробатические прыжки, делать резкие движения, толкать других посетителей, залезать на ограждения, мостики и декоративные фигуры и иным образом активно вести себя в бассейне.
                                            </li>
                                        </div>
                                    <li>
                                        <span>3.9.</span> Члены Клуба и иные посетители обязаны выполнять требования и команды дежурных сотрудников в бассейне, реагировать на их сигналы и предупреждения, соблюдать меры безопасности.
                                    </li>
                                    <div className={Styles.liTextMore}>
                                        <li>
                                            <span>3.10.</span> Администрация Клуба рекомендует воздержаться от посещений аквазоны:
                                        </li>
                                        <div className={Styles.subText}>
                                            <ul>
                                                <li>
                                                    <span>3.10.1.</span> Беременным женщинам.
                                                </li>
                                                <li>
                                                    <span>3.10.2.</span> Лицам с грудными детьми.
                                                </li>
                                                <li>
                                                    <span>3.10.3.</span> Лицам с кардиостимулятором и сердечно-сосудистыми заболеваниями.
                                                </li>
                                                <li>
                                                    <span>3.10.4.</span> Лицам с повышенной чувствительностью кожи к хлорированной воде и хлоросодержащим элементам.
                                                </li>
                                                <li>
                                                    <span>3.10.5.</span> Лицам с недавно перенесенными травмами, проходящим курс лечения или находящимся на реабилитации.
                                                </li>
                                                <li>
                                                    <span>3.10.6.</span> Лицам с травмами головы, заболеваниями опорно-двигательного аппарата, расстройствами нервной системы.
                                                </li>
                                                <li>
                                                    <span>3.10.7.</span> Лицам, подверженным сильным аллергическим реакциям.
                                                </li>
                                                <li>
                                                    <span>3.10.8.</span> Лицам с другими заболеваниями, которые могут обостриться во время пользования аквазоной.
                                                </li>
                                            </ul>
                                        </div>
                                        <li>
                                            <span>3.11.</span> Во время плавания в бассейне Член Клуба обязан соблюдать правила кругового плавания: во время плавания держаться правой стороны дорожки.
                                        </li>
                                        <li>
                                            <span>3.12.</span> Во время плавания Членам Клуба разрешено пользоваться плавательными досками, поясами, нарукавниками, плавающими игрушками. Дополнительным оборудованием (ласты, мячи, ворота и др.) Членам Клуба можно пользоваться только с разрешения дежурного работника Клуба, который в целях соблюдения мер безопасности вправе отказать в его выдаче и использовании.
                                        </li>
                                        <li>
                                            <span>3.13.</span> За порчу мебели, оборудования, инвентаря с виновных взимается стоимость нанесенного ущерба.
                                        </li>
                                        <li>
                                            <span>3.14.</span> В случае нарушения правил посещения зоны бассейна, тренер вправе просить нарушителя покинуть зону.
                                        </li>
                                        <li>
                                            <span>3.15.</span> В банях и саунах необходимо находиться в купальном костюме.
                                        </li>
                                        <li>
                                            <span>3.16.</span> Перед посещением сауны, солярия и турецкой парной необходимо ознакомиться с правилами их пользования и выполнять их. При пользовании сауной и турецкой парной необходимо соблюдать правила безопасности. Просим Вас соблюдать общепринятые меры предосторожности.
                                        </li>
                                        <li>
                                            <span>3.17.</span> Использование сауны и турецкой парной может быть ограничено (технические перерывы для проведения технических и профилактических работ). Администрация в праве отключить или вывести из эксплуатации банный комплекс частично или полностью без объяснения причин. Отключение сауны или парной не является достаточным основанием для расторжения договора.
                                        </li>
                                        <li>
                                            <span>3.18.</span> Запрещается лить воду и другие вещества на нагревательный элемент (печь) в саунах, приносить с собой аэрозольные жидкости.
                                        </li>
                                        <li>
                                            <span>3.19.</span> Запрещается самостоятельно регулировать температуру в сауне и/или переключать любые приборы сауны.
                                        </li>
                                        <li>
                                            <span>3.20.</span> Использование Членом Клуба косметических масок, красящих средств, эфирных масел, настоек, кремов, пилингов, скрабов, в том числе, кофе, веников, а также бритвенных принадлежностей в душе, банях и саунах запрещается. В сауне запрещается стирать собственные вещи, сушить одежду и полотенца.
                                        </li>
                                        <li>
                                            <span>3.21.</span> Беременные женщины перед посещением сауны должны проконсультироваться со своим врачом и посещать сауну, баню только при положительном заключении специалиста.
                                        </li>
                                        <li>
                                            <span>3.22.</span> Запрещается в сауне красить волосы, натирать себя или других посетителей кофе или другими красящими и плохо смывающимися смесями, которые возможно могут засорить канализацию.
                                        </li>
                                        <li>
                                            <span>3.23.</span> Перед посещением зон саун и турецкой парной с детьми, необходимо посетить с ними туалетную комнату.
                                        </li>
                                        <li>
                                            <span>3.24.</span> Персонал Клуба, инструкторы бассейна имеют право отстранять от посещения аквазоны, сауны, турецкой парной лиц с подозрением на заразные кожные заболевания до предоставления справки о здоровье от врача – дерматовенеролога.
                                        </li>
                                        <li>
                                            <span>3.25.</span> Температура воды в большом бассейне, согласно САНПИН 2.1.2.1188-03, приказа № 228, колеблется в пределах 26 - 29 градусов.
                                        </li>
                                        <li>
                                            <span>3.26.</span> Посещать аквазону, включая бассейн, могут дети с 1-го года согласно ниже установленным правилам:
                                        </li>
                                        <div className={Styles.subText}>
                                            <ul>
                                                <li>
                                                    <span>3.26.1.</span> Дети с 1 года до 14 лет допускаются в бассейн для плавания только в сопровождении родителей или персонального инструктора на персональной тренировке, проводимой по предварительной записи и оплате. Детям младше 14 лет находиться в бассейне самостоятельно запрещено.
                                                </li>
                                                <li>
                                                    <span>3.26.2.</span> Дети должны находиться в аквазоне только под постоянным наблюдением сопровождающих взрослых, которые несут за них полную ответственность, отвечают за их безопасность, а также за ущерб, нанесенный ими.
                                                </li>
                                                <li>
                                                    <span>3.26.3.</span> Ответственность за несовершеннолетних лиц во время нахождения их на территории аквазоны (бассейна, купелей и т. д.), саун, бань и т. п. целиком и полностью несут сопровождающие их взрослые, которые обязан постоянно следить за поведением и местонахождением детей.
                                                </li>
                                                <li>
                                                    <span>3.26.4.</span> В детский бассейн допускаются только дети до 14 лет.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    4.
                                </div>
                                Парковка автомобилей и других транспортных средств
                            </div>
                            <div className={Styles.liText}>
                                <ul>
                                    <li>
                                        <span>4.1.</span> Пользование автомобильной парковкой Клуба не включено в Карту Члена Клуба и является Дополнительной услугой Клуба, если стоимость данной услуги предусмотрена Прейскурантом Клуба. Член Клуба имеет право воспользоваться автомобильной парковкой во время посещения Клуба при наличии свободных мест.
                                    </li>
                                    <li>
                                        <span>4.2.</span> На территории парковки Член Клуба должен следовать указаниям сотрудника парковки.
                                    </li>
                                    <li>
                                        <span>4.3.</span> Члену Клуба запрещаетс оставлять автомобиль, иное транспортное средство на парковке на время своего фактического отсутствия в Клубе. В случае нарушения Членом Клуба данного пункта Правил, Клуб имеет право в дальнейшем не допускать автомобиль или иное транспортное средство Члена Клуба на парковку.
                                    </li>
                                    <li>
                                        <span>4.4.</span> В целях обеспечения правил пожарной безопасности и для обеспечения удобства других Членов Клуба и гостей запрещается ограничивать проходы в помещения Клуба, парковать автомобили, иные транспортные средства у входа в помещения Клуба, оставлять автотранспорт на проезжей части парковки и наезжать на ограничительные бордюры. В случае нарушения Членом Клуба данного пункта Правил, Клуб имеет право отказать в регистрации Члена Клуба (выдаче ключа от шкафчика в раздевалке и жетона на полотенца) до переустановки транспортного средства.
                                    </li>
                                    <li>
                                        <span>4.5.</span> Клуб не несет ответственности за сохранность автомобиля и иного транспортного средства Члена Клуба, находящегося на парковке, а также за сохранность находящегося в автомобиле и ином транспортом средстве имущества.
                                    </li>
                                    <li>
                                        <span>4.6.</span> Клиенты и Посетители Клуба обязаны возвратить полученное во временное пользование имущество спортивного комплекса.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    5.
                                </div>
                                Заключительные положения
                            </div>
                            <div className={Styles.liText}>
                                <ul>
                                    <li>
                                        <span>5.1.</span> Член Клуба обязан внимательно изучить и соблюдать Правила, положения Договора, заключённого между Клубом и Членом Клуба.
                                    </li>
                                    <li>
                                        <span>5.2.</span> При первом посещении Клуба Член Клуба обязан:
                                    </li>
                                    <div className={Styles.subText}>
                                        <ul>
                                            <li>
                                                <span>5.2.1.</span> Ознакомиться с фитнес-программами и расписанием групповых занятий, предлагаемых в Клубе;
                                            </li>
                                            <li>
                                                <span>5.2.2.</span> Ознакомиться с правилами по технике безопасности и правилами пожарной безопасности;
                                            </li>
                                            <li>
                                                <span>5.3.3.</span> Ознакомиться с правилами посещения помещений Клуба;
                                            </li>
                                            <li>
                                                <span>5.4.4.</span> Иметь при себе документ, удостоверяющий личность, и временную или постоянную Клубную карту. Не стесняйтесь задавать вопросы персоналу Клуба, ведь Ваш результат - наша работа. Наши консультанты и инструкторы всегда рады помочь Вам.
                                            </li>
                                        </ul>
                                    </div>
                                    <li>
                                        <span>5.3.</span> В случае необходимости, а также для обеспечения максимального комфорта Члена Клуба, Правила посещения Клуба могут быть изменены или дополнены Клубом в одностороннем порядке, о чем Члены Клуба информируются заранее: минимум за 10 (десять) дней до вступлений Правил в силу путем размещения информационных объявлений на информационных стендах Клуба и/или на рецепции Клуба.
                                    </li>
                                    <li>
                                        <span>5.4.</span> До начала занятий в Клубе Вам необходимо ознакомиться с правилами посещения зон Клуба, правилами посещения групповых занятий, правилами пользования тренажерами, иным спортивным оборудованием и инвентарем, инструкцией по технике безопасности их использования.
                                    </li>
                                    <li>
                                        <span>5.5.</span> Просим Вас внимательно относиться к состоянию Вашего здоровья. Во избежание получения физической травмы Член Клуба обязан соблюдать правила посещения всех зон Клуба и рекомендации инструкторов (сотрудников) Клуба. Правила посещения зон Клуба находятся в визуально доступном для Члена Клуба месте (информационный стенд) при входе в каждую из указанных зон. Член Клуба обязан письменно предупреждать Клуб о наличии имеющихся заболеваний (в том числе скрытых), а также о наличии медицинских противопоказаний, которые могут сделать оказываемые по Договору услуги небезопасными для здоровья Члена Клуба. Клуб не несет ответственности за возможное ухудшение состояние здоровья Члена Клуба, если это произошло в результате острого заболевания, несчастного случая, обострения хронического заболевания.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <div className={`${Styles.textWrapper_bold}`}>
                                    <li>
                                        Необходимо уведомлять персонал Клуба о любых случаях ухудшения самочувствия, случившегося во время Вашего пребывания в Клубе для обеспечения оказания первой необходимой помощи, а также о любых ограничениях к занятиям физической культурой и спортом.
                                    </li>
                                </div>
                                <li>
                                    Если самочувствие Члена Клуба ухудшилось в тот момент, когда в прямом доступе нет работника Клуба, рекомендуем обратиться к любому находящемуся рядом лицу с просьбой оказать помощь и/или пригласить работника Клуба.
                                </li>
                                <li>
                                    Для ускорения процесса оказания медицинской помощи рекомендуем самостоятельно вызвать специализированную организацию для оказания медицинской помощи, потом сообщить работникам Клуба о факте ее вызова и пояснить местонахождение Члена Клуба, которому необходимо организовать оказание первой или медицинской помощи.
                                </li>
                                <li>
                                    При наличии ограничений к занятиям физической культурой и спортом, подтвержденных справкой врача, Клуб может по просьбе Члена Клуба разработать индивидуальную программу занятий на основании справки врача и указанных в ней рекомендаций.
                                </li>
                                <li>
                                    При наступлении беременности Член Клуба обязан поставить в известность Инструкторов Клуба, Клуб и предоставить справку из женской консультации о сроке и течении беременности.
                                </li>
                                <li>
                                    Клуб обращает внимание, что соблюдение Членами Клуба настоящих Правил, положительно отразится на здоровье каждого Члена Клуба и предупредит возникновение возможных негативных последствий.
                                </li>
                                <li>
                                    Не стесняйтесь задавать вопросы персоналу Клуба, ведь Ваш результат - наша работа. Наши консультанты и инструкторы всегда рады помочь Вам.
                                </li>
                                <div className={`${Styles.textWrapper_bold}`}>
                                    <li>
                                        Клуб желает вам здоровья, спортивных успехов и приятного отдыха!
                                    </li>
                                </div>
                                <li>
                                    Приобретая членство в Клубе, вы заявляете об отсутствии у Вас медицинских противопоказаний для посещения Клуба и пользования Услугами и Дополнительными услугами Клуба. Вы признаете права администрации Клуба в любой момент запросить у Вас врачебную справку об отсутствии инфекционных заболеваний, препятствующих занятиям спортом в общественных местах, в том числе плаванию в бассейне.
                                </li>
                                <li>
                                    При умышленном сокрытии наличия инфекционного заболевания, опасного для окружающих, администрация Клуба имеет право расторгнуть договор и прекратить членство в Клубе без выплаты каких-либо компенсаций за неиспользованное время членства, иных выплат, возмещений и компенсаций.
                                </li>
                                <li>
                                    Член клуба обязан самостоятельно отслеживать изменения Правил Клуба.
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
