import React from "react";
import { ErrorMessage, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import * as Yup from "yup";

import { Button, NameInput, PhoneInput, ProfilesListIcon } from "../../components";

import { editProfile } from "../../redux/profile/profileInfo/slice";
import { useAppDispatch } from "../../redux/store";

import styles from "./ProfileAdultForm.module.scss";
import profileIcon from "../../assets/icons/profileInput.svg";
import phoneIcon from "../../assets/icons/phoneInput.svg";

import { ValidMessages } from "../../resources/locale";
import { ProfileAdultFormPropsType, ValuesType } from "./type";

export const ProfileAdultForm: React.FC<ProfileAdultFormPropsType> = (props) => {
  const {
    page,
    onSubmitFunc,
    onChangeFunc,
    close,
    error,
    isFilledFormsTest,
    profilesListIcon = false,
    formData,
    profileValues,
  } = props;

  const dispatch = useAppDispatch();

  const initialValues: ValuesType = {
    name: profileValues?.name || "",
    phone: profileValues?.secondaryText || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(4, ValidMessages.inputs.name.NOT_FULL)
      .required(ValidMessages.inputs.name.REQUIRED),
    phone: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
  });

  const handleSubmit = (values: ValuesType, actions: FormikHelpers<ValuesType>) => {
    dispatch(editProfile(values));
    if (close) {
      close(values);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validateOnChange={true}
      validateOnBlur={true}
      // enableReinitialize={true}
    >
      {(formik) => {
        if (isFilledFormsTest) {
          formik.touched = {
            name: true,
            phone: true,
          };
        }
        return (
          <Form>
            <GoValidateFunc isFilledFormsTest={isFilledFormsTest} />
            <div className={styles.formSection}>
              <label htmlFor="name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput
                name="name"
                placeholder="ФИО"
                onChangeFunc={onChangeFunc}
                isFilledFormsTest={isFilledFormsTest}
              />
              <ErrorMessage component="div" className={styles.errorMessage} name="name" />
              {profilesListIcon ? <ProfilesListIcon formData={formData} /> : null}
            </div>
            <div className={styles.formSection}>
              <label htmlFor="phone">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput
                name="phone"
                placeholder="+7 (___) ___-__-__"
                onChangeFunc={onChangeFunc}
                isFilledFormsTest={isFilledFormsTest}
              />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone"
              />
            </div>
            {page === "profile" &&
              (error ? (
                <>
                  <div className={styles.errorMessage}>
                    Такой телефон уже существует в базе данных!
                  </div>
                  <Button type="submit" newStyles={styles.button}>
                    Попробовать повторно
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  newStyles={styles.button}
                  disabled={formik.isSubmitting}
                >
                  Сохранить профиль
                </Button>
              ))}
          </Form>
        );
      }}
    </Formik>
  );
};

// This trick is an official practice - https://formik.org/docs/api/useFormikContext
const GoValidateFunc = (props: { isFilledFormsTest: boolean | undefined }) => {
  const { validateForm } = useFormikContext();

  React.useEffect(() => {
    if (props.isFilledFormsTest) validateForm(); // eslint-disable-next-line
  }, [props.isFilledFormsTest]);

  return null;
};
