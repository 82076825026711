import React from "react";
import { Link } from "react-router-dom";

import styles from "./Button.module.scss";

import { ButtonPropsType, ButtonEventType } from "./types";

export const Button: React.FC<ButtonPropsType> = (props: ButtonPropsType) => {
  const { children, onClickFunc, disabled, newStyles, ...attrs } = props;

  const Tag = attrs.href ? "a" : "button";
  const currentStyles = newStyles ? `${styles.btn} ${newStyles}` : styles.btn;

  const handleClick = (e: ButtonEventType) => {
    if (onClickFunc !== undefined) {
      if (disabled) {
        e.preventDefault(); // if TAG will be 'a' (link) - standart disable don't work
      } else {
        return onClickFunc(e);
      }
    }
  };

  return (
    <>
      {attrs.to ? (
        <Link
          to={attrs.to}
          {...attrs}
          className={currentStyles}
          onClick={handleClick}
          style={{ display: "inline-flex" }}
        >
          {children}
        </Link>
      ) : (
        <Tag
          {...attrs}
          className={currentStyles}
          disabled={disabled}
          onClick={handleClick}
        >
          {children}
        </Tag>
      )}
    </>
  );
};
