import React from "react";
import { useLocation } from "react-router-dom";
import {Header, Title} from "../../components";
import Styles from "./Politics.module.scss"
type PoliticsPropsType = {
    title: string;
};

export const Politics: React.FC<PoliticsPropsType> = ({title}) => {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return(
        <>
            <Header title={title}/>
            <main>
                <section className="container">
                    <Title>{title}</Title>
                    <div className={Styles.textWrapper}>
                        <div className={Styles.title}>
                            Политика конфиденциальности сайта fitness-sport-msk.ru
                        </div>
                        <div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    1.
                                </div>
                                Общие сведения
                            </div>
                            <div>
                                Сайт «fitness-sport-msk.ru» (далее — Сайт) разработан фитнес-клубом Теннис.рф (далее — Организация) ОГРН 1167746369416, юридический адрес: 115404, г. Москва, ул. 6-я Радиальная, д. 20, стр. 8.
                            </div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    2.
                                </div>
                               Условия использования
                            </div>
                            <div>
                                При использовании Сайта, Вы в полном объеме принимаете условия настоящей Политики и выражаете свое добровольное определенное согласие на обработку Ваших данных способом и в целях как это описано в настоящей Политике. Если Вы не согласны с настоящей Политикой, пожалуйста, откажитесь от испольования данного Сайта. Организация вправе время от времени изменять и/или дополнять настоящую Политику без предварительного письменного уведомления пользователей. Вам необходимо с периодичностью раз в один месяц знакомиться с настоящей Политикой на предмет внесенных в нее изменений и/или дополнений. Если Вы продолжаете пользоваться Сайтом, Вы подтверждаете свое согласие с новой редакцией Политики.
                            </div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    3.
                                </div>
                                Цели сбора и/или обработки данных пользователей
                            </div>
                            <div>
                                Организация собирает и обрабатывает Ваши данные, преследуя следующие цели обработки Ваших персональных данных, а именно: функционирование Сайта, а также более удобное, быстре и безопасне использования его для Вас. Кроме того, переданные данные могут быть использованы для обращения в компетентные государственные органы в целях защиты прав и свобод человека и гражданина, в том числе включая непосредственно Ваши права. Данные, которые может собирать и обрабатывать Организация и ее партнеры в связи с использованием Вами сайта «fitness-sport-msk.ru»:
                            </div>
                            <div>
                                <ul>
                                    <li>&mdash; ваше имя, фамилия, отчество;</li>
                                    <li>&mdash; номер Вашего мобильного телефона и данные о мобильной сети;</li>
                                    <li>&mdash; адрес Вашей электронной почты;</li>
                                    <li>&mdash; gps-данные о месте нахождения Вашего мобильного устройства;</li>
                                    <li>&mdash; IP-адрес Вашего устройства;</li>
                                    <li>&mdash; информацию и сведения, переданные Вами через форму обратной связи и иные каналы коммуникации (email, телефоны и др.), включая прилагаемые файлы (в том числе изображения).</li>
                                    <li>&mdash; файлы cookies.</li>
                                </ul>
                            </div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    4.
                                </div>
                                Использование, распространение и передача ващих данных
                            </div>
                            <div>
                                Организация может собирать, записывать, систематизировать, хранить, модифицировать, комбинировать, группировать, обезличивать, удалять, изменять и использовать Ваши данные с другой информацией для обеспечения управления и развития Сайта. Организация вправе, в том числе, когда это необходимо для защиты прав и свобод человека и гражданина, передавать Ваши персональные данные своим партнерам, а также при обращении в государственные органы и органы международной защиты прав человека. Настоящим Вы выражаете свое добровольное согласие, что Ваши данные могут быть предоставлены третьим лицам в следующих случаях:
                            </div>
                            <div>
                                <ul>
                                    <li>&mdash; когда это необходимо с целью соблюдения законодательства;</li>
                                    <li>&mdash; в случаях, когда это необходимо для защиты прав человека и основных свобод.</li>
                                </ul>
                            </div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    5.
                                </div>
                                Хранение данных
                            </div>
                            <div>
                                Данное согласие может быть расторгнуто по заявлению одной из сторон с момента получения второй стороны заявления о расторжении. В случаи отсутствия заявления о расторжении, согласие действует на срок определённый в статье 208 ГК РФ, но не менее чем срок, втечение которого функционирует сайт «fitness-sport-msk.ru».
                            </div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    6.
                                </div>
                                Иные обязанности сторон
                            </div>
                            <div>
                                Вы являетесь ответственным за полноту и достоверность предоставляемых Вами данных. В случае наличия несоответствий и/или некорректности в предоставленных Вами данных, они должны быть изменены, в том числе путем обращения к специалистам Организации, как это указано в разделе «Контакты». Организация оставляет за собой право при удалении и/или изменении Ваших данных хранить те данные, которые необходимы для целей соблюдения применимого законодательства, обеспечения безопасности и эффективности Сайта. В случае Вашего обращения в ООО «Фитнес-спорт», Организация вправе запросить дополнительные данные, необходимые для рассмотрения соответствующего запроса. При непредставлении таких данных рассмотрение запроса может быть не осуществлено.
                            </div>
                            <div className={Styles.title}>
                                <div className={`${Styles.title_number}`}>
                                    7.
                                </div>
                                Обратная связь
                            </div>
                            <div>
                                При возникновении вопросов касательно настоящей Политики или обработки и использования Ваших данных в связи с использованием сайта «fitness-sport-msk.ru», Вы можете обратиться по электронной почте: fitnessapp@tennis.ru.<br/>
                                Ваш запрос обязательно должен включать имя, фамилию, контактные данные для связи, а также иную информацию, необходимую для всестороннего и полного рассмотрения Вашего запроса.
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )

}
