import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../resources/api";
import { ISubscription } from "./types";

export const fetchGetSubscriptions = createAsyncThunk<ISubscription[]>(
  "subscriptions/fetchGetSubscriptions",
  async () => {
    try {
      const url = `${api.BASE_URL}${api.subscription.GET_SUBSCRIPTIONS}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      console.log("fetchGetSubscriptions resp >>>", error);
      return error.message;
    }
  }
);
