import React from "react";

import { Button, Header } from "../../../components";

import { useAppDispatch } from "../../../redux/store";
import { resetCartItems } from "../../../redux/cart/slice";

import styles from "./PaymentSuccess.module.scss";
import paymentSuccessImg from "../../../assets/img/paymentSuccessImg.svg";

import {
  removeCartItemsFromStorage,
  removeDateFromStorage,
  removeLastSelectedHallFromStorage,
  removeRentDateFromStorage,
  removeSelectedAdultSubscrFromStorage,
  removeSelectedHallsFromStorage,
  removeSelectedKidSubscrFromStorage,
  removeSelectedSubscrFromStorage,
  removeSubscrFormsDataFromStorage,
  removeTotalPriceFromStorage,
  removeWebPaymentConfirmationTokenFromStorage,
  removeWebPaymentIDFromStorage,
} from "../../../utils";
import {
  resetDate,
  resetLastSelectedHall,
  resetSelectedHalls,
} from "../../../redux/halls/slice";
import {
  resetSelectedAdultSubscr,
  resetSelectedKidSubscr,
  resetSelectedSubscr,
  resetSubscrFormsData,
} from "../../../redux/subscriptions/slice";
import { resetWebPaymentConfToken } from "../../../redux/profile/paytoken/slice";

export const PaymentSuccess: React.FC = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    removeDateFromStorage();
    removeCartItemsFromStorage();
    removeRentDateFromStorage();
    removeTotalPriceFromStorage();
    removeSelectedHallsFromStorage();
    removeSelectedSubscrFromStorage();
    removeSubscrFormsDataFromStorage();
    removeSelectedAdultSubscrFromStorage();
    removeSelectedKidSubscrFromStorage();
    removeWebPaymentConfirmationTokenFromStorage();
    removeWebPaymentIDFromStorage();
    removeLastSelectedHallFromStorage();
    dispatch(resetDate());
    dispatch(resetSelectedHalls());
    dispatch(resetSelectedSubscr());
    dispatch(resetCartItems());
    dispatch(resetSubscrFormsData());
    dispatch(resetSelectedAdultSubscr());
    dispatch(resetSelectedKidSubscr());
    dispatch(resetWebPaymentConfToken());
    dispatch(resetLastSelectedHall());
  }, []);

  return (
    <>
      <Header title={""} />
      <main>
        <section className="container">
          <p className={styles.subTitile}>Оплата прошла успешно</p>
          <div className={styles.image}>
            <img src={paymentSuccessImg} alt="payment success" />
          </div>
          <div className={styles.linkButtons}>
            <Button newStyles={styles.button} to="/profile/subscriptions">
              К списку моих абонементов
            </Button>
            <Button newStyles={styles.button} to="/profile/halls">
              К списку моих арендованных залов
            </Button>
            <Button newStyles={styles.button} to="/subscriptions">
              Купить абонемент
            </Button>
          </div>
        </section>
      </main>
    </>
  );
};
