import React from "react";
import ReactDOM from "react-dom";

import { PortalPropsType } from "./types";

export const Portal: React.FC<PortalPropsType> = ({ children }) => {
  const defaultContainer = document.body;

  return ReactDOM.createPortal(
    <div className="portal">{children}</div>,
    defaultContainer
  );
};
