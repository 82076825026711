import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCurrentWidth } from "../../../../../hooks";
import { MediaBreakPoints } from "../../../../../resources/constants";
import { imagesSelector } from "../../../../../redux/images/selectors";
import { IClubImage } from "../../../../../redux/images/types";
import Styles from "./OurClub.module.scss";
import {fetchGetClubImages} from "../../../../../redux/images/asyncActions";
import {useAppDispatch} from "../../../../../redux/store";
import {Slider} from "../../../../../components";
import styles from "./../HallPhotoRender/PhotoRenderPage.module.scss";

export const OurClub: React.FC = () => {
    const dispatch = useAppDispatch();
    const windowWidth = useCurrentWidth();
    const breakPoint = MediaBreakPoints.MOBILE_LARGE;
    const { clubImages } = useSelector(imagesSelector);

    useEffect(() => {
        dispatch(fetchGetClubImages());
    }, []);

    const imagesClubArray = clubImages.map((imageItem: IClubImage) => {
        return imageItem.url;
    });

    const renderSubTitle = () => {
        if (windowWidth > breakPoint) {
            return <p className={Styles.subTitile}>Фото клуба</p>;
        }
        return null;
    };

    const renderSmallImages = () => {
        return imagesClubArray.slice(1, 13).map((imageUrl, index) => {
            return <img key={index} className={Styles.imgAdaptiveSub} src={imageUrl} />;
        });
    };

    return (
   <div className={`${Styles.wrapper} ${Styles.wrapper_title}`}>
       {renderSubTitle()}
       <div className={styles.slider}>
           <Slider newStyle={'slider_medium_img'} images={imagesClubArray} />
       </div>
       <div className={Styles.imgContainer}>{renderSmallImages()}</div>
   </div>
    );
};

