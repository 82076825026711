import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../../../resources/api";
import { FormNames } from "../../../../resources/constants";

import {ICodeResp, IFetchCodeArgs, IFetchPassArgs, IFetchReqCodeArgs} from "./types";
import {getTokenFromStorage, setTokenToStorage} from "../../../../utils";

export const fetchSendPhone = createAsyncThunk<string, { phone: string }>(
  "changePass/fetchSendPhone",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.changePass.SEND_PHONE}`;

      const response = await axios.post(url, data);

      return response.status === 200 && response.statusText === "OK"
        ? FormNames.MESSAGE
        : FormNames.ERROR;
    } catch (error: any) {
      return error.response.status === 422 ? FormNames.ERROR_PHONE : FormNames.ERROR;
    }
  }
);

export const fetchSendCode = createAsyncThunk<ICodeResp, IFetchCodeArgs>(
  "changePass/fetchSendCode",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.changePass.SEND_CODE}`;

      const response = await axios.post(url, data);
        console.log(response)
      return response.status === 200 && response.statusText === "OK"
        ? { form: FormNames.PASSWORD, token: response.data.token }
        : { form: FormNames.ERROR, token: "" };
    } catch (error: any) {
      return error.response.status === 422
        ? { form: FormNames.ERROR_CODE, token: "" }
        : { form: FormNames.ERROR, token: "" };
    }
  }
);

export const fetchSendRegCode = createAsyncThunk<ICodeResp, IFetchCodeArgs>(
    "changePass/fetchSendCode",
    async (data) => {
        try {
            const url = `${api.BASE_URL}${api.profile.settings.changePass.SEND_CODE}`;
            const response = await axios.post(url, data);
            setTokenToStorage(response.data.token);
            if(response.status === 200){
                console.log('if true')
                return  { form: FormNames.ERROR, token: response.data.token }
            }
            else{
               return { form: FormNames.ERROR, token: "" };
            }

        } catch (error: any) {
            return error.response.status === 422
                ? { form: FormNames.ERROR_CODE, token: "" }
                : { form: FormNames.ERROR, token: "" };
        }
    }
);

export const fetchSendNewPassword = createAsyncThunk<string, IFetchPassArgs>(
  "changePass/fetchSendNewPassword",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.changePass.SEND_NEW_PASSWORD}`;

      const response = await axios.post(url, data);

      return response.status === 200 && response.statusText === "OK"
        ? FormNames.PASSWORD_APPLY
        : FormNames.ERROR;
    } catch (error: any) {
      return FormNames.ERROR;
    }
  }
);
