import React, { useState } from "react";
import { Button, NameInput } from "../../../../components";
import userIcon from "../../../../assets/icons/profileSettings.svg";
import { IProps } from "./types";
import { useAppDispatch } from "../../../../redux/store";
import { editName } from "../../../../redux/profile/settings/changeDataProfile/slice";
import { useSelector } from "react-redux";
import { changeNameSelector } from "../../../../redux/profile/settings/changeDataProfile/selectors";
import {ErrorMessage, Form, Formik, FormikHelpers} from "formik";
import styles from "../PhoneForm/ChangePhone.module.scss";
import * as Yup from "yup";
import {ValidMessages} from "../../../../resources/locale";


export type ValuesType = {
    name: string;
};

const validationSchema = Yup.object({
    name: Yup.string()
        .required(ValidMessages.inputs.name.REQUIRED),
});
export const ChangeUserName: React.FC<IProps> = ({ close, type, placeholder },props) => {
    const dispatch = useAppDispatch();
    const { isLoading } = useSelector(changeNameSelector);
    const { onChangeFunc } = props;
    const handleSubmit = (values: any, actions: FormikHelpers<ValuesType>) => {
        const { name } = values;
        dispatch(editName(name));
        close(name, type);
    };

    const initialValues: ValuesType = {
        name: "",
    };


    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={validationSchema}
        >
            {(formik) => {
                return (
                    <div>
                        <Form>
                            <div className={styles.formSection}>
                                <label htmlFor="name">
                                    <img src={userIcon} alt="phone icon" />
                                </label>
                                <NameInput
                                    name="name"
                                    placeholder="Введите ваше ФИО"
                                    onChangeFunc={onChangeFunc ? onChangeFunc : undefined}
                                />

                                <ErrorMessage
                                    component="div"
                                    className={styles.errorMessage}
                                    name="phone"
                                />
                            </div>
                            {isLoading ? (
                                <div className={styles.loadingMessage}>данные отправляются</div>
                            ) : null}
                            <Button
                                type="submit"
                                newStyles={styles.button}
                                disabled={formik.isSubmitting}
                            >
                                Готово
                            </Button>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};
