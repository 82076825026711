import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchGetClubImages } from "./asyncActions";
import { IImagesState, IClubImage } from "./types";

const initialState: IImagesState = {
  isLoading: false,
  clubImages: [],
};

const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getClubImages
    builder.addCase(fetchGetClubImages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchGetClubImages.fulfilled,
      (state, action: PayloadAction<IClubImage[]>) => {
        state.isLoading = false;
        state.clubImages = action.payload;
      }
    );
    builder.addCase(fetchGetClubImages.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default imagesSlice.reducer;
