import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../resources/api";

import {getTokenFromStorage} from "../../../utils";

export const fetchPurchaseHistory = createAsyncThunk<[]>(
    "getCards/fetchGetCards",
    async () => {
        try {
            const url = `${api.BASE_URL}${api.profile.history.GET_PURCHASE_HISTORY}`;
            const token = getTokenFromStorage();
            const response = await axios.get(url,{"headers": {
                    "Authorization": `Bearer ${token}`
                }});
            return response.data;

        } catch (error) {
            console.log("error fetchPurchaseHistory >>>", error);
        }
    }
);
