import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchFeedback } from "./asyncActions";
import { IFeedbackState } from "./types";

const initialState: IFeedbackState = {
  isLoading: false,
  status: "",
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Feedback
    builder.addCase(fetchFeedback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFeedback.fulfilled, (state, action: PayloadAction<string>) => {
      state.status = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFeedback.rejected, (state) => {
      state.status = "Error";
      state.isLoading = false;
    });
  },
});

export default feedbackSlice.reducer;
