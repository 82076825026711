import React from "react";
import {Button, NameInput} from "../../../../components";
import emailIcon from "../../../../assets/icons/emailTennis.svg";
import {useAppDispatch} from "../../../../redux/store";
import {ErrorMessage, Form, Formik, FormikHelpers} from "formik";
import {IProps} from "./types";
import {useSelector} from "react-redux";
import {changeNameSelector} from "../../../../redux/profile/settings/changeDataProfile/selectors";
import {editEmail} from "../../../../redux/profile/settings/changeDataProfile/slice";
import styles from "../PhoneForm/ChangePhone.module.scss";
import {ValidMessages} from "../../../../resources/locale";
import * as Yup from "yup";

export type ValuesType = {
    email: string;
};
export const ChangeEmail: React.FC<IProps> = ({close,placeholder, type}, props) => {
    const { onChangeFunc } = props;
    const dispatch = useAppDispatch();
    const { isLoading } = useSelector(changeNameSelector);

    const handleSubmit = (values: any, actions: FormikHelpers<ValuesType>) => {
        const { email } = values;
        dispatch(editEmail(email));
        close(email);
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .required(ValidMessages.inputs.email.REQUIRED),
    });


    const initialValues: ValuesType = {
        email: "",
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={validationSchema}
        >
            {(formik) => {
                return (
                    <div>
                        <Form>
                            <div className={styles.formSection}>
                                <label htmlFor="email">
                                    <img src={emailIcon} alt="phone icon" />
                                </label>
                                <NameInput
                                    name="email"
                                    placeholder="Введите вашу почту"
                                    onChangeFunc={onChangeFunc ? onChangeFunc : undefined}
                                />

                                <ErrorMessage
                                    component="div"
                                    className={styles.errorMessage}
                                    name="email"
                                />
                            </div>
                            {isLoading ? (
                                <div className={styles.loadingMessage}>данные отправляются</div>
                            ) : null}
                            <Button
                                type="submit"
                                newStyles={styles.button}
                                disabled={formik.isSubmitting}
                            >
                                Готово
                            </Button>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};
