import React from "react";
import { useSelector } from "react-redux";

import { Header, Title } from "../../../components";
import { HallsItem } from "../components/HallsItem";

import { useAppDispatch } from "../../../redux/store";
import { fetchGetHalls } from "../../../redux/halls/asyncActions";
import { hallsSelector } from "../../../redux/halls/selectors";

import styles from "./Halls.module.scss";

import { hallImages } from "../../../resources/constants";
import { HallsPropsType } from "./types";
import { IHall } from "../../../redux/halls/types";
import { useLocation } from "react-router-dom";

export const Halls: React.FC<HallsPropsType> = ({ title }) => {
  const { halls } = useSelector(hallsSelector);
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    dispatch(fetchGetHalls()); // eslint-disable-next-line
  }, []);

  const hallsItems = renderHallsItems(hallImages, halls);

  function renderHallsItems(hallsImages: { [key: number]: string }, hallsData: IHall[]) {
    return hallsData.map((curHallData: IHall) => (
      <HallsItem key={curHallData.id} data={curHallData} images={curHallData.images} />
    ));
  }

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container container--mobileFull">
          <Title>{title}</Title>
          <div className={styles.items}>{hallsItems}</div>
        </section>
      </main>
    </>
  );
};
