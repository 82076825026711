import React, { useEffect, useRef, useState } from "react";
import Styles from "./CardList.module.scss";
import { CardItem } from "../../components";
import PlusIcon from "../../assets/icons/plus.svg";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { addCardSelector } from "../../redux/profile/paytoken/selectors";
import { getCardSelector } from "../../redux/profile/cards/selectors";
import { fetchGetCards } from "../../redux/profile/cards/asyncActions";
import { api } from "../../resources/api";
import preloader from "../../assets/img/load.gif";
import cardIcon from "../../assets/icons/card.svg";

import { CardListPropsType } from "./types";
import { setCardid } from "../../redux/profile/cards/slice";
import { fetchGetToken } from "../../redux/profile/paytoken/asyncActions";

export const CardList: React.FC<CardListPropsType> = (props) => {
  const { onPage = "profile", onClickFunc } = props;
  const { cards, isLoading } = useSelector(getCardSelector);
  const tokenData = useSelector(addCardSelector);
  const dispatch = useAppDispatch();

  let selectedItem: any = null;
  useEffect(() => {
    dispatch(fetchGetCards());
    dispatch(fetchGetToken());
  }, []);
  const handlePaymentClick = () => {
    window.location.assign(api.kassa.GET_URL(tokenData.confirmation_token));
  };

  const addSelectStyles = React.useCallback((e: any) => {
    if (onPage === "payment") {
      const target = e.target.closest("[data-type]");

      if (target) {
        if (selectedItem) {
          selectedItem.style.boxShadow = "";
        }

        selectedItem = target;
        selectedItem.style.boxShadow = "2px 2px 15px rgba(140, 179, 70, 1)";
      }
    }
  }, []);

  const handleClickOtherMethods = (e: any) => {
    addSelectStyles(e);
    dispatch(setCardid(0));
    if (onClickFunc) onClickFunc();
  };

  const handleSelectItem = (id: number) => {
    dispatch(setCardid(id));
    if (onClickFunc) onClickFunc();
  };

  return (
      <>
        <div className={Styles.item}>
          <p
              className={Styles.subTitile}
              style={onPage === "payment" ? { display: "block" } : {}}
          >
            Мои карты
          </p>
          {isLoading ? (
              <div className={Styles.preloader}>
                <img className={Styles.preloader_img} src={preloader} alt="preloader" />
              </div>
          ) : (
              <div className={Styles.item} onClick={addSelectStyles}>
                {cards.length > 0 &&
                    cards.map((item) => (
                        <CardItem
                            data={item}
                            key={item.id}
                            dataType="cardItem"
                            onPage={onPage}
                            selectFunc={handleSelectItem}
                        />
                    ))}

                <div onClick={handlePaymentClick} className={`${Styles.item_plusItem}`}>
                  <img src={PlusIcon} />
                  <span>Добавить карту</span>
                </div>
              </div>
          )}
          {onPage === "payment" ? (
              <>
                <p className={Styles.subTitile__otherMethods}>Другие способы</p>
                <div
                    className={Styles.otherMethodsItem}
                    data-type="cardItem"
                    onClick={handleClickOtherMethods}
                    onLoad={handleClickOtherMethods}
                >
                  <img src={cardIcon} />
                  <div>Оплатить другим способом</div>
                </div>
              </>
          ) : null}

        </div>
      </>

  );
};
