import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { fetchAuth } from "../../../../redux/profile/authAndReg/asyncActions";
import { authAndRegSelector } from "../../../../redux/profile/authAndReg/selectors";

import { PhoneInput, PasswordInput, Button } from "../../../../components";

import { ValidMessages } from "../../../../resources/locale";

import styles from "./AuthForm.module.scss";
import phoneIcon from "../../../../assets/icons/phoneInput.svg";
import passwordIcon from "../../../../assets/icons/passProfile.svg";
import logo from "../../../../assets/img/logo.svg";
import auth from "../../../../assets/icons/authPic.svg";

import { SubmitValueType } from "./types";

export const AuthForm: React.FC = () => {
  const { isLoading } = useSelector(authAndRegSelector);
  const dispatch = useAppDispatch();

  const initialValues = {
    phone: "",
    password: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
    password: Yup.string()
      .required(ValidMessages.inputs.password.REQUIRED)
      .min(6, ValidMessages.inputs.password.NOT_FULL),
  });

  const handleSubmit = (values: SubmitValueType) => {
    dispatch(fetchAuth(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles.auth}>
              Чтобы Ваши покупки не потерялись - авторизируйтесь или зарегистрируйтесь
              <img src={auth} />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="phone">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput  name="phone" placeholder="+7 (___) ___-__-__" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="password">
                <img src={passwordIcon} alt="password" />
              </label>
              <PasswordInput name="password" placeholder="Пароль" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="password"
              />
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={formik.isSubmitting}
            >
              Войти
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
