import React from "react";
import { useSelector } from "react-redux";

import { CommonErrorModal, Header, Modal, Title } from "../../components";
import { OfferItem } from "./components";
import {
  PersonalTrainingForm,
  PersonalTrainingsDescrForm,
  TennisSchoolForm,
  SchoolSuccessForm,
} from "./Forms";

import { schoolSelector } from "../../redux/school/selectors";
import { useAppDispatch } from "../../redux/store";
import { resetErrorFetch } from "../../redux/school/slice";

import styles from "./School.module.scss";

import { ModalTexts } from "../../resources/locale";
import { SchoolPropsType, WindowTypes } from "./types";
import { useLocation } from "react-router-dom";

export const School: React.FC<SchoolPropsType> = ({ title }) => {
  const [showModal, setShowModal] = React.useState<WindowTypes>("close");
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { isErrorFetch } = useSelector(schoolSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const modals = renderModals(showModal);

  function renderModals(showModal: WindowTypes) {
    switch (showModal) {
      case "tennis-shool":
        return (
          <Modal
            title={ModalTexts.school.tennis.TITLE}
            subTitle={ModalTexts.school.tennis.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <TennisSchoolForm onClickFunc={setShowModal} />
          </Modal>
        );
      case "tennis-shool-success":
        return (
          <Modal
            title={ModalTexts.school.tennisSuccess.TITLE}
            subTitle={ModalTexts.school.tennisSuccess.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <SchoolSuccessForm onClickFunc={handleEndRegisterProcess} />
          </Modal>
        );
      case "personal-trainings-descr":
        return (
          <Modal
            title={ModalTexts.school.personalDescr.TITLE}
            subTitle={ModalTexts.school.personalDescr.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <PersonalTrainingsDescrForm onClickFunc={setShowModal} />
          </Modal>
        );
      case "personal-trainings":
        return (
          <Modal
            title={ModalTexts.school.personal.TITLE}
            subTitle={ModalTexts.school.personal.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <PersonalTrainingForm onClickFunc={setShowModal} />
          </Modal>
        );
      case "personal-trainings-success":
        return (
          <Modal
            title={ModalTexts.school.personalSuccess.TITLE}
            subTitle={ModalTexts.school.personalSuccess.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <SchoolSuccessForm onClickFunc={handleEndRegisterProcess} />
          </Modal>
        );
      case "close":
        return null;
      default:
        return null;
    }
  }

  const handleClickOffer = (windowName: WindowTypes) => {
    setShowModal(windowName);
    setIsOpen(true);
  };

  function handleEndRegisterProcess() {
    dispatch(resetErrorFetch());
    setShowModal("close");
    setIsOpen(false);
  }

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>
          <div className={styles.items}>
            <OfferItem
              title="Школа тенниса"
              description="Здесь Вы сможете оставить заявку на групповые тренировки по теннису для детей от 3-х лет"
              onClick={() => handleClickOffer("tennis-shool")}
            />
            <OfferItem
              title="Персональные тренировки"
              description="Персональные тренировки для спортсменов и любителей"
              onClick={() => handleClickOffer("personal-trainings-descr")}
            />
          </div>
        </section>
      </main>
      {isOpen ? (
        isErrorFetch ? (
          <CommonErrorModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndRegisterProcess}
          />
        ) : (
          modals
        )
      ) : null}
    </>
  );
};
