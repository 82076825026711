import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { authAndRegSelector } from "../../redux/profile/authAndReg/selectors";
import { setShowWindow } from "../../redux/profile/authAndReg/slice";
import { rerender } from "../../redux/rerender/slice";
import { useAppDispatch } from "../../redux/store";

import { LoginModal } from "../../components";

import styles from "./LoginIcon.module.scss";
import loginIcon from "../../assets/icons/login.svg";

import profileIcon from "../../assets/icons/profile.svg";

import { WindowNames } from "../../resources/constants";
import { LoginIconPropsType } from "./types";

export const LoginIcon: React.FC<LoginIconPropsType> = ({ isMobileMenu = false }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { auth } = useSelector(authAndRegSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleClickLogin = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setShowWindow(WindowNames.AUTH));
    setIsOpen(true);
  };

  const handleClickProfile = () => {
    const currentPath = location.pathname;
    if (
      currentPath === "/profile" ||
      currentPath === "/profile/history" ||
      currentPath === "/profile/cards" ||
      currentPath === "/profile/settings"
    ) {
      dispatch(rerender());
    }
  };

  return (
    <div className={isMobileMenu ? styles.icons__auth_mobileMenu : styles.icons__auth}>
      {auth ? (
        <Link
          to="/profile"
          onClick={handleClickProfile}
          state={{ from: location.pathname }}
        >
          <img src={profileIcon} alt="profile" />
          <span>Профиль</span>
        </Link>
      ) : (
        <a href="" onClick={handleClickLogin}>
          {isMobileMenu ? null : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 7V3C16 2.44772 15.5523 2 15 2H4C3.44772 2 3 2.44772 3 3V21C3 21.5523 3.44772 22 4 22H15C15.5523 22 16 21.5523 16 21V17" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12L7 12M7 12L12 16M7 12L12 8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          )}
          

          <span>Вход</span>
        </a>
      )}
      {isOpen ? <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
    </div>
  );
};
