import React from "react";
import dateFormat from "dateformat";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { setCartItems } from "../../../../redux/cart/slice";
import { cartSelector } from "../../../../redux/cart/selectors";
import { setSelectedSubscr } from "../../../../redux/subscriptions/slice";
import { setSelectedHalls } from "../../../../redux/halls/slice";

import {
  addCurrencyWord,
  setCartItemsToStorage,
  setSelectedSubscrToStorage,
} from "../../../../utils";
import { setSelectedHallsToStorage } from "../../../../utils/storage/halls";

import styles from "./CartIem.module.scss";
import hallIcon from "../../../../assets/icons/hallType.svg";
import calendarIcon from "../../../../assets/icons/calendar.svg";

import { CartItemPropsType } from "./types";
import { ISelectedSubscr } from "../../../../redux/subscriptions/types";
import { ISelectedHall } from "../../../../redux/halls/types";

export const CartItem: React.FC<CartItemPropsType> = (props) => {
  const {
    itemId,
    itemType,
    date,
    time,
    price,
    title,
    quantity,
    expiration,
    setIsOpenModalNoAdultSbscr,
  } = props;

  const { totalCartItems } = useSelector(cartSelector);
  const dispatch = useAppDispatch();

  const cartItemTime = getRentTimeRange(time);
  const cartItemDate = getRentDate(date);
  const cartItemPrice = addCurrencyWord(price);

  function getRentTimeRange(time: string | undefined) {
    if (time) {
      const startHour = `${Number(time.substring(0, 2).replaceAll(":", ""))}`;
      const endHour = `${Number(time.substring(0, 2).replaceAll(":", "")) + 1}`;

      const startRange = startHour.length === 1 ? `0${startHour}:00` : `${startHour}:00`;
      const endRange = endHour.length === 1 ? `0${endHour}:00` : `${endHour}:00`;

      return `${startRange} - ${endRange}`;
    }
  }

  function getRentDate(date: string | undefined) {
    if (date) {
      const rentDayName = dateFormat(new Date(Date.parse(date)), "ddd");
      const rentDay = dateFormat(new Date(Date.parse(date)), "dd");
      const rentYear = dateFormat(new Date(Date.parse(date)), "yyyy");

      const rentMonth = dateFormat(new Date(Date.parse(date)), "mmmm").toLowerCase();

      const rentMonthCorrected =
        rentMonth === "март" || rentMonth === "август"
          ? `${rentMonth}а`
          : `${rentMonth.substring(0, rentMonth.length - 1)}я`;

      return `${rentDayName} ${rentDay} ${rentMonthCorrected} ${rentYear}`;
    }
  }

  const handleDeleteCartItem = () => {
    let cloneTotalCartItems = JSON.parse(JSON.stringify(totalCartItems));

    const cloneTotalCartItemsInType = JSON.parse(
      JSON.stringify(cloneTotalCartItems[itemType])
    );

    const filtredCloneTotalItemsInType = Object.keys(cloneTotalCartItemsInType)
      .filter((key) => {
        return itemType === "seats"
          ? cloneTotalCartItemsInType[key].hallId !== itemId ||
              cloneTotalCartItemsInType[key].time !== time ||
              cloneTotalCartItemsInType[key].date !== date
          : cloneTotalCartItemsInType[key].subscription.id !== itemId;
      })
      .reduce((res: { [key: string]: ISelectedSubscr | ISelectedHall | any }, key) => {
        res[key] = cloneTotalCartItemsInType[key];
        return res;
      }, {});

    if (itemType === "subscriptions") {
      const deletingItem = Object.keys(cloneTotalCartItemsInType)
        .filter((key) => cloneTotalCartItemsInType[key].subscription.id === itemId)
        .reduce((res: { [key: string]: ISelectedSubscr }, key) => {
          res[key] = cloneTotalCartItemsInType[key];
          return res;
        }, {});

      const delitingItemSbscrType = Object.values(deletingItem)[0].subscription.type;

      if (delitingItemSbscrType === "adult") {
        const doesItHaveAnotherAdult = Object.values(filtredCloneTotalItemsInType).find(
          (item) => item.subscription.type === "adult"
        );
        const doesItHaveKid = Object.values(filtredCloneTotalItemsInType).find(
          (item) => item.subscription.type === "kid"
        );

        if (!doesItHaveAnotherAdult && doesItHaveKid) {
          setIsOpenModalNoAdultSbscr(true);

          Object.keys(filtredCloneTotalItemsInType).forEach(
            (key) => delete filtredCloneTotalItemsInType[key]
          );
        }
      }

      dispatch(setSelectedSubscr(filtredCloneTotalItemsInType));
      setSelectedSubscrToStorage(filtredCloneTotalItemsInType);
    }

    if (itemType === "seats") {
      dispatch(setSelectedHalls(filtredCloneTotalItemsInType));
      setSelectedHallsToStorage(filtredCloneTotalItemsInType);
    }

    cloneTotalCartItems[itemType] = filtredCloneTotalItemsInType;
    setCartItemsToStorage(cloneTotalCartItems);
    dispatch(setCartItems(cloneTotalCartItems));
  };

  return (
    <>
      <div id={`${itemId}`} className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.type}>{itemType === "seats" ? "Аренда залов" : title}</p>
          <button className={styles.deleteButton} onClick={handleDeleteCartItem} />
        </div>
        <div className={styles.body}>
          <p
            className={
              itemType === "seats"
                ? `${styles.line_1} ${styles.fs_italic}`
                : styles.line_1
            }
          >
            {itemType === "seats" && <img src={hallIcon} alt="hall type" />}
            {itemType === "seats" ? title : expiration}
          </p>
          <p
            className={
              itemType === "seats"
                ? `${styles.line_2} ${styles.fs_italic}`
                : styles.line_2
            }
          >
            {itemType === "seats" && <img src={calendarIcon} alt="calendar" />}
            {itemType === "seats"
              ? `${cartItemDate}, ${cartItemTime}`
              : typeof quantity === "number"?
                `Количество: ${quantity}`:
                (
                  <div className={styles.family_counter}>
                    <p className={styles.line_2 && styles.line_2_bold}>Количество взрослых:</p>
                    <p>{quantity !== undefined && quantity[0]}</p>
                    <p className={styles.line_2 && styles.line_2_bold}>Количество детских:</p>
                    <p>{quantity !== undefined && quantity[1]}</p>
                  </div>
                )
            }
          </p>
        </div>
        <div className={styles.footer}>
          <p className={styles.price}>Стоимость - {cartItemPrice}</p>
        </div>
      </div>
    </>
  );
};
