import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IMyprofile, IProfile, IProfileState} from "./types";
import {fetchMyProfiles, fetchProfileInfoMenu} from "./asyncActions";

const initialState: IProfileState = {
    profile: [],
    myProfiles: [],
    isLoading: false,
    deleteProfile: {},
    newProfile: {},
}

const GetProfileInfoMenu = createSlice({
  name: "getProfileInfo",
  initialState,
  reducers: {
      setDeleteProfile(state, action: PayloadAction<IMyprofile>) {
          state.deleteProfile = {
              ...state.deleteProfile,
              ...action.payload,
          };
      },
      editProfile(state, action: PayloadAction<object>) {
          state.newProfile = action.payload;
      },
  },
  extraReducers: (builder) => {
      builder.addCase(fetchProfileInfoMenu.pending, (state) => {
          state.isLoading = true;
      })
      builder.addCase(
          fetchProfileInfoMenu.fulfilled,
          (state, action: PayloadAction<IProfile[]>) => {
              state.profile = action.payload
              state.isLoading = false;
          }
      );
      builder.addCase(fetchMyProfiles.pending, (state) => {
          state.isLoading = true;
      })
      builder.addCase(
          fetchMyProfiles.fulfilled,
          (state, action: PayloadAction<IMyprofile[]>) => {
              state.myProfiles = action.payload
              state.isLoading = false;
          }
      );
  },
});

export default GetProfileInfoMenu.reducer;
export const { setDeleteProfile,editProfile } = GetProfileInfoMenu.actions;

