import React from "react";
import { useSelector } from "react-redux";

import styles from "./Seat.module.scss";

import { hallsSelector } from "../../../../redux/halls/selectors";

import { ISeatProps } from "./types";

export const SeatItem = (props: ISeatProps) => {
  const { disabled = false, data, onClickFunc } = props;
  const { id, date, time, price, hallTitle } = data;
  const { selectedHalls } = useSelector(hallsSelector);
  const [selectStatus, setSelectStatus] = React.useState<boolean>(false);

  const thisSeatKeyName = `${time}-${id}-${date}`;

  React.useEffect(() => {
    Object.keys(selectedHalls).forEach((key) => {
      if (key === thisSeatKeyName) setSelectStatus(true);
    }); // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    const selctedHall = {
      date,
      hallId: id,
      hallTitle,
      price,
      time,
      unique: thisSeatKeyName,
    };

    // delete
    if (selectStatus) {
      const cloneState = JSON.parse(JSON.stringify(selectedHalls));
      delete cloneState[thisSeatKeyName];
      onClickFunc(cloneState);
    }

    // add
    if (!selectStatus) {
      const cloneState = JSON.parse(JSON.stringify(selectedHalls));
      cloneState[thisSeatKeyName] = selctedHall;
      onClickFunc(cloneState);
    }

    setSelectStatus((selectStatus) => !selectStatus);
  };

  return (
    <>
      <button
        disabled={disabled}
        className={selectStatus ? `${styles.item} ${styles.item_selected}` : styles.item}
        onClick={handleClick}
      >
        <p>{time}</p>
        <p>{`${price} р/ч`}</p>
      </button>
    </>
  );
};
