import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import subscriptions from "./subscriptions/slice";
import images from "./images/slice";
import halls from "./halls/slice";

import changePass from "./profile/settings/changePass/slice";
import rent from "./rent/slice";
import cart from "./cart/slice";
import authAndReg from "./profile/authAndReg/slice";
import getToken from "./profile/paytoken//slice";
import getCards from "./profile/cards/slice";
import getHistory from "./profile/history/slice";
import getProfile from "./profile/settings/getProfileInfo/slice";
import changeName from "./profile/settings/changeDataProfile/slice";
import addCard from "./profile/cards//slice";
import rerender from "./rerender/slice";
import feedback from "./feedback/slice";
import school from "./school/slice";
import news from "./News/slice";
import getHalls from "./profile/hallsHistory/slice";
import purchaseHistory from "./profile/purchaseHistory/slice";
import getProfileInfo from "./profile/profileInfo/slice";

const store = configureStore({
  reducer: {
    subscriptions,
    images,
    halls,

    rerender,
    authAndReg,
    rent,
    cart,
    getCards,
    getHistory,
    getProfile,
    changeName,
    changePass,
    addCard,
    getToken,
    news,
    feedback,
    school,
    getHalls,
    purchaseHistory,
    getProfileInfo,
  },
  devTools: process.env.NODE_ENV !== "production",
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;

export default store;
