import React from "react";
import { Field } from "formik";
import { nanoid } from "nanoid";

import styles from "./NameInput.module.scss";

import { FieldPropsType, NameInputPropsType } from "./types";

export const NameInput: React.FC<NameInputPropsType> = (props) => {
  const {
    name,
    focusThisInput = false,
    placeholder,
    onChangeFunc,
    isFilledFormsTest,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (focusThisInput) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldProps: FieldPropsType,
    name: string
  ) => {
    const { form } = fieldProps;
    if (onChangeFunc) onChangeFunc({ name: e.currentTarget.value });
    form.setFieldValue(name, e.currentTarget.value);
  };

  return (
    <>
      <Field name={name}>
        {(fieldProps: FieldPropsType) => {
          const { field, meta } = fieldProps;

          return (
            <div>
              <input
                ref={inputRef}
                type="text"
                id={`${name}-${nanoid()}`}
                placeholder={placeholder}
                className={`${styles.input} 
							${meta.touched && meta.error ? styles.error : null}
							${isFilledFormsTest && meta.error ? styles.error : null}`}
                {...field}
                onChange={(e) => handleChange(e, fieldProps, name)}
              />
            </div>
          );
        }}
      </Field>
    </>
  );
};
