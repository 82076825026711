import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../resources/api";

import {IHalls} from "./types";
import {getTokenFromStorage} from "../../../utils";

export const fetchHalls = createAsyncThunk<IHalls[]>(
    "getCards/fetchGetCards",
    async () => {
        try {
            const url = `${api.BASE_URL}${api.halls.GET_HALLSHISTORY}`;
            const token = getTokenFromStorage();
            const response = await axios.get(url,{"headers": {
                    "Authorization": `Bearer ${token}`
                }});
            return response.data;

        } catch (error) {
            console.log("error fetchHalls >>>", error);
        }
    }
);
