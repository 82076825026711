import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IProfile,IProfileState} from "./types";
import {fetchProfileInfo} from "./asyncActions";

const initialState: IProfileState = {
    profileInfo: []
}

const GetCards = createSlice({
  name: "getCards",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    //get profileInfo
    builder.addCase(
        fetchProfileInfo.fulfilled,
        (state, action: PayloadAction<IProfile[]>) => {
            state.profileInfo = action.payload
        }
    );
  },
});

export default GetCards.reducer;

