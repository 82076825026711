import React from "react";
import { useSelector } from "react-redux";

import { Button, CommonErrorModal, Modal, PassChangeModal } from "../../components";
import { AuthForm, RegisterForm } from "./Forms";

import { useAppDispatch } from "../../redux/store";
import { setShowForm } from "../../redux/profile/settings/changePass/slice";
import { setShowWindow, resetShowWindow } from "../../redux/profile/authAndReg/slice";
import { authAndRegSelector } from "../../redux/profile/authAndReg/selectors";

import styles from "./LoginModal.module.scss";

import { FormNames, WindowNames } from "../../resources/constants";
import { ModalTexts } from "../../resources/locale";

import { LoginModalPropsType } from "./types";
import { SmsRegCodeForm } from "../PassChangeModal/Forms/SmsReg/SmsReg";

export const LoginModal: React.FC<LoginModalPropsType> = (props) => {
  const { isOpen, setIsOpen } = props;
  const { showWindow } = useSelector(authAndRegSelector);
  const dispatch = useAppDispatch();

  const handleChangePass = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setShowWindow(WindowNames.FORGOT_PASS));
    dispatch(setShowForm(FormNames.PHONE));
  };

  const handleRegister = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setShowWindow(WindowNames.REGISTRATION));
  };

  const handleAuth = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setShowWindow(WindowNames.AUTH));
  };

  const handleEndProcess = () => {
    setIsOpen(false);
    dispatch(resetShowWindow());
  };

  const handleSetCodeForm = () => {
    dispatch(setShowWindow(WindowNames.CODE));
  };

  const currentModal = renderModalController();

  function renderModalController() {
    switch (showWindow) {
      case WindowNames.AUTH:
        return (
          <Modal
            title={ModalTexts.auth.authStart.TITLE}
            subTitle={ModalTexts.auth.authStart.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <AuthForm />
            <div className={styles.links}>
              {/*  eslint-disable-next-line */}
              <a href="#" className={styles.link} onClick={handleRegister}>
                Зарегистрироваться
              </a>
              <a href="#" className={`${styles.link_forgot}`} onClick={handleChangePass}>
                Забыли пароль?
              </a>
              {/*  eslint-disable-next-line */}
            </div>
          </Modal>
        );
      case WindowNames.AUTH_SUCCESS:
        return (
          <Modal
            title={ModalTexts.auth.authSuccess.TITLE}
            subTitle={ModalTexts.auth.authSuccess.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <Button newStyles={styles.button} onClickFunc={handleEndProcess}>
              Ок
            </Button>
          </Modal>
        );
      case WindowNames.AUTH_ERROR:
        return (
          <Modal
            title={ModalTexts.auth.authError.TITLE}
            subTitle={ModalTexts.auth.authError.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <Button newStyles={styles.button} onClickFunc={handleAuth}>
              Назад
            </Button>
          </Modal>
        );
      case WindowNames.CODE:
        return (
          <Modal
            title={ModalTexts.changePass.confirmMessage.TITLE}
            subTitle={ModalTexts.changePass.confirmMessage.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <SmsRegCodeForm />
          </Modal>
        );
      case WindowNames.REGISTRATION:
        return (
          <Modal
            title={ModalTexts.register.regStart.TITLE}
            subTitle={ModalTexts.register.regStart.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <RegisterForm />
            <div className={styles.links_register}>
              Есть акканут?
              {/*  eslint-disable-next-line */}
              <a href="#" className={`${styles.link_forgot}`} onClick={handleAuth}>
                Войти
              </a>
            </div>
          </Modal>
        );

      case WindowNames.REGISTRATION_SUCCESS:
        return (
          <Modal
            title={ModalTexts.register.regSuccess.TITLE}
            subTitle={ModalTexts.register.regSuccess.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <Button newStyles={styles.button} onClickFunc={handleEndProcess}>
              Ок
            </Button>
          </Modal>
        );
      case WindowNames.FORGOT_PASS:
        return (
          <PassChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={ModalTexts.changePass.inputPhone.recovery.TITLE}
            subTitle={ModalTexts.changePass.inputPhone.recovery.SUB_TITLE}
          />
        );
      case WindowNames.ERROR_CODE:
        return (
          <Modal
            title={ModalTexts.changePass.errorCode.TITLE}
            subTitle={ModalTexts.changePass.errorCode.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndProcess}
          >
            <Button newStyles={styles.button} onClickFunc={handleSetCodeForm}>
              Назад
            </Button>
          </Modal>
        );
      case WindowNames.ERROR:
        return (
          <CommonErrorModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndProcess}
          />
        );
      default:
        return null;
    }
  }

  return <>{currentModal}</>;
};
