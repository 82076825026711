import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../resources/api";
import { IHall, ITime } from "./types";

export const fetchGetHalls = createAsyncThunk<IHall[]>(
  "halls/fetchGetHalls",
  async () => {
    try {
      const url = `${api.BASE_URL}${api.halls.GET_HALLS}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.log("error halls/fetchGetHalls >>>", error);
    }
  }
);

export const fetchGetHall = createAsyncThunk<ITime[], { hallId: number; date: string }>(
  "halls/fetchGetHall",
  async (data) => {
    try {
      const { hallId, date } = data;
      const url = `${api.BASE_URL}${api.halls.GET_HALL(hallId, date)}`;
      const response = await axios.get(url);
      return response.data.times;
    } catch (error) {
      console.log("error halls/fetchGetHall >>>", error);
    }
  }
);
