import React from "react";

import { Button, Modal } from "../../components";

import styles from "./CommonErrorModal.module.scss";

import { ModalTexts } from "../../resources/locale";
import { ErrorModalPropsType } from "./type";

export const CommonErrorModal: React.FC<ErrorModalPropsType> = (props) => {
  const {
    isOpen,
    setIsOpen,
    closeFunc,
    modalWindowWidth = 415,
    mobileContentSize = "full-screen",
  } = props;

  const handleClickButton = () => {
    setIsOpen((isOpen) => !isOpen);
    if (closeFunc) closeFunc();
  };

  return (
    <Modal
      title={ModalTexts.common.error.TITLE}
      subTitle={ModalTexts.common.error.SUB_TITLE}
      modalWindowWidth={modalWindowWidth}
      mobileContentSize={mobileContentSize}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeFunc={closeFunc}
    >
      <Button newStyles={styles.button} onClickFunc={handleClickButton}>
        Ок
      </Button>
    </Modal>
  );
};
