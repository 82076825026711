import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../redux/store";

import { subscriptionsSelector } from "../../../../redux/subscriptions/selectors";
import {
  resetSelectedAdultSubscr, resetSelectedFamilySubscr, resetSelectedKidSubscr,
  setSelectedAdultSubscr,
  setSelectedFamilySubscription,
  setSelectedKidSubscr,
} from "../../../../redux/subscriptions/slice";
import { addCurrencyWord } from "../../../../utils";

import styles from "./SubscrItem.module.scss";

import { SubscrItemPropsType } from "./types";

export const SubscrItem: React.FC<SubscrItemPropsType> = (props) => {
  const { id, title, type, expiration_text, includes, price, discount_text } = props.data;
  const { selectedAdultSubscr } = useSelector(subscriptionsSelector);
  const [quantity, setQuantity] = React.useState(0);
  const [quantityAdult, setQuantityAdult] = React.useState(0);
  const [quantityKid, setQuantityKid] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const isFirstRender = React.useRef(true);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (isFirstRender.current) isFirstRender.current = false;
    setSelectedSubscr(quantity);
    // eslint-disable-next-line
  }, [quantity]);

  React.useEffect(() => {
    if (quantityAdult === 0) {
      setQuantityKid(0)
    } else {
      dispatch(resetSelectedAdultSubscr());
      dispatch(resetSelectedKidSubscr());
      setTotalPrice(price + 1000 * (quantityAdult + quantityKid - 2))
    }
  }, [quantityKid, quantityAdult])

  React.useEffect(() => {
    setSelectedFamilySubscr(quantityAdult, quantityKid)
  }, [totalPrice])

  React.useEffect(() => {
    if (selectedAdultSubscr.length === 0) setQuantity(0)
    else {
      setQuantityAdult(0);
      dispatch(resetSelectedFamilySubscr());
    }
  }, [selectedAdultSubscr]);

  const maxQuantity = 10;
  const maxQuantityFamily = 8;
  const includesItems = renderIncludesItems(includes);
  const disabledBtnPlusStatus = getDisabledStatus("plus");
  const disabledBtnMinusStatus = getDisabledStatus("minus");

  function getDisabledStatus(typeButton: string, relativeBtn: string|null = null) {
    if (type === "adult") {
      return typeButton === "minus" ? quantity <= 0 : quantity >= maxQuantity;
    }
    if (type === "kid" && typeButton === "minus") {
      return selectedAdultSubscr.length > 0 ? quantity <= 0 : true;
    }
    if (type === "kid" && typeButton === "plus") {
      return selectedAdultSubscr.length > 0 ? quantity >= maxQuantity : true;
    }
    if (type === "family") {
      if (typeButton === "plus") {
        if (
          (relativeBtn === "adult" && quantityAdult >= 2) ||
          (relativeBtn === "kid" && quantityAdult === 0)
        ) return true;

        return quantityAdult + quantityKid >= maxQuantityFamily
      }

      return quantityAdult <= 0
    }
  }
  function renderIncludesItems(includes: string[]) {
    // eslint-disable-next-line
    return includes.map((item, i) => {
      switch (item) {
        case "gym":
          return (
            <div className={styles.includes_item_gym} key={i}>
              Фитнес зал
            </div>
          );
        case "hotTub":
          return (
            <div className={styles.includes_item_pool} key={i}>
              Бассейн
            </div>
          );
        case "spa":
          return (
            <div className={styles.includes_item_spa} key={i}>
              Сауна
            </div>
          );
        default:
          break;
      }
    });
  }

  const renderCounters = (type: string) => {
    if (type === 'family') {
      return (
        <div>
          <div className={styles.quantity_family}>
            <p className={styles.quantity_text_family}>Количество взрослых:</p>
            <button
              className={styles.quantity_button_minus}
              onClick={() => handleQuantityDecrease(type, 'adult')}
              disabled={getDisabledStatus("minus", "adult")}
            />
            <span className={styles.quantity_value}>{quantityAdult}</span>
            <button
              className={styles.quantity_button_plus}
              onClick={() => handleQuantityIncrease(type, 'adult')}
              disabled={getDisabledStatus("plus", "adult")}
            />
          </div>
          <div className={styles.quantity_family}>
            <p className={styles.quantity_text_family}>Количество детей:</p>
            <button
              className={styles.quantity_button_minus}
              onClick={() => handleQuantityDecrease(type, 'kid')}
              disabled={getDisabledStatus("minus", "kid")}
            />
            <span className={styles.quantity_value}>{quantityKid}</span>
            <button
              className={styles.quantity_button_plus}
              onClick={() => handleQuantityIncrease(type, 'kid')}
              disabled={getDisabledStatus("plus", "kid")}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.quantity}>
          <p className={styles.quantity_text}>Выберите количество:</p>
          <button
            className={styles.quantity_button_minus}
            onClick={() => handleQuantityDecrease(type)}
            disabled={disabledBtnMinusStatus}
          />
          <span className={styles.quantity_value}>{quantity}</span>
          <button
            className={styles.quantity_button_plus}
            onClick={() => handleQuantityIncrease(type)}
            disabled={disabledBtnPlusStatus}
          />
        </div>
      )
    }
  }

  const handleQuantityDecrease = (typeSubs: string, type: string | null = null ) => {
    if (typeSubs === 'family') {
      type === 'kid'?
        setQuantityKid((quantityKid) => quantityKid - 1)
        :
        setQuantityAdult((quantityAdult) => quantityAdult - 1);
    } else {
      setQuantity((quantity) => quantity - 1);
    }
  };

  const handleQuantityIncrease = (typeSubs: string, type: string | null = null) => {
    if (typeSubs === 'family') {
      type === 'kid'?
        setQuantityKid((quantityKid) => quantityKid + 1)
        :
        setQuantityAdult((quantityAdult) => quantityAdult + 1);
    } else {
      setQuantity((quantity) => quantity + 1);
    }
  };

  const setSelectedSubscr = (quantity: number) => {
    const selectedSubscr = { subscription: props.data, quantity };
    if (type === "adult") dispatch(setSelectedAdultSubscr(selectedSubscr));
    if (type === "kid") dispatch(setSelectedKidSubscr(selectedSubscr));
  };

  const setSelectedFamilySubscr = (quantityAdult: number, quantityKid: number) => {
    const subscription = Object.assign({}, props.data);
    subscription.price = totalPrice;
    const selectedSubscr = {
      subscription: subscription,
      quantity: [quantityAdult, quantityKid],
      profilesCount: {
        adult: quantityAdult,
        kid: quantityKid
      }
    };
    if (type === "family" && (quantityAdult + quantityKid > 2)) dispatch(setSelectedFamilySubscription(selectedSubscr));
  };

  return (
    <div id={id} className={ type === 'family'? styles.new_item : styles.item}>
      <h3 className={styles.title}>{title}</h3>
      {type === "kid" ? (
        <div className={styles.warningTop}>Не продается отдельно от взрослого!</div>)
        : type === 'family' ?
          (<div className={styles.warningTop}> Для 3-го и последующих членов семьи карта по 1000 рублей!</div>)
          :
          null
      }
      <div className={styles.info}>
        {
          type !== 'family' && (
            <div className={styles.info_age}>
              <p>
                Возраст:
                <span>{type === "adult" ? " от 14 лет" : " от 1 года до 13 лет"}</span>
              </p>
            </div>
          )
        }
        <div className={styles.info_expiration}>
          <p>
            Срок действия: <span>{expiration_text}</span> <span>(со дня оплаты)</span>
          </p>
        </div>
        <div className={styles.info_time}>
          <p>
            Время посещения: <span>пн - вс 7.00 - 24.00</span>
          </p>
        </div>
      </div>
      <div className={styles.includes}>
        <p className={styles.includes_title}>Включено:</p>
        <div className={styles.includes_items}>{includesItems}</div>
      </div>
      <div>
        {renderCounters(type)}
      </div>
      {
        type === 'family'?
          quantityKid + quantityAdult < 3?
            (<div className={styles.text_minimal_count}>Минимальное количество людей - 3</div>)
            :
            (<div className={styles.price}>Стоимость - {addCurrencyWord(totalPrice)}</div>)
          :
          (<div className={styles.price}>Стоимость - {addCurrencyWord(price)}</div>)
      }
      <div className={styles.discount}>{discount_text}</div>
      <div className={styles.warningBottom}>*Не включены групповые занятия</div>
      {
        type !== 'adult' && (
          <div className={styles.warningBottom}>*Дети до 14 лет</div>
        )
      }
    </div>
  );
};
