import React, {useState} from "react";

import { ProfileAdultForm, ProfileKidForm } from "../../../../components";

import { useAppDispatch } from "../../../../redux/store";
import { setSubscrFormsData } from "../../../../redux/subscriptions/slice";

import styles from "./FormItem.module.scss";

import { addCurrencyWord } from "../../../../utils";
import { FormItemPropsType } from "./types";
import { IProfileData, ISubscrFormData } from "../../../../redux/subscriptions/types";

export const FormItem: React.FC<FormItemPropsType> = (props) => {
  const { quantity, num, subscription, isFilledFormsTest, formsData, profilesCount } = props;
  const { title, price, type } = subscription;

  const dispatch = useAppDispatch();
  const profileValues = getProfileValues(formsData);
  const currentTitle = `${title} №${num}`;

  const currentFamilyTitle = (title: string) => {
    if (title === 'adult') return `Взрослый профиль №${num}`

    return `Детский профиль №${num}`
  }

  const [adultCounter, setAdultCounter] = useState(0)
  const [kidCounter, setKidCounter] = useState(0)

  React.useEffect(() => {
    if (typeof quantity === "object") {
      setAdultCounter(quantity[0])
    } else {
      subscription.type === "adult"?
        setAdultCounter(quantity)
        :
        setKidCounter(quantity)
    }
  }, [])

  function getProfileValues(formsData: ISubscrFormData[] | null) {
    if (formsData) {
      const formData = formsData.reduce((acc: any, item: ISubscrFormData) => {
        if (item.subscrData.id === subscription.id && item.subscrNum === num) {
          return { ...acc, ...item };
        } else {
          return { ...acc };
        }
      }, {});

      return formData.profileValues;
    }
  }

  const renderFamilyForm = () => {

    if (num <= adultCounter) {
      return (
        <ProfileAdultForm
          page={"subscription"}
          onChangeFunc={handleSetSubscrFormData}
          isFilledFormsTest={isFilledFormsTest}
          profilesListIcon
          formData={{
            subscrData: subscription,
            subscrNum: num,
            quantity,
            type: "adult",
            profilesCount: profilesCount,
            profileValues: { name: "", secondaryText: "", type: "adult" },
          }}
          profileValues={profileValues ? profileValues : null}
        />
      )
    } else {
      return (
        <ProfileKidForm
          page={"subscription"}
          onChangeFunc={handleSetSubscrFormData}
          isFilledFormsTest={isFilledFormsTest}
          profilesListIcon
          formData={{
            subscrData: subscription,
            subscrNum: num,
            quantity,
            type: "kid",
            profilesCount: profilesCount,
            profileValues: { name: "", secondaryText: "", type: "kid" },
          }}
          profileValues={profileValues ? profileValues : null}
        />
      )
    }
  }

  const handleSetSubscrFormData = (values: { [key: string]: string }) => {
    const formatedValues: IProfileData = {} as IProfileData;

    if ("name" in values) formatedValues.name = values.name;
    if ("phone" in values) {
      formatedValues.secondaryText = values.phone;
      formatedValues.type = "adult"
    }
    if ("date" in values){
      formatedValues.secondaryText = values.date;
      formatedValues.type = "kid"
    }

    if (subscription.type === "family") {
      const subscrFormData = {
        subscrData: subscription,
        subscrNum: num,
        quantity,
        profileValues: formatedValues,
        profilesCount: profilesCount
      };

      dispatch(setSubscrFormsData(subscrFormData));
    } else {
      const subscrFormData = {
        subscrData: subscription,
        subscrNum: num,
        quantity,
        profileValues: formatedValues,
      };

      dispatch(setSubscrFormsData(subscrFormData));
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        {
          type !== "family"?
            <h4 className={styles.title}>{currentTitle}</h4>
            :
            num <= adultCounter?
              <h4 className={styles.title}>{currentFamilyTitle('adult')}</h4>
              :
              <h4 className={styles.title}>{currentFamilyTitle('kid')}</h4>
        }
        <span className={styles.price}>{addCurrencyWord(price)}</span>
      </div>
      <div className={styles.body}>
        {
          type === "family"?
            renderFamilyForm()
            :
            type === "adult"? (
              <ProfileAdultForm
                page={"subscription"}
                onChangeFunc={handleSetSubscrFormData}
                isFilledFormsTest={isFilledFormsTest}
                profilesListIcon
                formData={{
                  subscrData: subscription,
                  subscrNum: num,
                  quantity,
                  profileValues: { name: "", secondaryText: "" },
                }}
                profileValues={profileValues ? profileValues : null}
              />
            ) : (
              <ProfileKidForm
                page={"subscription"}
                onChangeFunc={handleSetSubscrFormData}
                isFilledFormsTest={isFilledFormsTest}
                profilesListIcon
                formData={{
                  subscrData: subscription,
                  subscrNum: num,
                  quantity,
                  profileValues: { name: "", secondaryText: "" },
                }}
                profileValues={profileValues ? profileValues : null}
              />
            )
        }
      </div>
    </div>
  );
};
