import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { rerender } from "../../redux/rerender/slice";
import { useAppDispatch } from "../../redux/store";
import { hallsSelector } from "../../redux/halls/selectors";
import { ISelectedHall } from "../../redux/halls/types";
import { setSelectedHalls } from "../../redux/halls/slice";
import { setSelectedSubscr } from "../../redux/subscriptions/slice";
import { subscriptionsSelector } from "../../redux/subscriptions/selectors";
import { ISelectedSubscr } from "../../redux/subscriptions/types";
import {HandySvg} from 'handy-svg'
import styles from "./CartIcon.module.scss";
import cartEmptyIcon from "../../assets/icons/cartEmpty.svg";
import cartFullIcon from "../../assets/icons/cartFull.svg";
import cartEmptyMobileIcon from "../../assets/icons/cartEmptyMobile.svg";
import cartFullMobileIcon from "../../assets/icons/cartFullMobile.svg";

import { getSelectedSubscrFromStorage } from "../../utils";
import { useCurrentWidth } from "../../hooks";
import { MediaBreakPoints } from "../../resources/constants";
import { getSelectedHallsFromStorage } from "../../utils/storage/halls";

export const CartIcon: React.FC = () => {
  const { selectedHalls } = useSelector(hallsSelector);
  const { selectedSubscr } = useSelector(subscriptionsSelector);

  React.useEffect(() => {
    const storageSelectedHalls = getSelectedHallsFromStorage();
    const storageSelectedSubscr = getSelectedSubscrFromStorage();

    if (storageSelectedHalls) dispatch(setSelectedHalls(storageSelectedHalls));
    if (storageSelectedSubscr) dispatch(setSelectedSubscr(storageSelectedSubscr));
    // eslint-disable-next-line
  }, []);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const windowWidth = useCurrentWidth();
  const breakPoint = MediaBreakPoints.MOBILE_LARGE;

  const isCartEmpty = testCartEmpty(selectedHalls, selectedSubscr);
  const cartIcon = renderCartIcon();

  function renderCartIcon() {
    if (windowWidth > breakPoint) {
      return isCartEmpty ? cartFullIcon : cartEmptyIcon;
    } else {
      return isCartEmpty ? cartFullMobileIcon : cartEmptyMobileIcon;
    }
  }

  function testCartEmpty(
    selectedHalls: { [key: string]: ISelectedHall },
    selectedSubscr: { [key: string]: ISelectedSubscr }
  ) {
    return (
      !!Object.keys(selectedHalls || {}).length ||
      !!Object.keys(selectedSubscr || {}).length
    );
  }

  const handleClick = () => {
    const currentPath = location.pathname;
    if (currentPath === "/cart") {
      dispatch(rerender());
    }
  };

  return (
    <div className={styles.icons__cart}>
      <Link to="/cart" onClick={handleClick} state={{ from: location.pathname }}>
        <HandySvg src={cartIcon} fill="none" width={24} height={24}></HandySvg>
        <span>Корзина</span>
      </Link>
    </div>
  );
};
