const getFullValue = (e: React.ChangeEvent<HTMLInputElement>) => {
  const inputValueFull = e.currentTarget.value;
  return inputValueFull.replace(/\D/g, "");
};

export const dateMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  const fullValue = getFullValue(e);

  let resultInputValue = "";

  if (fullValue.length === 1) {
    Number(fullValue) > 3
      ? (resultInputValue = "")
      : (resultInputValue = `${fullValue[0]}`);
  }
  if (fullValue.length === 2) {
    Number(fullValue) > 31
      ? (resultInputValue = fullValue[0])
      : (resultInputValue = fullValue.slice(0, 2));
  }
  if (fullValue.length === 3) {
    Number(fullValue[2]) > 1
      ? (resultInputValue = fullValue.slice(0, 2) + "-")
      : (resultInputValue = fullValue.slice(0, 2) + "-" + fullValue[2]);
  }
  if (fullValue.length === 4) {
    Number(fullValue.slice(2, 5)) > 12
      ? (resultInputValue = fullValue.slice(0, 2) + "-" + fullValue[2])
      : (resultInputValue = fullValue.slice(0, 2) + "-" + fullValue.slice(2, 5));
  }
  if (fullValue.length === 5) {
    Number(fullValue[4]) > 2 || Number(fullValue[4]) === 0
      ? (resultInputValue = fullValue.slice(0, 2) + "-" + fullValue.slice(2, 4) + "-")
      : (resultInputValue =
          fullValue.slice(0, 2) +
          "-" +
          fullValue.slice(2, 4) +
          "-" +
          fullValue.slice(4, 5));
  }
  if (fullValue.length === 6) {
    Number(fullValue.slice(4, 8)) < 19 || Number(fullValue.slice(4, 8)) > 20
      ? (resultInputValue =
          fullValue.slice(0, 2) +
          "-" +
          fullValue.slice(2, 4) +
          "-" +
          fullValue.slice(4, 5))
      : (resultInputValue =
          fullValue.slice(0, 2) +
          "-" +
          fullValue.slice(2, 4) +
          "-" +
          fullValue.slice(4, 6));
  }
  if (fullValue.length === 7) {
    Number(fullValue.slice(4, 9)) < 199 || Number(fullValue.slice(4, 8)) > 202
      ? (resultInputValue =
          fullValue.slice(0, 2) +
          "-" +
          fullValue.slice(2, 4) +
          "-" +
          fullValue.slice(4, 6))
      : (resultInputValue =
          fullValue.slice(0, 2) +
          "-" +
          fullValue.slice(2, 4) +
          "-" +
          fullValue.slice(4, 7));
  }
  if (fullValue.length > 7 && fullValue.length < 10) {
    resultInputValue =
      fullValue.slice(0, 2) + "-" + fullValue.slice(2, 4) + "-" + fullValue.slice(4, 8);
  }

  return resultInputValue;
};
