import React from "react";

import styles from "./Preloader.module.scss";
import preloader from "../../assets/img/load.gif";
import { PreloaderPropsType } from "./types";

export const Preloader: React.FC<PreloaderPropsType> = (props) => {
  const { maxHeight = "250px", maxWidth = "250px" } = props;

  return (
    <div className={styles.preloader}>
      <img
        style={{ maxHeight: `${maxHeight}`, maxWidth: `${maxWidth}` }}
        src={preloader}
        alt="loading"
      />
    </div>
  );
};
