import React from "react";
import Styles from "./SubscriptionItem.module.scss"
import {IHistoryItemProps} from "./types";
import sauna from "../../../../assets/icons/Sauna.svg";
import gym from "../../../../assets/icons/Gym.svg";
import pool from "../../../../assets/icons/Pool.svg";
import calendar from "../../../../assets/icons/calendar.svg";
import flag from "../../../../assets/icons/flag.svg";

interface ISubscriptionItemProps extends IHistoryItemProps {
    status: string;
}
const SubscriptionItem: React.FC<ISubscriptionItemProps> = (props) => {

    const { status } = props;
    const dateEnd = new Date(props.data.date_to_iso);
    const date = new Date(props.data.date_from_iso);
    const weekdays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const formattedDate = `${weekdays[date.getUTCDay()]}, ${date.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\./g, '.')}`;
    const formattedDateEnd = `${weekdays[dateEnd.getUTCDay()]}, ${dateEnd.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\./g, '.')}`;

    return(
        <>
            {
                status === "big" ? (
                    <div className={Styles.wrapper}>
                        <div className={`${Styles.wrapper_header}`}>
                            {props.data.subscription?.title}
                        </div>
                        {
                            props.data.subscription.type === 'family' && props.data.family?
                              <div className={Styles.member}>
                                  Владелец: {props.data.family[0].profile.name}
                              </div>
                              :
                              <div className={Styles.member}>
                                  Владелец: {props.data.profile?.name}
                              </div>
                        }
                        <div className={Styles.boxHeader}>
                            Включено:
                        </div>
                        <div>
                            {
                                props.data.subscription?.includes.includes("gym") ?
                                    <div className={Styles.includes}>
                                        <div className={Styles.includes_item}>
                                            <img src={gym}/>
                                            <div>
                                                Фитнес зал
                                            </div>
                                        </div>
                                        <div className={Styles.includes_item}>
                                            <img src={pool}/>
                                            <div style={{marginTop: "4px"}}>
                                                Бассейн
                                            </div>
                                        </div>
                                        <div className={Styles.includes_item}>
                                            <img src={sauna}/>
                                            <div style={{marginTop: "3px"}}>
                                                Сауна
                                            </div>
                                        </div>
                                    </div> : <div className={Styles.includes}>
                                        <div className={Styles.includes_item}>
                                            <img src={pool}/>
                                            <div style={{marginTop: "4px"}}>
                                                Бассейн
                                            </div>
                                        </div>
                                        <div className={Styles.includes_item}>
                                            <img src={sauna}/>
                                            <div style={{marginTop: "3px"}}>
                                                Сауна
                                            </div>
                                        </div>
                                    </div>

                            }
                        </div>
                        {
                          props.data.subscription.type === 'family' &&
                          <div className={Styles.pretender}>
                              <div className={Styles.pretender_title}>
                                  Учатники:
                              </div>
                              <div className={Styles.pretender_list}>
                                  {
                                      props.data.family?.map(item =>
                                        <p className={Styles.pretender_name}>{item.profile.name}</p>
                                      )
                                  }
                              </div>
                          </div>
                        }
                        <div className={Styles.expiration}>
                            Абонент действует до {props.data.expires_at}
                            <div>
                                Дата покупки {props.data.bought_at}
                            </div>
                        </div>
                        <div className={Styles.about}>
                            *Не включены групповые занятия
                        </div>
                    </div>
                ) : (
                        <div className={Styles.wrapper_small} >
                            <div className={`${Styles.wrapper_small_header}`}>
                                {props.data.subscription?.title}
                            </div>
                            <div className={`${Styles.includes_small_containterSmall}`}>
                                <div className={Styles.includes_small}>
                                    <div>
                                        <img src={calendar}/>
                                    </div>
                                    <div>
                                        <div className={Styles.includes_item_text}>
                                            Дата: C {formattedDate}
                                        </div>
                                        <div className={`${Styles.includes_item_text_foottext}`}>
                                            По {formattedDateEnd}
                                        </div>
                                    </div>
                                </div>
                                <div className={Styles.includes_small}>
                                    <div>
                                        <img src={flag}/>
                                    </div>
                                    <div className={Styles.includes_item_text}>
                                       Статус:
                                    </div>
                                    <div className={Styles.status_text}>
                                        {new Date() < new Date(props.data.expiration_time) ? (
                                            <div className={Styles.status}>Активен</div>
                                        ) : (
                                            <div className={Styles.status}>Истек</div>
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                )
            }

        </>
    )
  }


export default SubscriptionItem;
