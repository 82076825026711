import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Styles from "./Profile.module.scss"
import {Header, LoginIcon, Title} from "../../components";
import { ProfilePropsType } from "./types";
import {useAppDispatch} from "../../redux/store";
import {fetchGetCards} from "../../redux/profile/cards/asyncActions";
import {fetchHistory} from "../../redux/profile/history/asyncActions";
import {useCurrentWidth} from "../../hooks";
import {MediaBreakPoints} from "../../resources/constants";
import profileAbon from "../../assets/icons/abonProfile.svg"
import profileHall from "../../assets/icons/profileHall.svg"
import settings from "../../assets/icons/settingsProfile.svg"
import profileIcon from "../../assets/icons/profileIconFitness.svg"
import card from "../../assets/icons/card.svg"
import mobileMenu from "../../assets/img/mobileMenu.svg"
import {fetchProfileInfo} from "../../redux/profile/settings/getProfileInfo/asyncActions";
import {useSelector} from "react-redux";
import {rerenderSelector} from "../../redux/rerender/selectors";
import {fetchHalls} from "../../redux/profile/hallsHistory/asyncActions";
import {fetchPurchaseHistory} from "../../redux/profile/purchaseHistory/asyncActions";
import {fetchProfileInfoMenu} from "../../redux/profile/profileInfo/asyncActions";
import {getProfileInfoMenu} from "../../redux/profile/profileInfo/selectors";

export const ProfileLayout: React.FC<ProfilePropsType> = ({ title }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const windowWidth = useCurrentWidth();
    const breakPoint = MediaBreakPoints.MOBILE_LARGE;
    const [activePage, setActivePage] = useState("");
    const { rerender } = useSelector(rerenderSelector);
    const [mobileTitle, setMobileTitle] = useState("")
    const handleNavigate = (route: string) => {
        setActivePage(route);
        navigate(route);
    };
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    const {profile} = useSelector(getProfileInfoMenu)

    useEffect(() => {
        if(location.pathname === "/profile"){
            setMobileTitle("Профиль")
            dispatch(fetchProfileInfoMenu())
            if(windowWidth > breakPoint ){
                navigate("subscriptions")
                handleNavigate("subscriptions")
                setActivePage("subscriptions")
                dispatch(fetchHistory())
            }
        }
        else if(location.pathname === "/profile/subscriptions"){
            setMobileTitle("Мои Абонементы")
        }
        else if(location.pathname === "/profile/settings"){
            setMobileTitle("Настройки")
        }
        else if(location.pathname === "/profile/halls"){
            setMobileTitle("Арендованные залы")
        }
        else if(location.pathname === "/profile/cards"){
            setMobileTitle("Мои карты")
        }
        else if(location.pathname === "/profile/history" ){
            setMobileTitle("История покупок ")
        }
        else if(location.pathname === "/profile/myprofiles" ){
            setMobileTitle("Мои профили")
        }
    }, [location.pathname]);


    useEffect(() => {
        if(windowWidth > breakPoint && location.pathname === "/profile"){
            navigate("subscriptions")
            handleNavigate("subscriptions")
            setActivePage("subscriptions")
            dispatch(fetchHistory())
        }
        else if (location.pathname === "/profile/subscriptions"){
            setActivePage("subscriptions")
        }
        else if (location.pathname === "/profile/halls"){
            setActivePage("halls")
        }
        else if (location.pathname === "/profile/history"){
            setActivePage("history")
        }
        else if (location.pathname === "/profile/cards"){
            setActivePage("cards")
        }
        else if (location.pathname === "/profile/settings"){
            setActivePage("settings")
        }
        else if (location.pathname === "/profile/myprofiles"){
            setActivePage("myprofiles")
        }
        else {
            dispatch(fetchProfileInfoMenu())
        }
    },[])

    React.useEffect( () => {
        if(windowWidth > breakPoint && location.pathname === "/profile"
            || location.pathname === "cards"
            || location.pathname === "settings"){
            navigate("subscriptions")
            handleNavigate("subscriptions")
            setActivePage("subscriptions")
            dispatch(fetchHistory())
        }
    }, [rerender]);
    const handleClickCards = () => {
        navigate("cards");
        dispatch(fetchGetCards())
    };

    const handleClickSubscription = () => {
        navigate("subscriptions");
        dispatch(fetchHistory())
    };

    const handleClickHalls = () => {
        navigate("halls");
        dispatch(fetchHalls())
    };
    const handleClickHistory = () => {
        navigate("history");
        dispatch(fetchPurchaseHistory())
    };

    const handleClickSettings = () => {
        navigate("settings");
        dispatch(fetchProfileInfo())
    };

    const handleClickMyProfiles = () => {
        navigate("myprofiles")
    }

    return (
        <>
            <main>
                <Header title={windowWidth > breakPoint ? title : mobileTitle} />
                <section className="container">
                    <Title>{title}</Title>
                    {windowWidth > breakPoint ? (
                        <div className={Styles.wrapper}>
                            <div>
                                    <nav>
                                        <ul className={`${Styles.wrapper_menu}`}>
                                            <li
                                                className={activePage === "subscriptions" ? Styles.history : `${Styles.history_Opacity}`}
                                                onClick={() => handleNavigate("subscriptions")}
                                            >
                                                Мои абонементы
                                            </li>
                                            <li
                                                className={activePage === "halls" ? Styles.cards : `${Styles.cards_Opacity}`}
                                                onClick={() => handleNavigate("halls")}
                                            >
                                                Арендованные залы
                                            </li>
                                            <li
                                                className={activePage === "history" ? Styles.cards : `${Styles.cards_Opacity}`}
                                                onClick={() => handleNavigate("history")}
                                            >
                                                История покупок
                                            </li>
                                            <li
                                                className={activePage === "settings" ? Styles.settings : `${Styles.settings_Opacity}`}
                                                onClick={() => handleNavigate("settings")}
                                            >
                                                Настройки
                                            </li>
                                            <li
                                                className={activePage === "cards" ? Styles.cards : `${Styles.cards_Opacity}`}
                                                onClick={() => handleNavigate("cards")}
                                            >
                                                Мои карты
                                            </li>
                                            <li
                                                className={activePage === "myprofiles" ? Styles.cards : `${Styles.cards_Opacity}`}
                                                onClick={() => handleNavigate("myprofiles")}
                                            >
                                                Мои профили
                                            </li>
                                        </ul>
                                    </nav>
                            </div>
                                <Outlet/>

                        </div>
                    ) : (
                        location.pathname === "/profile" && windowWidth < breakPoint ? (
                            <div className={Styles.mobileMenu}>
                                <div className={Styles.name}>
                                  <img src={mobileMenu}/>
                                </div>
                                <div className={Styles.name}>
                                    {profile[0]?.name}
                                </div>
                                <div className={Styles.mobileItem} onClick={handleClickSubscription}>
                                    <div>
                                        <img className={`${Styles.mobileItem_mobileImg}`} src={profileAbon}/>
                                    </div>
                                    Мои абонементы
                                </div>
                                <div className={Styles.mobileItem} onClick={handleClickHalls}>
                                    <div>
                                        <img src={profileHall}/>
                                    </div>
                                    Арендованные залы
                                </div>
                                <div className={Styles.mobileItem} onClick={handleClickHistory}>
                                    <div>
                                        <img src={profileHall}/>
                                    </div>
                                    История покупок
                                </div>
                                <div className={Styles.mobileItem} onClick={handleClickSettings}>
                                    <div>
                                        <img src={settings}/>
                                    </div>
                                    Настройки
                                </div>
                                <div className={Styles.mobileItem} onClick={handleClickCards}>
                                    <div>
                                        <img src={card}/>
                                    </div>
                                    Мои карты
                                </div>
                                <div className={Styles.mobileItem} onClick={handleClickMyProfiles}>
                                    <div>
                                        <img src={profileIcon}/>
                                    </div>
                                    Мои профили
                                </div>
                            </div>
                        ) :  <div className={Styles.wrapper}>
                            <Outlet/>
                        </div>
                    )
                    }
                </section>
            </main>
        </>
    );
};

