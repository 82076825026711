export enum PageTitles {
  PROFILE = "Профиль",
  COURT_RENT = "Аренда кортов",
  RACKET_RENT = "Аренда ракеток",
  CART = "Корзина",
  PAYMENT = "Оплата",
  CONTACTS = "Контакты",
  SCHOOL = "Школа тенниса",
  ABOUT = "О клубе",
  RULES = "Правила Клуба",
  POLITICS = "Политика конфиденциальности",
  SUBSCRIPTION = "Абонементы",
  USER_INFO = "Данные пользователя(-ей)",
  PROFILES_LIST = "Выбор профиля",
  HALLS = "Аренда залов",
  HALLS_PHOTO = "Фото спортивных залов",
  OFFER = "Оферта",
}

export const ValidMessages = {
  inputs: {
    name: {
      REQUIRED: 'поле "ФИО" обязательно для заполнения',
      NOT_FULL: 'поле "ФИО" не может быть меньше 4 символов',
    },
    email: {
      REQUIRED: 'поле "Email" обязательно для заполнения',
      INCORRECT: "неправильный формат электронной почты",
    },
    pinCode: {
      REQUIRED: "пожалуйста, введите код, который был отправлен на ваш телефон",
      NOT_FULL: "код состоит из 4 цифр",
      INCORRECT: "Вы ввели неправильный код",
    },
    emailChange: {
      REQUIRED: "пожалуйста, введите код, который был отправлен на вашу почту",
      NOT_FULL: "код состоит из 4 цифр",
      INCORRECT: "Вы ввели неправильный код",
    },
    phone: {
      REQUIRED: 'поле "телефон" обязательно для заполнения',
      NOT_FULL: 'количество символов в поле "телефон" должно быть не менее 11',
    },
    password: {
      REQUIRED: 'поле "пароль" обязательно для заполнения',
      NOT_FULL: 'количество символов в поле "пароль" должно быть не менее 6',
    },
    passwordRepeat: {
      REQUIRED: 'поле "повторите пароль" обязательно для заполнения',
      NOT_MATCH: "пароли должны совпадать",
    },
    acceptTerms: {
      REQUIRED:
        "необходимо подтвердить, что Вы ознакомлены и согласны с условиями оплаты",
    },
    acceptRules: {
      REQUIRED: "необходимо подтвердить, что Вы ознакомлены и согласны с правилами клуба",
    },
    acceptOffer: {
      REQUIRED:
        "необходимо подтвердить, что Вы ознакомлены и согласны с условиями оферты",
    },
    message: {
      NOT_FULL: 'количество символов в поле "ваше сообщение" должно быть не менее 10',
      REQUIRED: "необходимо ввести сообщение",
    },
    child_name: {
      REQUIRED: 'поле "ФИО ребенка" обязяательно для заполнения',
    },
    parent_name: {
      REQUIRED: 'поле "ФИО родителя" обязяательно для заполнения',
    },
    level: {
      REQUIRED: "необходимо выбрать уровень",
    },
    date: {
      REQUIRED: 'поле "дата рождения" обязательно для заполнения',
      NOT_FULL:
        'количество символов в поле "дата рождения" должно быть не менее 10, в формате "ДД-ММ-ГГГГ"',
    },
  },
};

export const ModalTexts = {
  common: {
    error: {
      TITLE: "Ошибка!",
      SUB_TITLE: "Что-то пошло не так. Попробуйте позже или свяжитесь с нами",
    },
  },
  changePass: {
    inputPhone: {
      recovery: {
        TITLE: "Восстановление пароля",
        SUB_TITLE: "Введите номер телефона",
      },
      change: {
        TITLE: "Смена пароля",
        SUB_TITLE: "Введите номер телефона",
      },
    },
    confirmMessage: {
      TITLE: "Подтверждение",
      SUB_TITLE: null,
    },
    inputSmsCode: {
      TITLE: "Восстановление пароля",
      SUB_TITLE: null,
    },
    inputNewPassword: {
      TITLE: "Введите новый пароль",
      SUB_TITLE: null,
    },
    passwordApply: {
      TITLE: "Пароль обновлен",
      SUB_TITLE: "Ваш пароль успешно обновлен",
    },
    errorPhone: {
      TITLE: "Такой телефон не найден",
      SUB_TITLE: "Убедитесь, что правильно ввели номер или свяжитесь с нами",
    },
    errorCode: {
      TITLE: "Неправильный код",
      SUB_TITLE: "Убедитесь, что правильно ввели код из смс",
    },
  },
  courtRent: {
    errorNoSelectedSeats: {
      TITLE: "Выберите корт и время",
      SUB_TITLE: "Для бронирования необходимо выбрать в таблице корт и время",
    },
    errorUnAvailable: {
      TITLE: "Выбранный корт уже заняли",
      SUB_TITLE:
        "Упс! Видимо кто-то уже положил к себе в корзину выбранный Вами корт. Выберите другой корт или время",
    },
  },
  racketRent: {
    errorNoSelectedRackets: {
      TITLE: "Выберите время",
      SUB_TITLE: "Для бронирования ракеток необходимо выбрать в таблице время",
    },
    errorUnAvailable: {
      TITLE: "Не достаточно ракеток",
      SUB_TITLE: "На выбранное Вами время не достаточно свободных ракеток",
    },
  },
  auth: {
    authStart: {
      TITLE: "Авторизация",
      SUB_TITLE: null,
    },
    authSuccess: {
      TITLE: "Вход выполнен",
      SUB_TITLE: "Вы успешно авторизировались на сайте",
    },
    authError: {
      TITLE: "Такой пользователь не найден",
      SUB_TITLE:
        "Убедитесь, что правильно ввели данные для авторизации или свяжитесь с нами",
    },
  },
  register: {
    regStart: {
      TITLE: "Регистрация",
      SUB_TITLE: null,
    },
    regSuccess: {
      TITLE: "Вы зарегистрировались",
      SUB_TITLE:
        "Вы успешно зарегистрировались и автоматически авторизировались на сайте",
    },
  },
  deleteCard: {
    confirmation: {
      TITLE: "Удаление карты",
      SUB_TITLE: "Вы точно хотите удалить карту?",
    },
  },
  deleteProfile: {
    confirmation: {
      TITLE: "Удаление профиля",
      SUB_TITLE: "Вы точно хотите удалить профиль?",
    },
  },
  payment: {
    afk: {
      TITLE: "Внимание!",
      SUB_TITLE: "Вы слишком долго находитесь в режиме оплаты",
    },
  },
  school: {
    tennis: {
      TITLE: "Школа тенниса",
      SUB_TITLE: "Оставьте заявку",
    },
    tennisSuccess: {
      TITLE: "Школа тенниса",
      SUB_TITLE: null,
    },
    personalDescr: {
      TITLE: "Персональные тренировки",
      SUB_TITLE: "Индивидуальные занятия теннисом с тренером",
    },
    personal: {
      TITLE: "Персональные тренировки",
      SUB_TITLE: "Оставьте заявку",
    },
    personalSuccess: {
      TITLE: "Персональные тренировки",
      SUB_TITLE: null,
    },
  },
  feedbackSuccess: {
    TITLE: "Успех",
    SUB_TITLE:
      'Сообщение отправлено! Наши менеджеры скоро свяжутся с Вами',
  },
  subscriptions: {
    notFilled: {
      TITLE: "Заполните все поля",
      SUB_TITLE: "Упс! Вы заполнили не все поля или заполнили поля не правильно",
    },
    notUniquePhones: {
      TITLE: "Ошибка!",
      SUB_TITLE: "Все номера телефонов должны быть уникальными",
    },
  },
  cart: {
    noAdultsSubscr: {
      TITLE: "Внимание!",
      SUB_TITLE:
        "Детский абонемент отдельно не продается! Детские абонементы будут удалены из корзины",
    },
    paymentError: {
      TITLE: "Ошибка!",
    },
  },
};

export const dayNamesForRent = [
  "Вс",
  "Пн",
  "Вт",
  "Ср",
  "Чт",
  "Пт",
  "Сб",
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
];

export const monthNamesForCalendar = [
  "Янв",
  "Фев",
  "Март",
  "Апр",
  "Май",
  "Июнь",
  "Июль",
  "Авг",
  "Сент",
  "Окт",
  "Нояб",
  "Дек",
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const siteUrl = {
  yandexMap: {
    MAP_YANDEX_MOBILE:
      "https://static-maps.yandex.ru/1.x/?l=map&lang=ru_RU&ll=37.41093430370709%2C55.570802104604034&origin=jsapi-constructor&pt=37.410309%2C55.570065%2Cpm2bll&size=328%2C240&z=14",
    MAP_YANDEX_BP:
      "https://static-maps.yandex.ru/1.x/?l=map&lang=ru_RU&ll=37.41093430370709%2C55.570802104604034&origin=jsapi-constructor&pt=37.410309%2C55.570065%2Cpm2bll&size=640%2C380&z=14",
    MAP_YANDEX:
      "https://static-maps.yandex.ru/1.x/?l=map&lang=ru_RU&ll=37.41093430370709%2C55.570802104604034&origin=jsapi-constructor&pt=37.410309%2C55.570065%2Cpm2bll&size=650%2C450&z=14",
  },
  google_direction: {
    MAP_DESTINATION:
      "https://www.google.com/maps/dir//Tennis.ru,+%D1%83%D0%BB.+%D0%9B%D0%B5%D1%82%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%9C%D0%B0%D0%BB%D0%B0%D1%8F,+8,+%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0,+%D0%9C%D0%BE%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB.,+142791/@55.5696399,37.3880903,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x46b5534fc481e599:0x2e4bc23e56d8e0c4!2m2!1d37.410379!2d55.570109",
  },
};

export const about_direction = {
  titles: {
    ADRESS: "г. Москва, ул. Летовская Малая, 8",
    METRO: "Ольховая",
    BUS: "878, до остановки «Ларево»",
  },
};
