import React, {useEffect} from "react";
import Styles from "./HallsList.module.scss"
import {useAppDispatch} from "../../../../redux/store";
import {useSelector} from "react-redux";
import preloader from "../../../../assets/img/load.gif";
import HallsItem from "../HallsItem/HallsItem";
import {getUserHalls} from "../../../../redux/profile/hallsHistory/selectors";
import {fetchHalls} from "../../../../redux/profile/hallsHistory/asyncActions";
const HallsList: React.FC = (props) => {

    const dispatch = useAppDispatch();
    const {halls, isLoading} = useSelector(getUserHalls)

    useEffect(() => {
        dispatch(fetchHalls())
    },[])

    return(
        <div className={Styles.wrapper}>
            <p className={Styles.subTitile}>Арендованные залы</p>
            {
                isLoading ? (
                    <div className={Styles.preloader}>
                        <img className={Styles.preloader_img} src={preloader} alt="preloader" />
                    </div>
                ) : (
                    <div className={Styles.item}>
                        {halls?.map( item => <HallsItem data={item}/> )}
                    </div>
                )
            }
        </div>
    )

}

export default HallsList