import React from "react";
import Styles from "./HallsItem.module.scss"
import {IHalls} from "./types";
import calendar from "../../../../assets/icons/calendar.svg";
import clock from "../../../../assets/icons/clock.svg";

const HallsItem: React.FC<IHalls> = (props) => {

    const date = new Date(props.data.date);
    const formattedWeekday = `${date.toLocaleString('ru-RU', { weekday: 'short' }).charAt(0).toUpperCase()}${date.toLocaleString('ru-RU', { weekday: 'short' }).slice(1).toLowerCase()}`;
    const formattedDate = date.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\./g, '-');

    return(
        <>
           <div className={Styles.wrapper} >
                   <div className={`${Styles.wrapper_header}`}>
                       {props.data.hall?.title}
                   </div>
               <div className={Styles.includes}>
                   <div className={Styles.includes_item}>
                       <img src={calendar}/>
                       <div className={Styles.includes_item_text}>
                           Дата: {formattedWeekday}, {formattedDate}
                       </div>
                       <div className={Styles.includes_item}>
                           <img src={clock}/>
                           <div className={Styles.includes_item_text}>
                               Время: {props.data.time}-{props.data.time_to}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </>
    )
  }


export default HallsItem;
