import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchGetArenas, fetchGetRackets } from "./asyncActions";

import { ICourtRentState, IArena, ISelctedSeat, ISelctedRacket, IRacket } from "./types";

const initialState: ICourtRentState = {
  date: "",
  arenas: [],
  rackets: [],
  selectedSeats: {},
  selectedRackets: {},
  isLoading: false,
};

const rentSlice = createSlice({
  name: "rent",
  initialState,
  reducers: {
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setSelctedSeats(state, action: PayloadAction<{ [key: string]: ISelctedSeat }>) {
      state.selectedSeats = action.payload;
    },
    setSelctedRackets(state, action: PayloadAction<{ [key: string]: ISelctedRacket }>) {
      state.selectedRackets = action.payload;
    },
    resetSelectedSeats(state) {
      state.selectedSeats = {};
    },
    resetSelectedRackets(state) {
      state.selectedRackets = {};
    },
  },
  extraReducers: (builder) => {
    // get courts
    builder.addCase(fetchGetArenas.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchGetArenas.fulfilled,
      (state, action: PayloadAction<IArena[]>) => {
        state.arenas = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchGetArenas.rejected, (state) => {
      state.isLoading = false;
    });
    // get racket
    builder.addCase(fetchGetRackets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchGetRackets.fulfilled,
      (state, action: PayloadAction<IRacket[]>) => {
        state.rackets = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchGetRackets.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setDate,
  setSelctedSeats,
  setSelctedRackets,
  resetSelectedSeats,
  resetSelectedRackets,
} = rentSlice.actions;

export default rentSlice.reducer;
