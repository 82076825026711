import React, {useEffect} from "react";
import Styles from "./HistoryList.module.scss"
import {useAppDispatch} from "../../../../redux/store";
import {useSelector} from "react-redux";
import preloader from "../../../../assets/img/load.gif";
import HallsItem from "../HallsItem/HallsItem";
import {fetchPurchaseHistory} from "../../../../redux/profile/purchaseHistory/asyncActions";
import SubscriptionItem from "../SubscriptionsItem/SubscriptionItem";
import {getPurchaseHistory} from "../../../../redux/profile/purchaseHistory/selectors";
const HistoryList: React.FC = (props) => {

    const dispatch = useAppDispatch();
    const {purchaseItems, isLoading} = useSelector(getPurchaseHistory)

    useEffect(() => {
        dispatch(fetchPurchaseHistory())
    },[])

    return(
        <div className={Styles.wrapper}>
            <p className={Styles.subTitile}>История покупок</p>
            {
                isLoading ? (
                    <div className={Styles.preloader}>
                        <img className={Styles.preloader_img} src={preloader} alt="preloader" />
                    </div>
                ) : (
                    <div className={Styles.item}>
                        {purchaseItems.map((item) =>
                            // @ts-ignore
                            item.type === "subscription" ? (
                                <SubscriptionItem status="small" data={item}/>
                            ) : (
                                <HallsItem data={item} />
                            )
                        )}
                    </div>
                )
            }
        </div>
    )

}

export default HistoryList