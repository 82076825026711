import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../resources/api";

import {ICard} from "./types";
import {getTokenFromStorage} from "../../../utils";

export const fetchGetCards = createAsyncThunk<ICard[]>(
  "getCards/fetchGetCards",
  async () => {
    try {
        const url = `${api.BASE_URL}${api.profile.cards.GET_CARD}`;
        const token = getTokenFromStorage();
      const response = await axios.get(url,{"headers": {
              "Authorization": `Bearer ${token}`
          }});
      return response.data;

    } catch (error: any) {
      console.log("error fetchGetCards >>>", error);
    }
  }
);

export const fetchDelCards = createAsyncThunk<ICard[], number>(
    "delCards/fetchDelCards",
    async (cardId) => {
        try {
            const url = `${api.BASE_URL}${api.profile.cards.DELETE_CARD}${cardId}`;
            const token = getTokenFromStorage();
            const response = await axios.get(url,{"headers": {
                    "Authorization": `Bearer ${token}`
                }});
            return response.data;
        } catch (error: any) {
            console.log("error fetchDelCards >>>", error);
        }
    }
);

