import React from "react";
import { Outlet } from "react-router-dom";

import { Footer } from "../components";

const PageLayout: React.FC = () => {
  return (
    <div className="wrapper">
      <Outlet />
      <Footer />
    </div>
  );
};

export default PageLayout;
