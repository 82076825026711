import React from "react";
import Style from "./Map.Module.scss"
import {siteUrl} from "../../../../resources/locale";
import {useCurrentWidth} from "../../../../hooks";
import {MediaBreakPoints} from "../../../../resources/constants";
import { YMaps, Map,Placemark,FullscreenControl } from '@pbe/react-yandex-maps';
export const MapDirection = () => {
    const handleMapClick = (event:any) => {
        event.preventDefault();
        window.open(siteUrl.google_direction.MAP_DESTINATION);
    };

    const defaultState = () => {
        let coordinates = {}
        if (windowWidth <= breakPointMobile) {
            coordinates = { center: [55.57655, 37.42179], zoom: 13 }
        } else if (windowWidth <= breakPointTablet) {
            coordinates = { center: [55.57655, 37.42179], zoom: 13.5 }
        } else {
            coordinates = { center: [55.57655, 37.42179], zoom: 14 }
        }

        console.log(coordinates);
        return coordinates;
    }

    const windowWidth = useCurrentWidth();
    const breakPointTablet = MediaBreakPoints.TABLET;
    const breakPointMobile = MediaBreakPoints.MOBILE_LARGE

    return (
      <YMaps>
        <Map
            defaultState={defaultState()}
            width="100%" height="100%"
        >
            <Placemark geometry={[55.570247, 37.410286]} />
            <FullscreenControl />
        </Map>
      </YMaps>
    );
};
