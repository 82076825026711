import React from "react";
import { useSelector } from "react-redux";

import { hallsSelector } from "../../../../redux/halls/selectors";

import styles from "./HallTitle.module.scss";

export const HallTitle: React.FC = () => {
  const { lastSelectedHall } = useSelector(hallsSelector);
  return <div className={styles.hallTitle}>{lastSelectedHall?.title}</div>;
};
