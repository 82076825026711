import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../../resources/api";
import {getTokenFromStorage} from "../../../../utils";

export const fetchEditName = createAsyncThunk<string, { name: string }>(
  "editName/fetcheditName",
  async (data) => {
    try {
          const url = `${api.BASE_URL}${api.profile.settings.changeName.CHANGE_NAME}`;
        const token = getTokenFromStorage();
      const response = await axios.post(url, data, {"headers": {
              "Authorization": `Bearer ${token}`
          }});
      return response.status === 200 && response.statusText === "OK"
    } catch (error: any) {
      return error.response.status
    }
  }
);

export const fetchEditEmail = createAsyncThunk<string, { email: string }>(
    "editEmail/fetchedEmail",
    async (data) => {
        try {
            const url = `${api.BASE_URL}${api.profile.settings.changeEmail.GET_CODE_EMAIL}`;
            const token = getTokenFromStorage();
            const response = await axios.post(url, data, {"headers": {
                    "Authorization": `Bearer ${token}`
                }});
            return response.status === 200 && response.statusText === "OK"
        } catch (error: any) {
            return error.response.status
        }
    }
);

export const fetchEditPassword = createAsyncThunk<string, { password: string, passwordRepeat: string}>(
    "editPassword/fetchePass",
    async (data) => {
        try {
              const url = `${api.BASE_URL}${api.profile.settings.changePass.CHANGE_PASS}`;
              const token = getTokenFromStorage();
            const response = await axios.post(url, data, {"headers": {
                    "Authorization": `Bearer ${token}`
                }});
            return response.status === 200 && response.statusText === "OK"
        } catch (error: any) {
            return error.response.status
        }
    }
);




export const fetchEditPhone = createAsyncThunk<string, { phone: string}>(
    "editPhone/fetchedPhone",
    async (data) => {
        try {
            const url = 'https://fitness.tennis.ru/api/settings/phone';
            const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNmM3ZjM0YmNhOTc5MDE2NDBlODgyMTQ1NGM2MzJkNDE4YWY5YTcyN2I2Y2IyNzQ2Njk3NDg4ZDFkZmQxNTNjMzkyMDllNGNiYzA1NmNjNzciLCJpYXQiOjE2NzgyNzI2MTQsIm5iZiI6MTY3ODI3MjYxNCwiZXhwIjoxODM2MTI1NDE0LCJzdWIiOiIxNzg0Iiwic2NvcGVzIjpbXX0.eMLTLgId3CvAo-HmvQTJNboEMLOFxTxE1Q1mCuW7iFQVv3jUHGxNbPhfAu1jki_BuTKhydxOk60GUCN-F3ZXdDf9Al0uz5HNn5t9CBPsWJ2a-WSS6qzFQko-D85RdQPp9Nm7CdONUDX0_N5_vDk8S_miJ543pYQCNqS5l4vQ3sNIz4kzGPK4qkPP-2t8ylI1rQw6mt30WJW1pQWlpNTNrpBBHkRGdCWC1Uae6zdX_FU_2FJWo-sOLu6PUaZxOxDCfUafEAbp-w2V9Jc3xe57qEnu3VZYTuYtKYZ6zFzbytg-k7MIGWeWmjj9fU2W1Skaj6Dqc5XgTNDRHwI5cmkfyrt4hALSADnzkE7Wr__v5ans9XqVHxv9dRKf3G5FX0YjKcVvdBrliSCp2FRyxgK3hRS4Qnw5_vyM_CpGA19orH6Cou-pLT-s3xGBJh6NGkLOSsF8hK3ank1FFWoJwmRc3xM7FOuEhXJM3b5Oo8z2TpDJbISgWFypeFKkwJ67xLELJq2yH6YZ3JNPDrzJ-NqVBbUAAyOsYdyNdea_6lFja2v3zNzlw8fFMTjXQieBzuQIPWq4GuC6GCEhNyFa5ivfj3h0En6orKVEaur6zJuq1ZunP3buaH9UaWi6SeZYns6WhQcBdond1YPClP3MYl_X2ouypCVCRoSHzb8wig3trbY"

            /*const url = `${api.BASE_URL}${api.profile.settings.changePhone.CHANGE_PHONE}`;
            const token = getTokenFromStorage();*/
            const response = await axios.post(url, data, {"headers": {
                    "Authorization": `Bearer ${token}`
                }});
            return response.status === 200 && response.statusText === "OK"
        } catch (error: any) {
            return error.response.status
        }
    }
);