import React from "react";
import { useSelector } from "react-redux";
import {useLocation, useNavigate } from "react-router-dom";

import { Button, Header, LoginModal, Modal, Title } from "../../../components";
import { FormItem } from "../components";

import { subscriptionsSelector } from "../../../redux/subscriptions/selectors";
import { useAppDispatch } from "../../../redux/store";
import { authAndRegSelector } from "../../../redux/profile/authAndReg/selectors";
import { setShowWindow } from "../../../redux/profile/authAndReg/slice";
import { fetchAddProfile } from "../../../redux/profile/profileInfo/asyncActions";
import {
  resetSelectedAdultSubscr,
  resetSelectedKidSubscr,
  resetSubscrFormsData,
  setSelectedAdultSubscr,
  setSelectedFamilySubscription,
  setSelectedKidSubscr,
  setSelectedSubscr,
  setSubscrFormsData,
} from "../../../redux/subscriptions/slice";

import styles from "./SubscriptionsForms.module.scss";

import { ModalTexts } from "../../../resources/locale";
import { WindowNames } from "../../../resources/constants";
import {
  getSelectedAdultSubscrFromStorage,
  getSelectedFamilySubscrFromStorage,
  getSelectedKidSubscrFromStorage,
  getSubscrFormsDataFromStorage,
  removeSelectedAdultSubscrFromStorage,
  removeSelectedKidSubscrFromStorage,
  removeSubscrFormsDataFromStorage,
  setSelectedSubscrToStorage,
} from "../../../utils";

import { SubscrFormsPropsType } from "./types";
import {
  ISelectedAdultSubscr,
  ISelectedFamilySubscr,
  ISelectedKidSubscr,
  ISelectedSubscr,
  ISubscrFormData,
} from "../../../redux/subscriptions/types";

export const SubscriptionsForms: React.FC<SubscrFormsPropsType> = ({ title }) => {
  const [isFilledFormsTest, setIsFilledFormsTest] = React.useState(false);
  const [isOpenModalFilledForms, setIsOpenModalFilledForms] = React.useState(false);
  const [isOpenModalUniquePhones, setIsOpenModalUniquePhones] = React.useState(false);
  const [isOpenModalNoAuth, setIsOpenModalNoAuth] = React.useState(false);

  const { selectedAdultSubscr, selectedKidSubscr, selectedFamilySubscr, subscrFormsData } =
    useSelector(subscriptionsSelector);
  const { auth } = useSelector(authAndRegSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    const selectedAdultSubscr = getSelectedAdultSubscrFromStorage();
    const selectedKidSubscr = getSelectedKidSubscrFromStorage();
    const selectedFamilySubscr = getSelectedFamilySubscrFromStorage();
    const subscrFormsData = getSubscrFormsDataFromStorage();

    if (selectedAdultSubscr) {
      selectedAdultSubscr.forEach((item: ISelectedAdultSubscr) =>
        dispatch(setSelectedAdultSubscr(item))
      );
    }
    if (selectedKidSubscr) {
      selectedKidSubscr.forEach((item: ISelectedKidSubscr) =>
        dispatch(setSelectedKidSubscr(item))
      );
    }
    if (selectedFamilySubscr) {
      selectedFamilySubscr.forEach((item: ISelectedFamilySubscr) =>
        dispatch(setSelectedFamilySubscription(item))
      );
    }
    console.log('useEffect',subscrFormsData)
    if (subscrFormsData) {
      subscrFormsData.forEach((item: ISubscrFormData) =>
        dispatch(setSubscrFormsData(item))
      );
    } // eslint-disable-next-line
  }, []);

  let formsCounter = 0;
  const subsriptionForms = renderSubsriptionForms(selectedAdultSubscr, selectedKidSubscr, selectedFamilySubscr);

  function renderSubsriptionForms(
    adultSubscrArray: ISelectedAdultSubscr[],
    kidSubscrArray: ISelectedKidSubscr[],
    familySubscrArray: ISelectedFamilySubscr[]
  ) {

    if (familySubscrArray.length) {
      return [...familySubscrArray].map(
        (item: ISelectedFamilySubscr) => {
          let sameSubscrWithNum = [];


          for (let i = 1; i < item.quantity[0] + item.quantity[1] + 1; i++) {
            formsCounter += 1;
            sameSubscrWithNum.push(
              <FormItem
                key={`${item.subscription.id}-${i}`}
                subscription={item.subscription}
                quantity={item.quantity}
                profilesCount={item.profilesCount}
                num={i}
                isFilledFormsTest={isFilledFormsTest}
                formsData={subscrFormsData}
              />
            );
          }

          return sameSubscrWithNum;
        }
      )
    }

    return [...adultSubscrArray, ...kidSubscrArray].map(
      (item: ISelectedAdultSubscr | ISelectedAdultSubscr) => {
        let sameSubscrWithNum = [];

        for (let i = 1; i < item.quantity + 1; i++) {
          formsCounter += 1;
          sameSubscrWithNum.push(
            <FormItem
              key={`${item.subscription.id}-${i}`}
              subscription={item.subscription}
              quantity={item.quantity}
              num={i}
              isFilledFormsTest={isFilledFormsTest}
              formsData={subscrFormsData}
            />
          );
        }

        return sameSubscrWithNum;
      }
    );
  }

  const handleCloseModalFilledForms = () => {
    setIsOpenModalFilledForms(false);
  };

  const handleCloseModalUniquePhones = () => {
    setIsOpenModalUniquePhones(false);
  };

  const isFormsFilledTest = () => {
    let isFilled = true;
    setIsFilledFormsTest(true);

    if (formsCounter !== subscrFormsData.length) return (isFilled = false);

    subscrFormsData.forEach((item) => {
      const name = item.profileValues.name;
      const text = item.profileValues.secondaryText;
      if (name.length < 4) isFilled = false;
      if (text.substring(0, 1) === "+" && text.length < 18) isFilled = false;
      if (text.substring(0, 1) !== "+" && text.length < 10) isFilled = false;
    });

    return isFilled;
  };

  const isUniquePhoneTest = () => {
    let isUnique = true;
    let phones: string[] = [];

    subscrFormsData.forEach((item) => {
      const text = item.profileValues.secondaryText;
      if (text.substring(0, 1) === "+") phones.push(text);
    });

    const uniquePhones = [...Array.from(new Set(phones))];
    if (phones.length !== uniquePhones.length) isUnique = false;

    return isUnique;
  };

  const handleGoCart = () => {
    if (!isFormsFilledTest()) return setIsOpenModalFilledForms(true);
    if (!isUniquePhoneTest()) return setIsOpenModalUniquePhones(true);
    if (!auth) {
      setIsOpenModalNoAuth(true);
      dispatch(setShowWindow(WindowNames.AUTH));
      return;
    }

    const selectedSubscr = Object.values(subscrFormsData).reduce(
      (acc: { [key: string]: ISelectedSubscr }, item) => {
        const thisSubscrKeyName: string = `${item.subscrData.id}`;

        if (thisSubscrKeyName in acc) {
          acc[thisSubscrKeyName].profiles.push(item.profileValues);
          return acc;
        } else {
          if (item.subscrData.type === "family") {
            console.log(item)
            return {
              ...acc,
              [thisSubscrKeyName]: {
                profiles: [item.profileValues],
                quantity: item.quantity,
                subscription: item.subscrData,
                profilesCount: item.profilesCount
              },
            };
          } else {
            return {
              ...acc,
              [thisSubscrKeyName]: {
                profiles: [item.profileValues],
                quantity: item.quantity,
                subscription: item.subscrData,
              },
            };
          }
        }
      },
      {}
    );

    console.log(selectedSubscr)

    dispatch(setSelectedSubscr(selectedSubscr));
    setSelectedSubscrToStorage(selectedSubscr);

    Object.values(subscrFormsData).forEach((item) => {
      const type = item.subscrData.type;
      const dataKeyName = type === "adult" ? "phone" : "birthday";
      const data = {
        name: item.profileValues.name,
        [dataKeyName]: item.profileValues.secondaryText,
      };

      const fetchData = {
        type,
        data,
      };

      console.log(fetchData)
      dispatch(fetchAddProfile(fetchData));
    });

    navigate("/cart");
  };

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>
          <p className={styles.subTitile}>Заполните данные для каждого абонемента</p>
          <div className={styles.items}>{subsriptionForms}</div>
          <Button
            newStyles={styles.button}
            onClickFunc={handleGoCart}
            disabled={!(selectedAdultSubscr.length > 0 || selectedKidSubscr.length > 0 || selectedFamilySubscr.length > 0)}
          >
            Оплатить
          </Button>
        </section>
      </main>

      {/* Modal - not all forms are filled out */}
      {isOpenModalFilledForms ? (
        <Modal
          title={ModalTexts.subscriptions.notFilled.TITLE}
          subTitle={ModalTexts.subscriptions.notFilled.SUB_TITLE}
          isOpen={isOpenModalFilledForms}
          setIsOpen={setIsOpenModalFilledForms}
        >
          <Button newStyles={styles.button} onClickFunc={handleCloseModalFilledForms}>
            Назад
          </Button>
        </Modal>
      ) : null}

      {/* Modal - not all phone numbers are unique */}
      {isOpenModalUniquePhones ? (
        <Modal
          title={ModalTexts.subscriptions.notUniquePhones.TITLE}
          subTitle={ModalTexts.subscriptions.notUniquePhones.SUB_TITLE}
          isOpen={isOpenModalUniquePhones}
          setIsOpen={setIsOpenModalUniquePhones}
        >
          <Button newStyles={styles.button} onClickFunc={handleCloseModalUniquePhones}>
            Назад
          </Button>
        </Modal>
      ) : null}

      {/* Modal - have no authorization */}
      {isOpenModalNoAuth ? (
        <LoginModal isOpen={isOpenModalNoAuth} setIsOpen={setIsOpenModalNoAuth} />
      ) : null}
    </>
  );
};
