import React from "react";
import { Formik, Form, ErrorMessage, Field, FormikValues } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { fetchFeedback } from "../../../../redux/feedback/asyncActions";
import { feedbackSelector } from "../../../../redux/feedback/selectors";

import {
  NameInput,
  EmailInput,
  PhoneInput,
  Button,
  TextareaInput,
} from "../../../../components";

import styles from "./FeedbackForm.module.scss";
import phoneIcon from "../../../../assets/icons/phoneInput.svg";
import profileIcon from "../../../../assets/icons/profileInput.svg";
import emailIcon from "../../../../assets/icons/emailTennis.svg";
import inputIcon from "../../../../assets/icons/messageWriteFitness.svg";

import { ValidMessages } from "../../../../resources/locale";
import { IFeedbackData } from "../../../../redux/feedback/types";
import { FeedbackFormPropsType } from "./types";

export const FeedbackForm: React.FC<FeedbackFormPropsType> = ({ onSubmitFunc }) => {
  const { isLoading } = useSelector(feedbackSelector);
  const dispatch = useAppDispatch();

  const initialValues: IFeedbackData = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(ValidMessages.inputs.name.REQUIRED),
    email: Yup.string()
      .email(ValidMessages.inputs.email.INCORRECT)
      .required(ValidMessages.inputs.email.REQUIRED),
    phone: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
    message: Yup.string()
      .min(10, ValidMessages.inputs.message.NOT_FULL)
      .required(ValidMessages.inputs.message.REQUIRED),
  });

  const handleSubmit = async (values: IFeedbackData, { resetForm }: FormikValues) => {
    await dispatch(fetchFeedback(values));
    onSubmitFunc(true);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles.formSection}>
              <label htmlFor="name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput name="name" placeholder="ФИО" />
              <ErrorMessage component="div" className={styles.errorMessage} name="name" />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="email">
                <img src={emailIcon} alt="email icon" />
              </label>
              <EmailInput name="email" placeholder="Email" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="email"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="phone">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput name="phone" placeholder="+7 (___) ___-__-__" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="input">
                <img src={inputIcon} alt="input icon" />
              </label>
              <TextareaInput
                name="message"
                placeholder="Ваше сообщение"
                className={styles.textarea}
              />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="message"
              />
            </div>

            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={
                // !formik.dirty ||
                formik.isSubmitting
              }
            >
              Отправить
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
