import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICartState, ITotalCartItems } from "./types";

const initialState: ICartState = {
  totalCartItems: {},
  isLoading: false,
  fetchReserveStatus: "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItems(state, action: PayloadAction<ITotalCartItems>) {
      state.totalCartItems = action.payload;
    },
    resetCartItems(state) {
      state.totalCartItems = {};
    },
  },
});

export const { setCartItems, resetCartItems } = cartSlice.actions;

export default cartSlice.reducer;
