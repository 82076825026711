import React from "react";

import styles from "./CallMeBack.module.scss";
import callMeBackIcon from "../../assets/icons/callMeBackIcon.svg";

export const CallMeBackIcon: React.FC = () => {
  return (
    <div className={styles.icons__callMeBack}>
      <a href="tel: +74951505599">
        <img src={callMeBackIcon} alt="call me back" />
      </a>
    </div>
  );
};
