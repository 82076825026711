export const getTokenFromStorage = () => {
  return localStorage.getItem("token");
};

export const removeTokenToStorage = () => {
  localStorage.removeItem("token");
};

export const setTokenToStorage = (token: string) => {
  localStorage.setItem("token", token);
};

// webPayment_confirmation_token
export const getWebPaymentConfirmationTokenFromStorage = () => {
  return sessionStorage.getItem("WPConfToken");
};

export const removeWebPaymentConfirmationTokenFromStorage = () => {
  sessionStorage.removeItem("WPConfToken");
};

export const setWebPaymentConfirmationTokenToStorage = (token: string) => {
  sessionStorage.setItem("WPConfToken", token);
};

// webPayment_payment_id
export const getWebPaymentIDFromStorage = () => {
  return sessionStorage.getItem("WPID");
};

export const removeWebPaymentIDFromStorage = () => {
  sessionStorage.removeItem("WPID");
};

export const setWebPaymentIDToStorage = (token: string) => {
  sessionStorage.setItem("WPID", token);
};
