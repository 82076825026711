import React, { useEffect } from "react";
import Styles from "./Settings.module.scss";
import { useAppDispatch } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { getProfileInfo } from "../../../../redux/profile/settings/getProfileInfo/selectors";
import { fetchProfileInfo } from "../../../../redux/profile/settings/getProfileInfo/asyncActions";
import {Button, Modal, PassChangeModal, Title} from "../../../../components";
import { ChangeUserName } from "../../Forms/ChangeUserName/ChangeUser";
import {
  fetchEditEmail,
  fetchEditName,
  fetchEditPassword,
  fetchEditPhone,
} from "../../../../redux/profile/settings/changeDataProfile/asyncActions";
import { ChangeEmail } from "../../Forms/EmailForm/ChangeEmail";
import { ChangePhone } from "../../Forms/PhoneForm/ChangePhone";
import {
  removeCartItemsFromStorage,
  removeTokenToStorage,
  setCartItemsToStorage,
  setSelectedSubscrToStorage
} from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { cartSelector } from "../../../../redux/cart/selectors";
import { ChangePassword } from "../../Forms/ChangePassword/ChangePassword";
import {setSelectedSubscr} from "../../../../redux/subscriptions/slice";
import {setSelectedHalls} from "../../../../redux/halls/slice";
import {setSelectedHallsToStorage} from "../../../../utils/storage/halls";
const Settings: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [type, setType] = React.useState<string>("");
  const [title, settitle] = React.useState<string>("");
  const [subTitle, setsubTitle] = React.useState<string>("");
  const [placeHolder, setplaceHolder] = React.useState<string>("");
  const dispatch = useAppDispatch();
  const { profileInfo } = useSelector(getProfileInfo);
  const navigate = useNavigate();
  const { totalCartItems } = useSelector(cartSelector);

  const HandleClickChangeName = () => {
    settitle("Сменить имя");
    setType("changeName");
    setplaceHolder("Введите имя");
    setIsOpenModal(true);
  };

  const HandleClickChangeEmail = () => {
    settitle("Сменить почту");
    setType("changeEmail");
    setplaceHolder("Введите почту");
    setIsOpenModal(true);
  };
  const HandleClickChangePhone = () => {
    settitle("Сменить телефон");
    setType("changePhone");
    setplaceHolder("+7 --- --- -- --");
    setIsOpenModal(true);
  };
  const handleChangePass = () => {
    setType("changePassword");
    settitle("Смена пароля");
    setIsOpenModal(true);
    setplaceHolder("Пароль");
  };
  const handleEndEditNameClose = async () => {
    await dispatch(fetchProfileInfo());
    setIsOpenModal(false);
  };

  const HandleClickExit = () => {
    settitle("Выход");
    setType("exitClick");
    setsubTitle("Выйти из учетной записи?");
    setIsOpenModal(true);
  };

  const handleCloseModalNoConfirm = () => {
    dispatch(fetchProfileInfo());
    setIsOpenModal(false);
  };

  const handleDeleteAllCartItems = () => {
    const emptyCart = { subscriptions: {}, seats: {} };
    setCartItemsToStorage(emptyCart);
    dispatch(setSelectedSubscr(emptyCart.subscriptions));
    setSelectedSubscrToStorage(emptyCart.subscriptions);
    dispatch(setSelectedHalls(emptyCart.seats));
    setSelectedHallsToStorage(emptyCart.seats);
  };

  const handleCloseModalConfirm = () => {
    removeTokenToStorage();
    handleDeleteAllCartItems();
    navigate("/");
  };

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, []);

  return (
    <div>
      <p className={Styles.subTitile}>Настройки</p>
      <div className={Styles.wrapper}>
        <div onClick={HandleClickChangeName} className={`${Styles.wrapper_item} `}>
          <div className={`${Styles.wrapper_text}`}>{profileInfo?.[0]?.name}</div>
        </div>
        <div onClick={HandleClickChangePhone} className={`${Styles.wrapper_item}`}>
          <div className={`${Styles.wrapper_text}`}>{profileInfo?.[0]?.phone}</div>
        </div>
        <div onClick={HandleClickChangeEmail} className={`${Styles.wrapper_item}`}>
          <div className={`${Styles.wrapper_text}`}>{profileInfo?.[0]?.email}</div>
        </div>
        <div onClick={handleChangePass} className={`${Styles.wrapper_item}`}>
          <div className={`${Styles.wrapper_text}`}>Сменить пароль</div>
        </div>
        <div onClick={HandleClickExit} className={`${Styles.wrapper_item}`}>
          <div className={`${Styles.wrapper_text}`}>Выход</div>
        </div>
      </div>
      {isOpenModal ? (
        <Modal
          title={title}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          closeFunc={handleEndEditNameClose}
          subTitle={subTitle}
        >
          {type === "exitClick" && (
            <div className={Styles.buttons}>
              <Button onClickFunc={handleCloseModalConfirm}>Да</Button>
              <Button onClickFunc={handleCloseModalNoConfirm}>Нет</Button>
            </div>
          )}
          {type === "changeName" && (
            <ChangeUserName
              close={async (values) => {
                await dispatch(fetchEditName({ name: values }));
                await dispatch(fetchProfileInfo());
                setIsOpenModal(false);
              }}
              type={type}
              placeholder={placeHolder}
            />
          )}
          {type === "changePassword" && (
            <ChangePassword
              close={async (values) => {
                await dispatch(
                  fetchEditPassword({ password: values, passwordRepeat: values })
                );
                await dispatch(fetchProfileInfo());
                setIsOpenModal(false);
              }}
              type={type}
              placeholder={placeHolder}
            />
          )}
          {type === "changeEmail" && (
            <ChangeEmail
              close={async (email) => {
                await dispatch(fetchEditEmail({ email: email }));
                await dispatch(fetchProfileInfo());
                setIsOpenModal(false);
              }}
              type={type}
              placeholder={placeHolder}
            />
          )}
          {type === "changePhone" && (
            <ChangePhone
              close={async (value) => {
                await dispatch(fetchEditPhone({ phone: value }));
                await dispatch(fetchProfileInfo());
                setIsOpenModal(false);
              }}
              type={type}
              placeholder={placeHolder}
            />
          )}
        </Modal>
      ) : null}
    </div>
  );
};

export default Settings;
