import React, { useState } from "react";
import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { authAndRegSelector } from "../../../../redux/profile/authAndReg/selectors";
import {
  fetchReg,
  fetchRequestCode,
} from "../../../../redux/profile/authAndReg/asyncActions";

import {
  NameInput,
  EmailInput,
  PhoneInput,
  PasswordInput,
  Button,
} from "../../../../components";

import styles from "./RegisterForm.module.scss";
import phoneIcon from "../../../../assets/icons/phoneInput.svg";
import passwordIcon from "../../../../assets/icons/passProfile.svg";
import profileIcon from "../../../../assets/icons/profileInput.svg";
import emailIcon from "../../../../assets/icons/emailTennis.svg";

import { ValidMessages } from "../../../../resources/locale";
import { RegValuesType } from "./types";
import {addEmail, addPhone} from "../../../../redux/profile/settings/changePass/slice";

export const RegisterForm = () => {
  const { isLoading } = useSelector(authAndRegSelector);
  const dispatch = useAppDispatch();

  const initialValues: RegValuesType = {
    name: "",
    email: "",
    phone: "",
    password: "",
    passwordRepeat: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(ValidMessages.inputs.name.REQUIRED),
    email: Yup.string()
      .email(ValidMessages.inputs.email.INCORRECT)
      .required(ValidMessages.inputs.email.REQUIRED),
    phone: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
    password: Yup.string()
      .required(ValidMessages.inputs.password.REQUIRED)
      .min(6, ValidMessages.inputs.password.NOT_FULL),
    passwordRepeat: Yup.string()
      .required(ValidMessages.inputs.passwordRepeat.REQUIRED)
      .oneOf([Yup.ref("password"), null], ValidMessages.inputs.passwordRepeat.NOT_MATCH),
  });

  const handleSubmit = async (
    values: RegValuesType,
    actions: FormikHelpers<RegValuesType>
  ) => {
    const data = { values, actions };
    const result = await dispatch(fetchReg(data)).unwrap();
    if (result.showWindow !== undefined) {
      dispatch(addPhone(values.phone));
      dispatch(addEmail(values.email))
      dispatch(fetchRequestCode({ phone: values.phone }));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles.formSection}>
              <label htmlFor="name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput name="name" placeholder="ФИО" />
              <ErrorMessage component="div" className={styles.errorMessage} name="name" />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="email">
                <img src={emailIcon} alt="email icon" />
              </label>
              <EmailInput name="email" placeholder="Email" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="email"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="phone">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput name="phone" placeholder="+7 (___) ___-__-__" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="password">
                <img src={passwordIcon} alt="password" />
              </label>
              <PasswordInput name="password" placeholder="Пароль" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="password"
              />
            </div>
            <div className={styles.formSection}>
              <label htmlFor="passwordRepeat">
                <img src={passwordIcon} alt="password" />
              </label>
              <PasswordInput name="passwordRepeat" placeholder="Повторите пароль" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="passwordRepeat"
              />
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={
                // !formik.dirty ||
                formik.isSubmitting
              }
            >
              Зарегистрироваться
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
