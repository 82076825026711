import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Button, Header, Title } from "../../../components";
import { fetchGetHall } from "../../../redux/halls/asyncActions";
import { hallsSelector } from "../../../redux/halls/selectors";
import { setDate, setLastSelectedHall } from "../../../redux/halls/slice";
import { useAppDispatch } from "../../../redux/store";
import {
  getDateFromStorage,
  getLastSelectedHallFromStorage,
} from "../../../utils/storage/halls";
import { HallTitle, Navigation, Price, TimesTable } from "../components";

import styles from "./HallsTime.module.scss";

import { HallsTimePropsType } from "./types";

export const HallsTime: React.FC<HallsTimePropsType> = ({ title }) => {
  const { date, lastSelectedHall } = useSelector(hallsSelector);
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    const date = getDateFromStorage();
    const lastSelectedHall = getLastSelectedHallFromStorage();

    if (date) dispatch(setDate(date));
    if (lastSelectedHall) dispatch(setLastSelectedHall(lastSelectedHall)); // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (date && lastSelectedHall) {
      const hallId = lastSelectedHall.id;
      dispatch(fetchGetHall({ hallId, date }));
    } // eslint-disable-next-line
  }, [date, lastSelectedHall]);

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container container--textAlignCenter">
          <Title>{title}</Title>
          <p className={styles.subTitile}>Выберите время для аренды зала</p>
          <HallTitle />
          <Navigation />
          <TimesTable />
          <Price />
          <Button newStyles={styles.button} to="/cart">
            В корзину
          </Button>
          <Link to="/halls-calendar" className={styles.link}>
            К предыдущему шагу
          </Link>
        </section>
      </main>
    </>
  );
};
