import React from "react";
import dateFormat from "dateformat";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Calendar, Header, Title } from "../../../components";
import { HallTitle } from "../components";

import { useAppDispatch } from "../../../redux/store";
import { setDate } from "../../../redux/halls/slice";

import styles from "./HallsCalendar.module.scss";

import { setDateToStorage } from "../../../utils/storage/halls";
import { HallsCalendarPropsType } from "./types";

export const HallsCalendar: React.FC<HallsCalendarPropsType> = ({ title }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleSelectDate = (calendarDate: Date) => {
    const date = dateFormat(calendarDate, "isoDate");
    dispatch(setDate(date));
    setDateToStorage(date);
    navigate("/halls-time");
  };

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container container--textAlignCenter">
          <Title>{title}</Title>
          <p className={styles.subTitile}>Выберите дату для аренды</p>
          <HallTitle />
          <Calendar onSelectDate={handleSelectDate} />
          <Link to="/halls" className={styles.link}>
            Выбрать другой зал
          </Link>
        </section>
      </main>
    </>
  );
};
