import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../resources/api";

import { IMyprofile, IProfile } from "./types";
import { getTokenFromStorage } from "../../../utils";

export const fetchProfileInfoMenu = createAsyncThunk<IProfile[]>(
  "getCards/fetchGetCards",
  async () => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.getProfile.GET_PROFILE}`;
      const token = getTokenFromStorage();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log("error fetchProfileInfoMenu >>>", error);
    }
  }
);

export const fetchMyProfiles = createAsyncThunk<IMyprofile[]>(
  "getProfiles/fetchGetProfiles",
  async () => {
    try {
      const typeProfile = "all";
      const url = `${api.BASE_URL}${api.profile.settings.getProfile.GET_TYPE_PROFILE(
        typeProfile
      )}`;
      const token = getTokenFromStorage();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log("error fetchMyProfiles >>>", error);
    }
  }
);

export const fetchDelProfiles = createAsyncThunk<IMyprofile[], object>(
  "detProfiles",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.profile.settings.getProfile.DEL_PROFILE}`;
      const token = getTokenFromStorage();
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchAddProfile = createAsyncThunk<string, { data: object; type: string }>(
  "editPassword/fetchePass",
  async ({ type, data }) => {
    try {
      const token = getTokenFromStorage();
      const url = `${api.BASE_URL}${api.profile.settings.getProfile.GET_TYPE(type)}`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.status === 200 && response.statusText === "OK";
    } catch (error: any) {
      return error.response.status;
    }
  }
);
