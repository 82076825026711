import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetToken, fetchGetWebPayToken } from "./asyncActions";
import { IPayToken, IResponseData } from "./types";

const initialState: IPayToken = {
  confirmation_token: "",
  payment_id: "",
  webPayment_confirmation_token: "",
  webPayment_payment_id: "",
};

const getPayTokenSlice = createSlice({
  name: "getToken",
  initialState,
  reducers: {
    setWebPaymentConfToken(state, action: PayloadAction<IResponseData>) {
      state.webPayment_confirmation_token = action.payload.confirmation_token;
      state.webPayment_payment_id = action.payload.payment_id;
    },
    resetWebPaymentConfToken(state) {
      state.webPayment_confirmation_token = "";
      state.webPayment_payment_id = "";
    },
  },
  extraReducers: (builder) => {
    //get confirmToken
    builder.addCase(
      fetchGetToken.fulfilled,
      (state, action: PayloadAction<IPayToken>) => {
        state.confirmation_token = action.payload.confirmation_token;
        state.payment_id = action.payload.payment_id;
      }
    );
    builder.addCase(
      fetchGetWebPayToken.fulfilled,
      (state, action: PayloadAction<IResponseData>) => {
        state.webPayment_confirmation_token = action.payload.confirmation_token;
        state.webPayment_payment_id = action.payload.payment_id;
      }
    );
  },
});

export const { setWebPaymentConfToken, resetWebPaymentConfToken } =
  getPayTokenSlice.actions;

export default getPayTokenSlice.reducer;
