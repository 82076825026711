import { createSlice } from "@reduxjs/toolkit";
import { IRerendertState } from "./types";

const initialState: IRerendertState = {
  rerender: true,
};

const rerenderSlice = createSlice({
  name: "rerender",
  initialState,
  reducers: {
    rerender(state) {
      state.rerender = !state.rerender;
    },
  },
});

export const { rerender } = rerenderSlice.actions;

export default rerenderSlice.reducer;
