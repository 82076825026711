import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import PageLayout from "./layouts/PageLayout";
import {
  Cart,
  ProfileLayout,
  Payment,
  PaymentSuccess,
  PaymentError,
  Contacts,
  School,
  Subscriptions,
  SubscriptionsForms,
  MainPage,
  HallsCalendar,
  Halls,
  HallsTime,
  SubscriptionsProfiles,
} from "./pages";

import { CardList } from "./components";

import { PageTitles } from "./resources/locale";

import "./styles/app.scss";

import SubscriptionList from "./pages/Profile/components/SubscriptionsList/SubscriptionList";
import Settings from "./pages/Profile/components/Settings/Settings";
import { About } from "./pages/AboutPage/About";
import { Rules } from "./pages/Rules/Rules";
import { Politics } from "./pages/Politics/Politics";
import HallsList from "./pages/Profile/components/HallsList/HallsList";
import HistoryList from "./pages/Profile/components/HistoryList/HistoryList";
import { MyProfilesList } from "./components/MyProfiles/MyProfilesList";
import { OurClub } from "./pages/AboutPage/Components/ClubPhoto/OurClub/OurClub";
import { HallsPhoto } from "./pages/AboutPage/Components/ClubPhoto/HallsPhoto";
import { PhotoRenderPage } from "./pages/AboutPage/Components/ClubPhoto/HallPhotoRender/PhotoRenderPage";
import { Offer } from "./pages/Offer/Offer";
import {Oferta} from "./pages/Rules/Oferta";
import MetaTags from "./components/MetaTags/MetaTags";

function App() {
  const location = useLocation()
  return (
    <>
    <MetaTags path={location.pathname}/>
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route path="" element={<MainPage title={PageTitles.SUBSCRIPTION} />} />
        <Route
          path="subscriptions"
          element={<Subscriptions title={PageTitles.SUBSCRIPTION} />}
        />
        <Route
          path="subscriptions-forms"
          element={<SubscriptionsForms title={PageTitles.USER_INFO} />}
        />
        <Route
          path="subscriptions-profiles"
          element={<SubscriptionsProfiles title={PageTitles.PROFILES_LIST} />}
        />
        <Route path="halls" element={<Halls title={PageTitles.HALLS} />} />
        <Route
          path="halls-calendar"
          element={<HallsCalendar title={PageTitles.HALLS} />}
        />
        <Route path="halls-time" element={<HallsTime title={PageTitles.HALLS} />} />

        <Route path="rules" element={<Rules title={PageTitles.RULES} />} />
        <Route path="politics" element={<Politics title={PageTitles.POLITICS} />} />
        <Route path="offer" element={<Oferta title={PageTitles.OFFER} />} />
        <Route path="/profile" element={<ProfileLayout title={PageTitles.PROFILE} />}>
          <Route path="cards" element={<CardList />} />
          <Route path="subscriptions" element={<SubscriptionList />} />
          <Route path="settings" element={<Settings />} />
          <Route path="halls" element={<HallsList />} />
          <Route path="history" element={<HistoryList />} />
          <Route path="myprofiles" element={<MyProfilesList />} />
        </Route>
        <Route path="/about" element={<About title={PageTitles.ABOUT} />}>
          <Route
            path="hallsFoto/*"
            element={<HallsPhoto title={PageTitles.HALLS_PHOTO} />}
          />
          <Route path="hallsFoto/morePhoto" element={<PhotoRenderPage />} />
          <Route path="clubFoto" element={<OurClub />} />
        </Route>
        <Route path="school" element={<School title={PageTitles.SCHOOL} />} />
        <Route path="cart" element={<Cart title={PageTitles.CART} />} />
        <Route path="payment" element={<Payment title={PageTitles.PAYMENT} />} />
        <Route path="payment-success" element={<PaymentSuccess />}></Route>
        <Route path="payment-error" element={<PaymentError />}></Route>
        <Route path="contacts" element={<Contacts title={PageTitles.CONTACTS} />} />
      </Route>
    </Routes>
    </>
    
  );
}

export default App;
