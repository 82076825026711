import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./header.module.scss";

import { SubMenuItemsType, SubMenuPropsType } from "./types";

const subMenuItems: SubMenuItemsType = [
  { id: "clubFoto", name: "Фото клуба", href: "/about/clubFoto" },
  { id: "hallsFoto", name: "Фото спортивных залов", href: "/about/hallsFoto" },
];

const SubMenuAbout: React.FC<SubMenuPropsType> = (props: SubMenuPropsType) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const subMenuRef = React.useRef<HTMLUListElement>(null);
  const subMenuTriggerRef = React.useRef<HTMLAnchorElement>(null);
  const subMenuLinksRef = React.useRef<HTMLAnchorElement[] | null[]>([]);
  const navigate = useNavigate();
  const handleNavigation = (href: string) => {
    navigate(href);
    setIsSubMenuOpen(false);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { href, name } = props;
  const subMenu = renderSubMenu(subMenuItems);

  const handleSubMenuClick = () => {
    setIsSubMenuOpen((isSubMenuOpen) => !isSubMenuOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    const isLinkRefArray = subMenuLinksRef.current.map((item) =>
      item ? e.composedPath().includes(item) : null
    );
    if (isLinkRefArray.includes(true)) {
      setIsSubMenuOpen(false);
    } else if (
      subMenuRef.current &&
      !e.composedPath().includes(subMenuRef.current) &&
      subMenuTriggerRef.current &&
      !e.composedPath().includes(subMenuTriggerRef.current)
    ) {
      setIsSubMenuOpen(false);
    }
  };

  function renderSubMenu(menuItems: SubMenuItemsType) {
    return menuItems.map(({ id, name, href }) => (
      <li key={id}>
        <button onClick={() => handleNavigation(href)}>{name}</button>
      </li>
    ));
  }

  return (
    <>
      <a
        href={href}
        className={styles.mainMenu__link__sub}
        onClick={handleSubMenuClick}
        ref={subMenuTriggerRef}
      >
        {name}
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6L8 10L12 6" stroke="#2B3558" stroke-width="1.5"/>
        </svg>
      </a>
      <ul
        className={styles.subMenu__about + (isSubMenuOpen ? ` ${styles.active}` : "")}
        ref={subMenuRef}
      >
        {subMenu}

      </ul>
    </>
  );
};

export default SubMenuAbout;
