import React from "react";
import dateFormat, { i18n } from "dateformat";
import { isWithinInterval } from "date-fns";
import {
  Calendar as ReactCalendar,
  CalendarTileProperties,
  FormatterCallback,
  DateCallback,
} from "react-calendar";

import "./calendar.scss";
import arrowLeftIcon from "../../../../assets/icons/arrowCalendarLeft.svg";
import arrowRightIcon from "../../../../assets/icons/arrowCalendarRight.svg";

import { useCurrentWidth } from "../../../../hooks";
import { MediaBreakPoints, appController } from "../../../../resources/constants";
import { CalendarPropsType } from "./types";
import { monthNamesForCalendar } from "../../../../resources/locale";

i18n.monthNames = monthNamesForCalendar;

export const Calendar: React.FC<CalendarPropsType> = (props) => {
  const {
    currentDate = new Date(),
    region = "ru",
    maxDate = new Date(2030, 12, 31),
    minDate = new Date(2022, 0, 1),
    onSelectDate,
  } = props;

  const navBtnLeft = <img src={arrowLeftIcon} alt="prev button" />;
  const navBtnRight = <img src={arrowRightIcon} alt="next button" />;

  const windowWidth = useCurrentWidth();
  const breakpointDoubleView = MediaBreakPoints.MOBILE_LARGE;

  const handleSelectDate: DateCallback = (value) => {
    onSelectDate(value);
  };

  const tileDisabled = ({ date }: CalendarTileProperties) => {
    const firstEnableDate = currentDate.setHours(0, 0, 0, 0);
    const lastEnableDate = appController.DISABLE_NEXT_MONTH_DATES
      ? new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      : new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);

    const enableRange = isWithinInterval(date, {
      start: firstEnableDate,
      end: lastEnableDate,
    });

    return !enableRange;
  };

  const formatMonth: FormatterCallback = (_, date) => dateFormat(date, "mmmm");

  return (
    <div>
      <ReactCalendar
        value={currentDate}
        locale={region}
        maxDate={maxDate}
        minDate={minDate}
        prevLabel={navBtnLeft}
        nextLabel={navBtnRight}
        showDoubleView={windowWidth > breakpointDoubleView ? true : false}
        showNeighboringMonth={false}
        defaultView="month"
        maxDetail="month"
        minDetail="month"
        onClickDay={handleSelectDate}
        tileDisabled={tileDisabled}
        formatMonthYear={formatMonth}
      />
    </div>
  );
};
