import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components";

import styles from "./TennisSchoolSuccessForm.module.scss";
import offerImg from "../../../../assets/img/offerSend.png";

import { SchoolPropsType } from "./types";

export const SchoolSuccessForm: React.FC<SchoolPropsType> = (props) => {
  const { onClickFunc } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    onClickFunc();
    navigate("/");
  };

  return (
    <>
      <div className={styles.image}>
        <img src={offerImg} alt="offer sent" />
      </div>
      <h4 className={styles.subTitle}>Спасибо, что оставили заявку!</h4>
      <p className={styles.description}>Наши менеджеры скоро свяжутся с Вами!</p>
      <Button newStyles={styles.button} onClickFunc={handleClick}>
        Вернуться на главную
      </Button>
    </>
  );
};
