import React from "react";

import {
  Portal,
  Title,
  Footer,
  CartIcon,
  LoginIcon,
  CallMeBackIcon,
} from "../../components";
import { useCurrentWidth } from "../../hooks";
import { MediaBreakPoints } from "../../resources/constants";

import styles from "./Modal.module.scss";
import arrowBack from "../../assets/icons/arrowHeaderLeft.svg";

import { ModalPropsType } from "./types";

export const Modal: React.FC<ModalPropsType> = (props) => {
  const {
    children,
    title,
    subTitle,
    isOpen,
    setIsOpen,
    closeFunc,
    modalWindowWidth = 415,
    mobileContentSize = "full-screen", // 'full-screen' | 'fixed'
  } = props;

  React.useEffect(() => {
    document.body.style.overflow = "hidden"; // no scroll site when modal is active
    window.addEventListener("keydown", closeEsc);

    return () => {
      document.body.style.overflow = "visible";
      window.removeEventListener("keydown", closeEsc);
    };
    // eslint-disable-next-line
  }, []);

  const windowWidth = useCurrentWidth();
  const breakPoint = MediaBreakPoints.MOBILE_MEDIUM;
  const isFullScreenCondition =
    windowWidth < breakPoint && mobileContentSize === "full-screen";

  const modalCloseButton = renderCloseButton();
  const modalHeader = renderModalHeader();
  const modalBody = renderModalBody();

  const closeEsc = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose();
    }
  };

  function handleClose() {
    setIsOpen(false);
    if (closeFunc) closeFunc();
  }

  function renderCloseButton() {
    return (
      <button className={styles.closeButton} onClick={handleClose}>
        <span></span>
        <span></span>
      </button>
    );
  }

  function renderModalHeader() {
    return (
      <header className={styles.header + " container"}>
        <button className={styles.header_backButton} onClick={handleClose}>
          <img src={arrowBack} alt="back" />
        </button>
        <Title newStyles={styles.header_title} mountLocation="modalHeader" hType="h3">
          {title}
        </Title>
        <div className={styles.header_icons}>
          <CallMeBackIcon />
          <CartIcon />
        </div>
      </header>
    );
  }

  function renderModalBody() {
    return (
      <div className={styles.body}>
        <Title newStyles={styles.body_title} mountLocation="modalPage" hType="h3">
          {title}
        </Title>
        {subTitle ? <p className={styles.body_subTitle}>{subTitle}</p> : null}
        {children}
      </div>
    );
  }

  return (
    <>
      {isOpen && (
        <Portal>
          <div className={styles.overlay} onClick={handleClose}>
            <div
              className={styles.window}
              style={
                isFullScreenCondition
                  ? { width: "100vw", height: "100vh" }
                  : { width: `${modalWindowWidth}px` }
              }
              onClick={(e) => e.stopPropagation()}
            >
              {isFullScreenCondition ? null : modalCloseButton}
              {isFullScreenCondition ? modalHeader : null}
              {modalBody}
              {isFullScreenCondition ? <Footer /> : null}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
