import React, {useEffect, useState} from "react";
import Styles from "./SubscriptionList.module.scss"
import {useAppDispatch} from "../../../../redux/store";
import {fetchHistory} from "../../../../redux/profile/history/asyncActions";
import {useSelector} from "react-redux";
import {getUserHistory} from "../../../../redux/profile/history/selectors";
import preloader from "../../../../assets/img/load.gif";
import SubscriptionItem from "../SubscriptionsItem/SubscriptionItem";
import {id} from "date-fns/locale";
import {IItem} from "../../../../redux/profile/history/types";
const SubscriptionList: React.FC = (props) => {

    const dispatch = useAppDispatch();
    const {items, isLoading} = useSelector(getUserHistory)
    const [data, setData] = useState<[] | IItem[]>([])

    useEffect(() => {
        dispatch(fetchHistory())
    },[])

    useEffect(() => {
        if (items[0]?.subscription)
            setData([...items])
    }, [items])

    return(
        <div className={Styles.wrapper}>
            <p className={Styles.subTitile}>Мои абонементы</p>
            {
                isLoading ? (
                    <div className={Styles.preloader}>
                        <img className={Styles.preloader_img} src={preloader} alt="preloader" />
                    </div>
                ) : (
                    <div className={Styles.item}>
                        {data.map( (item, index) => {
                            console.log(data)
                            if (item.subscription.type === 'family' && item.family !== undefined) {
                                data.splice(index, index + item.family.length - 1)
                            }

                            return <SubscriptionItem key={item.id} status="big" data={item}/>
                          })
                        }
                    </div>
                )
            }
        </div>
    )

}

export default SubscriptionList
