import React from "react";

import { useCurrentWidth } from "../../hooks";
import { MediaBreakPoints } from "../../resources/constants";

import styles from "./Titile.module.scss";

import { TitilePropsType } from "./types";

export const Title: React.FC<TitilePropsType> = (props) => {
  const { children, newStyles, mountLocation = "page", hType = "h1" } = props;

  const windowWidth = useCurrentWidth();

  const currentStyles = newStyles
    ? `${styles.title} ${newStyles}`
    : styles.title;

  const breakPoint = MediaBreakPoints.MOBILE_LARGE;
  const modalBreakPoint = MediaBreakPoints.MOBILE_MEDIUM;
  const Tag = hType;
  const currentTitle = renderTitle();

  function renderTitle() {
    switch (mountLocation) {
      case "page":
        return windowWidth > breakPoint ? (
          <Tag className={currentStyles}>{children}</Tag>
        ) : null;
      case "header":
        return windowWidth < breakPoint ? (
          <Tag className={currentStyles}>{children}</Tag>
        ) : null;
      case "modalPage":
        return windowWidth > modalBreakPoint ? (
          <Tag className={currentStyles}>{children}</Tag>
        ) : null;
      case "modalHeader":
        return windowWidth < modalBreakPoint ? (
          <Tag className={currentStyles}>{children}</Tag>
        ) : null;
      default:
        return null;
    }
  }

  return <>{currentTitle}</>;
};
