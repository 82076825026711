import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Button } from "../../../../components";

import styles from "./AcceptTermsForm.module.scss";

import { ValidMessages } from "../../../../resources/locale";
import { useAppDispatch } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { authAndRegSelector } from "../../../../redux/profile/authAndReg/selectors";
import { setShowWindow } from "../../../../redux/profile/authAndReg/slice";

import { WindowNames } from "../../../../resources/constants";

import { AcceptTermsFormPropsType } from "./types";

export const AcceptTermsForm: React.FC<AcceptTermsFormPropsType> = (props) => {
  const { totalPrice, setIsOpenModalNoAuth } = props;
  const { auth } = useSelector(authAndRegSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues = {
    acceptTerms: false,
    acceptRules: false,
    acceptOffer: false,
  };

  const validationSchema = Yup.object({
    acceptTerms: Yup.bool().oneOf([true], ValidMessages.inputs.acceptTerms.REQUIRED),
    acceptRules: Yup.bool().oneOf([true], ValidMessages.inputs.acceptRules.REQUIRED),
    acceptOffer: Yup.bool().oneOf([true], ValidMessages.inputs.acceptOffer.REQUIRED),
  });

  const handleSubmit = async () => {
    if (!auth) {
      setIsOpenModalNoAuth(true);
      dispatch(setShowWindow(WindowNames.AUTH));
      return;
    }

    navigate("/payment");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form className={styles.acceptForm}>
            <div className={styles.acceptForm_section}>
              <Field
                type="checkbox"
                className={styles.acceptForm_checkbox}
                id="acceptTerms"
                name="acceptTerms"
              />
              <label htmlFor="acceptTerms" className={styles.acceptForm_label}>
                <span>
                  Ознакомлен, что оплата за абонементы и аренду залов возврату не подлежит
                </span>
              </label>
              <ErrorMessage
                name="acceptTerms"
                component="div"
                className={styles.acceptForm_error}
              />
            </div>
            <div className={styles.acceptForm_section}>
              <Field
                type="checkbox"
                className={styles.acceptForm_checkbox}
                id="acceptRules"
                name="acceptRules"
              />
              <label htmlFor="acceptRules" className={styles.acceptForm_label}>
                <span>
                  Я принимаю {/*  eslint-disable-next-line */}
                  <Link to="/rules">правила клуба</Link>
                </span>
              </label>
              <ErrorMessage
                name="acceptRules"
                component="div"
                className={styles.acceptForm_error}
              />
            </div>
            <div className={styles.acceptForm_section}>
              <Field
                type="checkbox"
                className={styles.acceptForm_checkbox}
                id="acceptOffer"
                name="acceptOffer"
              />
              <label htmlFor="acceptOffer" className={styles.acceptForm_label}>
                <span>
                  Я принимаю {/*  eslint-disable-next-line */}
                  <Link to="/offer">условия оферты</Link>
                </span>
              </label>
              <ErrorMessage
                name="acceptOffer"
                component="div"
                className={styles.acceptForm_error}
              />
            </div>

            <Button
              type="submit"
              newStyles={styles.button}
              disabled={formik.isSubmitting}
            >
              <span>Оплатить</span>
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
