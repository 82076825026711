export const getTotalPriceFromStorage = () => {
  const totalPrice = sessionStorage.getItem("totalPrice");
  if (totalPrice) return +totalPrice;
  if (!totalPrice) return null;
};

export const removeTotalPriceFromStorage = () => {
  sessionStorage.removeItem("totalPrice");
};

export const setTotalPriceToStorage = (price: number | string) => {
  const totalPrice = `${price}`;
  sessionStorage.setItem("totalPrice", totalPrice);
};
