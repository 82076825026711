export enum MediaBreakPoints { // styles/_variables.scss
  TABLET = 930,
  MOBILE_LARGE = 720,
  MOBILE_MEDIUM = 560,
  MOBILE_SMALL = 460,
}

export enum FormNames {
  REGAPPLY = "apply-reg",
  CODEREG = "code-reg",
  PHONE = "input-phone",
  CODE = "input-code",
  PASSWORD = "input-password",
  PASSWORD_APPLY = "password-apply",
  MESSAGE = "confirm-message",
  ERROR_PHONE = "error-phone-not-found",
  ERROR_CODE = "error-bad-code",
  ERROR = "error",
}

export enum WindowNames {
  CODE = "TESTcode",
  AUTH = "auth",
  AUTH_SUCCESS = "authSuccess",
  AUTH_ERROR = "authError",
  FORGOT_PASS = "forgotPass",
  REGISTRATION = "register",
  REGISTRATION_SUCCESS = "regSuccess",
  ERROR = "error",
  ERROR_CODE = "error-bad-code",
}

export const appController = {
  DISABLE_NEXT_MONTH_DATES: false,
  AFK_TIMER_MINUTES: 15,
};

export const hallImages = {
  1: require("../assets/img/hallsPhoto/hall1-main.jpg"),
  2: require("../assets/img/hallsPhoto/hall2-main.jpg"),
  3: require("../assets/img/hallsPhoto/hall3-main.jpg"),
  4: require("../assets/img/hallsPhoto/hall4-main.jpg"),
  5: require("../assets/img/hallsPhoto/hall5-main.jpg"),
  6: require("../assets/img/hallsPhoto/hall6-main.jpg"),
  7: require("../assets/img/hallsPhoto/hall7-main.jpg"),
  8: require("../assets/img/hallsPhoto/hall8-main.jpg"),
  9: require("../assets/img/hallsPhoto/hall9-main.jpg"),
  10: require("../assets/img/hallsPhoto/hall10-main.jpg"),
  11: require("../assets/img/hallsPhoto/hall11-main.jpg"),
  12: require("../assets/img/hallsPhoto/hall12-main.jpg"),
  13: require("../assets/img/hallsPhoto/hall13-main.jpg"),
  14: require("../assets/img/hallsPhoto/hall14-main.jpg"),
  15: require("../assets/img/hallsPhoto/hall15-main.jpg"),
};
