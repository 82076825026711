import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Header, MyProfilesList, Title } from "../../../components";

import { useAppDispatch } from "../../../redux/store";
import { setSubscrFormsData } from "../../../redux/subscriptions/slice";

import styles from "./SubscriptionsProfiles.module.scss";

import { SubscrProfilesPropsType } from "./types";
import { ISubscrFormData } from "../../../redux/subscriptions/types";
import { IMyprofile } from "../../../redux/profile/profileInfo/types";
import { getSubscrFormsDataFromStorage } from "../../../utils";

export const SubscriptionsProfiles: React.FC<SubscrProfilesPropsType> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { formData } = location.state as { formData: ISubscrFormData };

  React.useEffect(() => {
    const subscrFormsData = getSubscrFormsDataFromStorage();

    if (subscrFormsData) {
      subscrFormsData.forEach((item: ISubscrFormData) =>
        dispatch(setSubscrFormsData(item))
      );
    } // eslint-disable-next-line
  }, []);

  const handleSelectProfile = (profileData: IMyprofile) => {
    formData.profileValues.name = profileData.name;
    if (profileData.phone) formData.profileValues.secondaryText = profileData.phone;
    if (profileData.birthday) formData.profileValues.secondaryText = profileData.birthday;
    formData.profileValues.id = profileData.id;
    formData.profileValues.elKey = profileData.user_id; // TODO: How it make?

    dispatch(setSubscrFormsData(formData));
    navigate("/subscriptions-forms");
  };

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>
          <div className={styles.wrapper}>
            <div className={styles.profileList}>
              {
                formData.subscrData.type === "family"? (
                    <MyProfilesList
                      onPage="payment"
                      selectFunc={handleSelectProfile}
                      filterType={formData.type}
                    />
                )
                  :
                  (
                    <MyProfilesList
                      onPage="payment"
                      selectFunc={handleSelectProfile}
                      filterType={formData.subscrData.type}
                    />
                  )
              }
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
