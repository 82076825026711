import { ITotalCartItems } from "../../../redux/cart/types";

export const getCartItemsFromStorage = () => {
  const cartItems = sessionStorage.getItem("cartItems");
  if (cartItems) return JSON.parse(cartItems);
  if (!cartItems) return null;
};

export const removeCartItemsFromStorage = () => {
  sessionStorage.removeItem("cartItems");
};

export const setCartItemsToStorage = (cartItems: ITotalCartItems) => {
  const stringedCartItem = JSON.stringify(cartItems);

  sessionStorage.setItem("cartItems", stringedCartItem);
};
