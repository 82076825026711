import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { useSelector } from "react-redux";

import { NameInput, PhoneInput, Button, TextareaInput } from "../../../../components";

import { useAppDispatch } from "../../../../redux/store";
import { fetchTennisSchoolOrder } from "../../../../redux/school/asyncActions";
import { schoolSelector } from "../../../../redux/school/selectors";

import styles from "./TennisSchoolForm.module.scss";
import phoneIcon from "../../../../assets/icons/phone.png";
import profileIcon from "../../../../assets/icons/profile.svg";
import presentIcon from "../../../../assets/icons/present.svg";

import { ValidMessages } from "../../../../resources/locale";
import { ITennisSchoolData } from "../../../../redux/school/types";
import { TennisSchoolPropsType } from "./types";

export const TennisSchoolForm: React.FC<TennisSchoolPropsType> = ({ onClickFunc }) => {
  const { isLoading, isErrorFetch } = useSelector(schoolSelector);
  const dispatch = useAppDispatch();

  const initialValues: ITennisSchoolData = {
    child_name: "",
    child_birthday: "",
    parent_name: "",
    phone_number: "",
    level: "",
    additional: "",
  };

  const validationSchema = Yup.object({
    child_name: Yup.string().required(ValidMessages.inputs.child_name.REQUIRED),
    parent_name: Yup.string().required(ValidMessages.inputs.parent_name.REQUIRED),
    level: Yup.string().required(ValidMessages.inputs.level.REQUIRED),
    phone_number: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
  });

  const handleSubmit = async (values: ITennisSchoolData) => {
    const cloneValues = Object.assign({}, values);
    cloneValues.phone_number = cloneValues.phone_number.replace(/[-()\s]/g, "");

    await dispatch(fetchTennisSchoolOrder(cloneValues));
    if (!isErrorFetch) onClickFunc("tennis-shool-success");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles.formSection}>
              <label htmlFor="child_name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput name="child_name" placeholder="ФИО ребенка" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="child_name"
              />
            </div>

            <div className={styles.formSection}>
              <label htmlFor="child_birthday">
                <img src={presentIcon} alt="name icon" />
              </label>
              <NameInput name="child_birthday" placeholder="Дата рождения" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="child_birthday"
              />
            </div>

            <div className={styles.formSection}>
              <label htmlFor="parent_name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput name="parent_name" placeholder="ФИО родителя" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="parent_name"
              />
            </div>

            <div className={styles.formSection}>
              <label htmlFor="phone_number">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput
                name="phone_number"
                placeholder="Номер телефона"
                // noMask={true}
              />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone_number"
              />
            </div>

            <p className={styles.radioTitle}>Уровнь</p>
            <div className={styles.formSection_radioWrapper}>
              <Field name="level">
                {({ field, meta }: FieldProps) => {
                  return (
                    <div
                      className={`${styles.formSection_radio} ${
                        meta.touched && meta.error ? styles.formSection_radio_error : null
                      }`}
                    >
                      <div className={styles.radioButton}>
                        <input
                          type="radio"
                          id="Начальный"
                          {...field}
                          name="level"
                          value="Начальный"
                          checked={field.value === "Начальный"}
                        />
                        <label htmlFor="Начальный">Начальный</label>
                      </div>

                      <div className={styles.radioButton}>
                        <input
                          type="radio"
                          id="Средний"
                          {...field}
                          name="level"
                          value="Средний"
                          checked={field.value === "Средний"}
                        />
                        <label htmlFor="Средний">Средний</label>
                      </div>

                      <div className={styles.radioButton}>
                        <input
                          type="radio"
                          id="Высокий"
                          {...field}
                          name="level"
                          value="Высокий"
                          checked={field.value === "Высокий"}
                        />
                        <label htmlFor="Высокий">Высокий</label>
                      </div>
                    </div>
                  );
                }}
              </Field>

              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="level"
              />
            </div>

            <div className={styles.formSection_textarea}>
              <TextareaInput name="additional" placeholder="Ваши дополнения" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="additional"
              />
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={formik.isSubmitting}
            >
              Отправить
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
