import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";

import { Title, CartIcon, LoginIcon, CallMeBackIcon } from "../../components";

import { setAuth } from "../../redux/profile/authAndReg/slice";
import { authAndRegSelector } from "../../redux/profile/authAndReg/selectors";
import { useAppDispatch } from "../../redux/store";

import { getTokenFromStorage } from "../../utils";
import { useCurrentWidth } from "../../hooks";
import { MediaBreakPoints } from "../../resources/constants";

import styles from "./header.module.scss";
import logo from "../../assets/img/logo.svg";
import arrowBack from "../../assets/icons/arrowHeaderLeft.svg";

import SubMenuAbout from "./SubMenuAbout";
import { MainMenuItemsType, HeaderPropsType } from "./types";

const mainMenuItems: MainMenuItemsType = [
  { id: "subscriptions", name: "Абонементы", href: "/subscriptions", subMenu: false },
  { id: "rentHalls", name: "Аренда залов", href: "/halls", subMenu: false },
  { id: "about", name: "О клубе", href: "#", subMenu: "SubMenuAbout" },
  { id: "contacts", name: "Контакты", href: "/contacts", subMenu: false },
];

export const Header: React.FC<HeaderPropsType> = (props) => {
  const { title, headerType = "page", returnButton = false } = props;
  const [isMainMenuMobileOpen, setIsMainMenuMobileOpen] = React.useState(false);
  const { auth } = useSelector(authAndRegSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const windowWidth = useCurrentWidth();
  const breakPoint = MediaBreakPoints.MOBILE_LARGE;
  const location = useLocation()

  React.useEffect(() => {
    const isAuth = getTokenFromStorage();
    dispatch(setAuth(isAuth));

    document.body.addEventListener("click", clickOutside);

    return () => {
      document.body.removeEventListener("click", clickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const mainMenuRef = React.useRef<HTMLDivElement>(null);
  const mainMenuButtonRef = React.useRef<HTMLButtonElement>(null);

  const nanoidId = React.useMemo(() => nanoid(), [mainMenuItems]);
  const mainMenu = renderMainMenu(mainMenuItems);

  const clickOutside = (e: MouseEvent) => {
    if (
      mainMenuRef.current &&
      !e.composedPath().includes(mainMenuRef.current) &&
      mainMenuButtonRef.current &&
      !e.composedPath().includes(mainMenuButtonRef.current)
    ) {
      setIsMainMenuMobileOpen(false);
      document.body.style.overflow = "visible";
    }
  };

  const handleMainMenuMobileClose = () => {
    setIsMainMenuMobileOpen(false);
    document.body.style.overflow = "visible";
  };

  const handleMainMenuMobileOpen = () => {
    setIsMainMenuMobileOpen(true);
    document.body.style.overflow = "hidden";
  };

  function renderMainMenu(menuItems: MainMenuItemsType) {
    const isMobile = windowWidth < breakPoint;
    const formatedMenuItems = menuItems.map(({ id, name, href, subMenu }) => {
      if (subMenu) {
        if (isMobile) {
          return (
            <li key={id}>
              <Link onClick={()=> {"/about" === location.pathname&&handleMainMenuMobileClose()}} to="/about">{name}</Link>
            </li>
          );
        } else {
          return (
            <li key={id}>
              <SubMenuAbout name={name} href={href} />
            </li>
          );
        }
      } else {
        return (
          <li key={id}>
            <Link onClick={()=> {href === location.pathname&&handleMainMenuMobileClose()}} to={href}>{name}</Link>
          </li>
        );
      }
    });

    if (isMobile && auth) {
      formatedMenuItems.push(
        <li key={nanoidId}>
          <Link onClick={()=> {"/profile" === location.pathname&&handleMainMenuMobileClose()}} to="/profile">Профиль</Link>
        </li>
      );
      return formatedMenuItems;
    } else if (isMobile && !auth) {
      formatedMenuItems.push(<LoginIcon isMobileMenu={true} key={nanoidId} />);
      return formatedMenuItems;
    } else {
      return formatedMenuItems;
    }
  }

  function handleReturn() {
    location.pathname === "/profile/cards" ?
    navigate('/profile'):
    navigate(-1);
  }

  return (
    <header className={styles.wrapper + " container"}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={logo} alt="Company's logo" />
        </Link>
      </div>

      {returnButton ? (
        <button className={styles.returnButton} onClick={handleReturn}>
          <img src={arrowBack} alt="back" />
        </button>
      ) : (
        <button
          ref={mainMenuButtonRef}
          className={styles.mainMenuButton}
          onClick={handleMainMenuMobileOpen}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      )}

      <Title newStyles={styles.title} mountLocation="header">
        {title}
      </Title>
      <nav
        className={styles.mainMenu + (isMainMenuMobileOpen ? ` ${styles.active}` : "")}
        ref={mainMenuRef}
      >
        <button
          className={styles.mainMenu__closeButton}
          onClick={handleMainMenuMobileClose}
        >
          <span></span>
          <span></span>
        </button>
        <ul className={styles.mainMenu__list}>{mainMenu}</ul>
      </nav>
      <div className={styles.icons}>
        <LoginIcon />
        <CallMeBackIcon />
        <CartIcon />
      </div>
    </header>
  );
};
