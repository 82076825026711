import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchGetNews } from "./asyncActions";
import { INews, INewsState } from "./types";

const initialState: INewsState = {
    news: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(
        fetchGetNews.fulfilled,
        (state, action: PayloadAction<INews[]>) => {
          state.news = action.payload
        }
    );
  },
});

export default cartSlice.reducer;
