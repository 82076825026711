import React from "react";
import Styles from "./CardItem.module.scss";
import { ICardItem } from "./types";
import cardIcon from "../../assets/icons/card.svg";
import trashIcon from "../../assets/icons/trashIconFitnes.svg";
import { useAppDispatch } from "../../redux/store";
import { setCardid } from "../../redux/profile/cards/slice";
import { useSelector } from "react-redux";
import { getCardSelector } from "../../redux/profile/cards/selectors";
import { fetchDelCards, fetchGetCards } from "../../redux/profile/cards/asyncActions";
import { ModalTexts } from "../../resources/locale";
import { Button, Modal } from "../../components";

export const CardItem: React.FC<ICardItem> = (props) => {
  const { onPage = "profile", selectFunc } = props;

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { cardId } = useSelector(getCardSelector);

  const handleCloseModalNoConfirm = () => {
    setIsOpenModal(false);
  };

  const handleCloseModalConfirm = async () => {
    await dispatch(fetchDelCards(cardId));
    setIsOpenModal(false);
    await dispatch(fetchGetCards());
  };

  function handleRemove(id: number) {
    setIsOpenModal(true);
    dispatch(setCardid(id));
  }

  const handleSelectCard = () => {
    if (onPage === "payment" && selectFunc) {
      selectFunc(props.data.id);
    }
  };

  return (
    <>
      <div className={Styles.item} data-type={props.dataType} onClick={handleSelectCard}>
        <span className={Styles.cardNumber}>
            <img src={cardIcon} />
            <div>**** **** **** {props.data.title && props.data.title.substring(12)}</div>
        </span>
        <div
          onClick={() => handleRemove(props.data.id)}
          className={`${Styles.item_trash}`}
        >
          <img src={trashIcon} />
        </div>
      </div>
      {isOpenModal ? (
        <Modal
          title={ModalTexts.deleteCard.confirmation.TITLE}
          subTitle={ModalTexts.deleteCard.confirmation.SUB_TITLE}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          closeFunc={handleCloseModalNoConfirm}
        >
          <div className={Styles.buttons}>
            <Button newStyles={Styles.button} onClickFunc={handleCloseModalNoConfirm}>
              Отмена
            </Button>
            <Button onClickFunc={handleCloseModalConfirm}>Удалить</Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
