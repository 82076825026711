import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { authAndRegSelector } from "../../redux/profile/authAndReg/selectors";

import styles from "./ProfilesListIcon.module.scss";
import profilesListIcon from "../../assets/icons/profilesListIcon.svg";
import profilesListIconDisabled from "../../assets/icons/profilesListIconDisabled.svg";
import { ProfilesListIconPropsType } from "./types";

export const ProfilesListIcon: React.FC<ProfilesListIconPropsType> = ({ formData }) => {
  const { auth } = useSelector(authAndRegSelector);
  const { icon, disabled } = styles;

  return (
    <Link
      className={auth ? icon : `${icon} ${disabled}`}
      to="/subscriptions-profiles"
      state={{ formData }}
    >
      <img src={auth ? profilesListIcon : profilesListIconDisabled} alt="profiles list" />
    </Link>
  );
};
