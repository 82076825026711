import React from "react";
import { Button } from "../../../../components";

import styles from "./OfferItem.module.scss";

export const OfferItem = (props: any) => {
  const { title, description, onClick } = props;

  return (
    <div className={styles.item}>
      <h3 className={styles.title}>{title}</h3>
      <Button newStyles={styles.button} onClickFunc={onClick}>
        Оставить заявку
      </Button>
      <p className={styles.description}>{description}</p>
    </div>
  );
};
