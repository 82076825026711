import React from "react";
import { useSelector } from "react-redux";
import {useLocation, useNavigate } from "react-router-dom";

import { Button, Header, Preloader, Title } from "../../../components";
import { SubscrItem } from "../components";

import { useAppDispatch } from "../../../redux/store";
import { fetchGetSubscriptions } from "../../../redux/subscriptions/asyncActions";
import { subscriptionsSelector } from "../../../redux/subscriptions/selectors";

import styles from "./Subscriptions.module.scss";

import { SubscrPropsType } from "./types";
import { ISubscription } from "../../../redux/subscriptions/types";
import {
  removeSelectedAdultSubscrFromStorage,
  removeSelectedFamilySubscrFromStorage,
  removeSelectedKidSubscrFromStorage,
  removeSubscrFormsDataFromStorage,
  setSelectedAdultSubscrToStorage,
  setSelectedFamilySubscrToStorage,
  setSelectedKidSubscrToStorage,
} from "../../../utils";
import {
  resetSelectedAdultSubscr,
  resetSelectedFamilySubscr,
  resetSelectedKidSubscr,
  resetSubscrFormsData,
} from "../../../redux/subscriptions/slice";

export const Subscriptions: React.FC<SubscrPropsType> = ({ title }) => {
  const { subscriptions, selectedAdultSubscr, selectedKidSubscr, selectedFamilySubscr, isLoading } =
    useSelector(subscriptionsSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    removeSelectedAdultSubscrFromStorage();
    removeSelectedKidSubscrFromStorage();
    removeSelectedFamilySubscrFromStorage();
    removeSubscrFormsDataFromStorage();
    dispatch(resetSubscrFormsData());
    dispatch(resetSelectedAdultSubscr());
    dispatch(resetSelectedKidSubscr());
    dispatch(resetSelectedFamilySubscr());
    dispatch(fetchGetSubscriptions()); // eslint-disable-next-line
  }, []);

  const subscriptionsList = rendersubscriptionsList(subscriptions);

  function rendersubscriptionsList(subscriptions: ISubscription[]) {
    return (
      <div className={styles.subscrItems}>
        {subscriptions.map((item: ISubscription, i: number) => (
          <SubscrItem key={i} data={item} />
        ))}
      </div>
    );
  }

  const handleNextPage = () => {
    setSelectedAdultSubscrToStorage(JSON.stringify(selectedAdultSubscr));
    setSelectedKidSubscrToStorage(JSON.stringify(selectedKidSubscr));
    setSelectedFamilySubscrToStorage(JSON.stringify(selectedFamilySubscr));

    navigate("/subscriptions-forms");
  };

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>
          {isLoading ? <Preloader /> : subscriptionsList}
          <Button
            newStyles={styles.button}
            onClickFunc={handleNextPage}
            disabled={!selectedAdultSubscr.length && !selectedFamilySubscr.length}
          >
            Далее
          </Button>
        </section>
      </main>
    </>
  );
};
