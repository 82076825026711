import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Slider } from "../../../../components";

import { useAppDispatch } from "../../../../redux/store";
import {
  resetLastSelectedHall,
  setLastSelectedHall,
} from "../../../../redux/halls/slice";

import styles from "./HallsItem.module.scss";

import {
  removeLastSelectedHallFromStorage,
  setLastSelectedHallToStorage,
} from "../../../../utils/storage/halls";
import { HallsItemPropsType } from "./types";

export const HallsItem: React.FC<HallsItemPropsType> = ({ images, data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const images1 = data.images.map((image: { url: string }) => {
    return image.url;
  });

  React.useEffect(() => {
    dispatch(resetLastSelectedHall());
    removeLastSelectedHallFromStorage();
    // eslint-disable-next-line
  }, []);

  const handleChooseHall = () => {
    dispatch(setLastSelectedHall(data));
    setLastSelectedHallToStorage(data);
    navigate("/halls-calendar");
  };

  return (
    <div className={styles.item}>
      <div className={styles.img}>
        {/* <div className={styles.slider}> */}
        <Slider images={images1} />
        {/* </div> */}

        {/* <img src={data.image_url} alt="hall" /> */}
      </div>
      <h3 className={styles.title}>{data.title}</h3>
      <Button newStyles={styles.button} onClickFunc={handleChooseHall}>
        Арендовать
      </Button>
    </div>
  );
};
