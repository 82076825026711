import React from "react";
import Styles from "./MyProfiles.module.scss";
import profileIcon from "../../assets/icons/profileIconFitness.svg";
import trashIcon from "../../assets/icons/trashIconFitnes.svg";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { ModalTexts } from "../../resources/locale";
import { Button, Modal } from "../../components";
import { IProfileItem } from "./types";
import {
  fetchDelProfiles,
  fetchMyProfiles,
} from "../../redux/profile/profileInfo/asyncActions";
import { getProfileInfoMenu } from "../../redux/profile/profileInfo/selectors";
import { setDeleteProfile } from "../../redux/profile/profileInfo/slice";
import { IMyprofile } from "../../redux/profile/profileInfo/types";

export const MyProfileItem: React.FC<IProfileItem> = (props) => {
  const { onPage = "profile", selectFunc } = props;
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { deleteProfile } = useSelector(getProfileInfoMenu);
  const handleCloseModalNoConfirm = () => {
    setIsOpenModal(false);
    dispatch(fetchMyProfiles);
  };

  const handleCloseModalConfirm = async () => {
    await dispatch(fetchDelProfiles(deleteProfile));
    setIsOpenModal(false);
    await dispatch(fetchMyProfiles());
  };

  function handleRemove(data: IMyprofile) {
    setIsOpenModal(true);
    dispatch(setDeleteProfile(data));
  }

  const handleSelectProfile = () => {
    if (onPage === "payment" && selectFunc) {
      selectFunc(props.data);
    }
  };

  return (
    <>
      {props.data.deleted !== 1 ? (
        <div
          className={Styles.item}
          data-type={props.dataType}
          onClick={handleSelectProfile}
        >
          <img className={Styles.img} src={profileIcon} />
          <div>
            <div className={Styles.title}>
              {props.data.is_self === 1 ? "Вы" : props.data.name}
            </div>
            <div className={Styles.content}>
              <div className={Styles.text}>
                {props.data.type === "adult" ? "Номер телефона:" : "Дата рождения:"}
              </div>
              <div className={`${Styles.text_phone}`}>
                {props.data.type === "adult" ? props.data.phone : props.data.birthday}
              </div>
            </div>
          </div>
          {onPage === "profile" && props.data.is_self === 0 ? (
            <div
              onClick={() => handleRemove(props.data)}
              className={`${Styles.item_trash}`}
            >
              <img src={trashIcon} />
            </div>
          ) : null}
        </div>
      ) : null}
      {isOpenModal ? (
        <Modal
          title={ModalTexts.deleteProfile.confirmation.TITLE}
          subTitle={ModalTexts.deleteProfile.confirmation.SUB_TITLE}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          closeFunc={handleCloseModalNoConfirm}
        >
          <div className={Styles.buttons}>
            <Button newStyles={Styles.button} onClickFunc={handleCloseModalNoConfirm}>
              Отмена
            </Button>
            <Button onClickFunc={handleCloseModalConfirm}>Удалить</Button>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
