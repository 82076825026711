import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../../components";

import styles from "./footer.module.scss";
import logo from "../../assets/img/logo.svg";

import { FooterMenuItemsType } from "./types";

const footerMenuItems: FooterMenuItemsType = [
  { id: "subscriptions", name: "Абонементы", href: "/subscriptions" },
  { id: "rentHalls", name: "Аренда залов", href: "/halls" },
  { id: "about", name: "О клубе", href: "/about" },
  { id: "contacts", name: "Контакты", href: "contacts" },
  { id: "rules", name: "Правила клуба", href: "rules" },
  { id: "offer", name: "Оферта", href: "offer" },
  { id: "privacy", name: "Политика конфиденциальности", href: "politics" },
];

export const Footer: React.FC = () => {
  const footerMenu = renderFooterMenu(footerMenuItems);

  function renderFooterMenu(menuItems: FooterMenuItemsType) {
    return menuItems.map(({ id, name, href }) => (
      <li key={id}>
        <Link to={href}>{name}</Link>
      </li>
    ));
  }

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="Company's logo" />
            </Link>
          </div>
          <nav className={styles.menu}>
            <ul className={styles.menu__list}>{footerMenu}</ul>
          </nav>
          <div className={styles.contacts}>
            <p className={styles.phone}>
              <a href="tel:+7 (495) 150-55-99">+7 (495) 150-55-99</a>
            </p>
            <p className={styles.address}>
              <a 
                href="https://yandex.ru/maps/org/tennis_ru/1341065211/?ll=37.410421%2C55.570022&z=18.54" 
                target="_blank" rel="noreferrer"> г. Москва, ул. Летовская Малая, 8</a>
            </p>
            <Button newStyles={styles.button} to={"contacts"}>
              Обратная связь
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};
