import React from "react";
import { useSelector } from "react-redux";

import { changePassSelector } from "../../../../redux/profile/settings/changePass/selectors";
import { setShowForm } from "../../../../redux/profile/settings/changePass/slice";
import { useAppDispatch } from "../../../../redux/store";

import { Button } from "../../../../components";

import styles from "./MessageForm.module.scss";
import noticeImage from "../../../../assets/img/noticeImg.png";

import { FormNames } from "../../../../resources/constants";

export const MessageForm: React.FC = () => {
  const { phone } = useSelector(changePassSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(setShowForm(FormNames.CODE));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageBlock}>
        <img src={noticeImage} alt="notice" />
      </div>
      <p className={styles.subTitle}>Проверьте свои смс</p>
      <p className={styles.description}>
        На номер телефона <span>{phone}</span> было выслано сообщение с кодом
      </p>
      <Button newStyles={styles.button} onClickFunc={handleSubmit}>
        Далее
      </Button>
    </div>
  );
};
