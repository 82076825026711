import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../resources/api";

import {INews} from "./types";

export const fetchGetNews = createAsyncThunk<INews[], number>(
  "getNews/fetchGetNews",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.news.GET_NEWS(data)}`;
      const response = await axios.get(url);
      return response.data;

    } catch (error: any) {
      console.log("some error", error);
    }
  }
);

