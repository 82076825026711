export const getRentDateFromStorage = () => {
  return sessionStorage.getItem("rentDate");
};

export const removeRentDateFromStorage = () => {
  sessionStorage.removeItem("rentDate");
};

export const setRentDateToStorage = (date: string) => {
  sessionStorage.setItem("rentDate", date);
};
