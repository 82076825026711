import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../../resources/api";

import { IPayAll, IPayToken, IResponseData } from "./types";
import { getTokenFromStorage } from "../../../utils";

export const fetchGetToken = createAsyncThunk<IPayToken>(
  "getToken/fetchGetToken",
  async () => {
    try {
      const url = `${api.BASE_URL}${api.profile.cards.GET_TOKEN}`;
      const token = getTokenFromStorage();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {}
  }
);

export const fetchGetWebPayToken = createAsyncThunk<IResponseData, IPayAll>(
  "getToken/fetchGetWebPayToken",
  async (data) => {
    try {
      console.log("data>>>", data);

      const url = `${api.BASE_URL}${api.payment.CREATE_PAYMENT_LINKED_CARD}`;
      console.log(url);
      const token = getTokenFromStorage();
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("url fetchGetWebPayToken>>>", url);
      console.log("response fetchGetWebPayToken>>>", response);
      return response.data;
    } catch (error: any) {
      console.log("fetchGetWebPayToken error >>>", error);
    }
  }
);
