import React from "react";
import { useSelector } from "react-redux";

import { setShowForm, reset } from "../../redux/profile/settings/changePass/slice";
import { useAppDispatch } from "../../redux/store";
import { changePassSelector } from "../../redux/profile/settings/changePass/selectors";

import { Modal, CommonErrorModal, Button } from "../../components";
import { PhoneForm, MessageForm, SmsCodeForm, NewPasswordForm } from "./Forms";

import styles from "./PassChangeMockPage.module.scss";

import { ModalTexts } from "../../resources/locale";
import { FormNames } from "../../resources/constants";

import { PassChangePropsType } from "./types";
import { SmsRegCodeForm } from "./Forms/SmsReg/SmsReg";

export const PassChangeModal: React.FC<PassChangePropsType> = (props) => {
  const { isOpen, setIsOpen, title, subTitle } = props;

  const { showForm } = useSelector(changePassSelector);
  const dispatch = useAppDispatch();

  const modalRenderController = (showForm: string) => {
    switch (showForm) {
      case FormNames.PHONE:
        return (
          <Modal
            title={title}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <PhoneForm />
          </Modal>
        );
      case FormNames.MESSAGE:
        return (
          <Modal
            title={ModalTexts.changePass.confirmMessage.TITLE}
            subTitle={ModalTexts.changePass.confirmMessage.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <MessageForm />
          </Modal>
        );
      case FormNames.CODEREG:
        return (
          <Modal
            title={ModalTexts.changePass.confirmMessage.TITLE}
            subTitle={ModalTexts.changePass.confirmMessage.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <SmsRegCodeForm />
          </Modal>
        );
      case FormNames.CODE:
        return (
          <Modal
            title={ModalTexts.changePass.inputSmsCode.TITLE}
            subTitle={ModalTexts.changePass.inputSmsCode.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <SmsCodeForm />
          </Modal>
        );
      case FormNames.PASSWORD:
        return (
          <Modal
            title={ModalTexts.changePass.inputNewPassword.TITLE}
            subTitle={ModalTexts.changePass.inputNewPassword.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <NewPasswordForm />
          </Modal>
        );
      case FormNames.PASSWORD_APPLY:
        return (
          <Modal
            title={ModalTexts.changePass.passwordApply.TITLE}
            subTitle={ModalTexts.changePass.passwordApply.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <Button newStyles={styles.button} onClickFunc={handleEndChangePassProcess}>
              Ок
            </Button>
          </Modal>
        );
      case FormNames.REGAPPLY:
        return (
          <Modal
            title={ModalTexts.changePass.passwordApply.TITLE}
            subTitle={ModalTexts.changePass.passwordApply.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <Button newStyles={styles.button} onClickFunc={handleEndChangePassProcess}>
              Ок
            </Button>
          </Modal>
        );
      case FormNames.ERROR:
        return (
          <CommonErrorModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          />
        );
      case FormNames.ERROR_PHONE:
        return (
          <Modal
            title={ModalTexts.changePass.errorPhone.TITLE}
            subTitle={ModalTexts.changePass.errorPhone.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <Button newStyles={styles.button} onClickFunc={handleSetPhoneForm}>
              Назад
            </Button>
          </Modal>
        );
      case FormNames.ERROR_CODE:
        return (
          <Modal
            title={ModalTexts.changePass.errorCode.TITLE}
            subTitle={ModalTexts.changePass.errorCode.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            closeFunc={handleEndChangePassProcess}
          >
            <Button newStyles={styles.button} onClickFunc={handleSetCodeForm}>
              Назад
            </Button>
          </Modal>
        );
      default:
        return null;
    }
  };

  const handleSetPhoneForm = () => {
    dispatch(setShowForm(FormNames.PHONE));
  };

  const handleSetCodeForm = () => {
    dispatch(setShowForm(FormNames.CODE));
  };

  const handleEndChangePassProcess = () => {
    setIsOpen(false);
    dispatch(reset());
  };

  // const handleStartChangePassProcess = () => {
  //   setIsOpen((isOpen) => !isOpen);
  //   dispatch(setShowForm(FormNames.PHONE));
  // };

  const currentModal = modalRenderController(showForm);

  return <>{currentModal}</>;
};
