import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { useSelector } from "react-redux";

import {
  NameInput,
  PhoneInput,
  EmailInput,
  TextareaInput,
  Button,
} from "../../../../components";

import { useAppDispatch } from "../../../../redux/store";
import { fetchPersonalTrainingOrder } from "../../../../redux/school/asyncActions";
import { schoolSelector } from "../../../../redux/school/selectors";

import styles from "./TennisSchoolForm.module.scss";
import phoneIcon from "../../../../assets/icons/phone.png";
import profileIcon from "../../../../assets/icons/profile.svg";
import emailIcon from "../../../../assets/icons/emailIcon.svg";

import { ValidMessages } from "../../../../resources/locale";
import { IPersonalTrainData } from "../../../../redux/school/types";
import { PersonalTrainingsPropsType } from "./types";

export const PersonalTrainingForm: React.FC<PersonalTrainingsPropsType> = ({
  onClickFunc,
}) => {
  const { isLoading, isErrorFetch } = useSelector(schoolSelector);
  const dispatch = useAppDispatch();

  const initialValues: IPersonalTrainData = {
    name: "",
    phone: "",
    email: "",
    additional: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(ValidMessages.inputs.name.REQUIRED),
    email: Yup.string().email(ValidMessages.inputs.email.INCORRECT),
    phone: Yup.string()
      .min(18, ValidMessages.inputs.phone.NOT_FULL)
      .required(ValidMessages.inputs.phone.REQUIRED),
  });

  const handleSubmit = async (values: IPersonalTrainData) => {
    const cloneValues = Object.assign({}, values);
    cloneValues.phone = cloneValues.phone.replace(/[-()\s]/g, "");

    await dispatch(fetchPersonalTrainingOrder(cloneValues));
    if (!isErrorFetch) onClickFunc("personal-trainings-success");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles.formSection}>
              <label htmlFor="name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput name="name" placeholder="Ваше имя" />
              <ErrorMessage component="div" className={styles.errorMessage} name="name" />
            </div>

            <div className={styles.formSection}>
              <label htmlFor="phone">
                <img src={phoneIcon} alt="phone icon" />
              </label>
              <PhoneInput name="phone" placeholder="Номер телефона" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="phone"
              />
            </div>

            <div className={styles.formSection}>
              <label htmlFor="email">
                <img src={emailIcon} alt="email icon" />
              </label>
              <EmailInput name="email" placeholder="Email" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="email"
              />
            </div>

            <div className={styles.formSection_textarea}>
              <TextareaInput name="additional" placeholder="Ваши дополнения" />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="additional"
              />
            </div>
            {isLoading ? (
              <div className={styles.loadingMessage}>данные отправляются</div>
            ) : null}
            <Button
              type="submit"
              newStyles={styles.button}
              disabled={formik.isSubmitting}
            >
              Отправить
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
