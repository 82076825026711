import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Button, Header, Slider } from "../../components";

import { useAppDispatch } from "../../redux/store";
import { fetchGetClubImages } from "../../redux/images/asyncActions";
import { imagesSelector } from "../../redux/images/selectors";

import styles from "./MainPage.module.scss";
import preloader from "../../assets/img/load.gif";

import { hallImages } from "../../resources/constants";
import { MainPagePropsType } from "./types";
import { IClubImage } from "../../redux/images/types";

export const MainPage: React.FC<MainPagePropsType> = ({ title }) => {
  const { clubImages, isLoading } = useSelector(imagesSelector);
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    dispatch(fetchGetClubImages()); // eslint-disable-next-line
  }, []);

  const imagesClubArray = clubImages.map((imageItem: IClubImage) => {
    return imageItem.url;
  });

  const imagesHallArray = Object.values(hallImages);

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container container--mobileFull">
          <p className={styles.subTitile}>
            Фитнес клуб с бассейном всего за 8 000 руб в месяц. Оплата в один клик!
          </p>
          <div className={styles.buttonWrap}>
            <Button newStyles={styles.button} to="/subscriptions">
              Купить абонемент
            </Button>
          </div>
          <div className={styles.slider}>
            {isLoading ? <Preloader /> : <Slider newStyle={'slider_big_img'} images={imagesClubArray} />}
          </div>

          <Link to="/about/clubFoto" className={styles.moreFotosLink}>
            Больше фото
          </Link>
          <div className={styles.buttonWrap}>
            <Button newStyles={styles.button} to="/halls">
              Аренда залов
            </Button>
          </div>
          {isLoading ? <Preloader /> : <Slider newStyle={'slider_big_img'} images={imagesHallArray} />}
        </section>
      </main>
    </>
  );
};

const Preloader: React.FC = () => {
  return <img className={styles.preloader} src={preloader} alt="loading" />;
};
