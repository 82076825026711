import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../resources/api";

import { IClubImage } from "./types";

export const fetchGetClubImages = createAsyncThunk<IClubImage[]>(
  "images/fetchGetClubImages",
  async () => {
    try {
      const url = `${api.BASE_URL}${api.main.GET_CLUB_IMAGES}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      console.log("fetchGetClubImages error >>>", error);
      return error.message;
    }
  }
);

// export const fetchGetHallImages = createAsyncThunk<any>(
//   "images/fetchGetHallImages",
//   async () => {
//     try {
//       const url = `${api.BASE_URL}${api.main.GET_HALLS_IMAGES}`;
//       // const token = getTokenFromStorage();

//       const response = await axios.get(url);

//       console.log("fetchGetHallImages resp >>>", response);
//       return response.data;
//       // return response.status === 200 && response.statusText === "OK"
//       //   ? "Success"
//       //   : "Error";
//     } catch (error: any) {
//       console.log("fetchGetHallImages resp >>>", error);
//       return error.message;
//     }
//   }
// );
