import React from "react";
import dateFormat, { i18n } from "dateformat";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../redux/store";
import { setDate } from "../../../../redux/halls/slice";
import { hallsSelector } from "../../../../redux/halls/selectors";

import styles from "./Navigate.module.scss";
import arrowLeftIcon from "../../../../assets/icons/arrowCalendarLeft.svg";
import arrowRightIcon from "../../../../assets/icons/arrowCalendarRight.svg";

import { dayNamesForRent } from "../../../../resources/locale";
import { setDateToStorage } from "../../../../utils/storage/halls";

i18n.dayNames = dayNamesForRent;

export const Navigation: React.FC = () => {
  const { date } = useSelector(hallsSelector);
  const dispatch = useAppDispatch();

  const currentDate = renderCurrentDate(date);
  const prevDate = dateFormat(getPrevDate(date), "ddd").toUpperCase();
  const nextDate = dateFormat(getNextDate(date), "ddd").toUpperCase();

  const disablePrevButton = React.useMemo(() => {
    const prevDate = new Date(getPrevDate(date)).setHours(0, 0, 0, 0);
    const nowDate = new Date().setHours(0, 0, 0, 0);

    return prevDate < nowDate;
  }, [date]);

  function renderCurrentDate(date: string) {
    return (
      <>
        <span className={styles.navigate_currentDay_name}>
          {`${dateFormat(date, "dddd")}, `}
        </span>
        <span className={styles.navigate_currentDay_date}>
          {`${dateFormat(date, "dd.mm.yyyy")}`}
        </span>
      </>
    );
  }

  function getPrevDate(date: string) {
    const currentDay = new Date(Date.parse(date));
    return currentDay.setDate(currentDay.getDate() - 1);
  }

  function getNextDate(date: string) {
    const currentDay = new Date(Date.parse(date));
    return currentDay.setDate(currentDay.getDate() + 1);
  }

  const handlePrevDate = () => {
    const prevDate = dateFormat(getPrevDate(date), "isoDate");
    setDateToStorage(prevDate);
    dispatch(setDate(prevDate));
  };

  const handleNextDate = () => {
    const nextDate = dateFormat(getNextDate(date), "isoDate");
    setDateToStorage(nextDate);
    dispatch(setDate(nextDate));
  };

  return (
    <div className={styles.navigate}>
      <button
        className={styles.navigate_button}
        disabled={disablePrevButton}
        onClick={handlePrevDate}
      >
        <img src={arrowLeftIcon} alt="prev day" />
        <div className={styles.navigate_button_name}>{prevDate}</div>
      </button>
      <div className={styles.navigate_currentDay}>{currentDate}</div>
      <button className={styles.navigate_button} onClick={handleNextDate}>
        <img src={arrowRightIcon} alt="next day" />
        <div className={styles.navigate_button_name}>{nextDate}</div>
      </button>
    </div>
  );
};
