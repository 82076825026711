export const MetaSettings = {
  "/":{
    title:'',
  },
  "/halls":{
    title:'Аренда залов'
  },
  "/subscriptions":{
    title:'Абонементы'
  },
  "/subscriptions-forms":{
    title:'Данные пользователей'
  },
  "/halls-calendar":{
    title:'Аренда залов'
  },
  "/politics":{
    title:'Политика конфиденциальности'
  },
  "/offer":{
    title:'Оферта'
  },
  "/halls-time":{
    title:'Аренда залов'
  },
  "/rules":{
    title:'Правила клуба'
  },
  "/contacts":{
    title:'Контакты'
  },
  "/cart":{
    title:'Корзина'
  },
  "/profile/myprofiles":{
    title:'Мой профиль'
  },
  "/profile/subscriptions":{
    title:'Мои абонименты'
  },
  "/profile/halls":{
    title:'Арендованные залы'
  },
  "/profile/history":{
    title:'История покупок'
  },
  "/profile/settings":{
    title:'Настройка'
  },
  "/profile/cards":{
    title:'Мои привязанные карты'
  },
  "/payment-error":{
    title:'Оплата не прошла'
  },
  "/payment":{
    title:'Оплата'
  },
  "/payment-success":{
    title:'Оплата не прошла'
  },
  "/about/clubFoto":{
    title:'Фото клуба'
  },
  "/about/hallsFoto":{
    title:'Фото залов'
  },
  "/about/hallsFoto/morePhoto":{
    title:'Больше фото'
  },
  "/profile":{
    title:'Профиль'
  },
}
