import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../resources/api";
import { IPersonalTrainData, ITennisSchoolData } from "./types";

export const fetchTennisSchoolOrder = createAsyncThunk<string, ITennisSchoolData>(
  "school/fetchTennisSchoolOrder",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.school.TENNIS_SCHOOL}`;
      const response = await axios.post(url, data);

      return response.status === 200 && response.statusText === "OK"
        ? "Success"
        : "Error";
    } catch (error: any) {
      return "Error";
    }
  }
);

export const fetchPersonalTrainingOrder = createAsyncThunk<string, IPersonalTrainData>(
  "school/personalTrainingOrder",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.school.PERSONAL_TRAINING}`;
      const response = await axios.post(url, data);

      return response.status === 200 && response.statusText === "OK"
        ? "Success"
        : "Error";
    } catch (error) {
      return "Error";
    }
  }
);
