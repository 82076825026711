import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IPurchase} from "./types";
import {fetchPurchaseHistory} from "./asyncActions";

const initialState: IPurchase = {
    purchaseItems: [],
    isLoading: false
}

const GetHistory = createSlice({
  name: "getSubscriptions",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
      builder.addCase(fetchPurchaseHistory.pending, (state) => {
          state.isLoading = true;
      })
      builder.addCase(
          fetchPurchaseHistory.fulfilled,
          (state, action: PayloadAction<[]>) => {
              state.purchaseItems = action.payload
              state.isLoading = false;
          }
      );
  },
});

export default GetHistory.reducer;

