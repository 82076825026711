import React from "react";
import { useSelector } from "react-redux";

import { hallsSelector } from "../../../../redux/halls/selectors";
import { addCurrencyWord, addNumWord } from "../../../../utils";

import styles from "./Price.module.scss";

export const Price: React.FC = () => {
  const { date, lastSelectedHall, selectedHalls } = useSelector(hallsSelector);

  const quantity = getQuantity();
  const price = getPrice();

  function getQuantity() {
    const quantity = Object.values(selectedHalls).reduce((acc, item) => {
      return item.hallId === lastSelectedHall?.id && item.date === date
        ? (acc += 1)
        : acc;
    }, 0);
    return addNumWord(quantity, ["час", "часа", "часов"]);
  }

  function getPrice() {
    const price = Object.values(selectedHalls).reduce((acc, item) => {
      return item.hallId === lastSelectedHall?.id && item.date === date
        ? (acc += item.price)
        : acc;
    }, 0);
    return addCurrencyWord(price);
  }

  return (
    <div className={styles.price}>
      {quantity === "0 часов" ? (
        <p>Вы еще не выбрали время для данного зала</p>
      ) : (
        <p>
          Вы выбрали <span>{quantity}</span> на сумму:{" "}
          <span className={styles.value}>{price}</span>
        </p>
      )}
    </div>
  );
};
