import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../resources/api";
import { IFeedbackData } from "./types";

export const fetchFeedback = createAsyncThunk<string, IFeedbackData>(
  "feedback/fetchFeedback",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.feedback.FEEDBACK}`;
      const response = await axios.post(url, data);
      return response.status === 200 && response.statusText === "OK"
        ? "Success"
        : "Error";
    } catch (error: any) {
      return "Error";
    }
  }
);
