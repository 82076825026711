import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { api } from "../../resources/api";

import { IArena, IRacket } from "./types";

export const fetchGetArenas = createAsyncThunk<IArena[], string>(
  "rent/fetchGetArenas",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.rent.courts.GET_COURTS(data)}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.log("error fetchGetArenas >>>", error);
    }
  }
);

export const fetchGetRackets = createAsyncThunk<IRacket[], string>(
  "rent/fetchGetRackets",
  async (data) => {
    try {
      const url = `${api.BASE_URL}${api.rent.rackets.GET_RACKETS(data)}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.log("error fetchGetRackets >>>", error);
    }
  }
);
