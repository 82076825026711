import React from "react";
import { ErrorMessage, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import * as Yup from "yup";

import { Button, NameInput, DateInput, ProfilesListIcon } from "../../components";

import { editProfile } from "../../redux/profile/profileInfo/slice";
import { useAppDispatch } from "../../redux/store";

import styles from "./ProfileKidForm.module.scss";
import profileIcon from "../../assets/icons/profileInput.svg";
import dateIcon from "../../assets/icons/age.svg";

import { ProfileKidFormPropsType, ValuesType } from "./type";
import { ValidMessages } from "../../resources/locale";

export const ProfileKidForm: React.FC<ProfileKidFormPropsType> = (props) => {
  const {
    page,
    onSubmitFunc,
    onChangeFunc,
    close,
    isFilledFormsTest,
    profilesListIcon = false,
    formData,
    profileValues,
  } = props;

  const dispatch = useAppDispatch();

  const initialValues: ValuesType = {
    name: profileValues?.name || "",
    birthday: profileValues?.secondaryText || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(4, ValidMessages.inputs.name.NOT_FULL)
      .required(ValidMessages.inputs.name.REQUIRED),
    birthday: Yup.string()
      .min(10, ValidMessages.inputs.date.NOT_FULL)
      .required(ValidMessages.inputs.date.REQUIRED),
  });

  const handleSubmit = (values: ValuesType, actions: FormikHelpers<ValuesType>) => {
    dispatch(editProfile(values));
    if (close) {
      close(values);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        if (isFilledFormsTest)
          formik.touched = {
            name: true,
            birthday: true,
          };
        return (
          <Form>
            <GoValidateFunc isFilledFormsTest={isFilledFormsTest} />
            <div className={styles.formSection}>
              <label htmlFor="name">
                <img src={profileIcon} alt="name icon" />
              </label>
              <NameInput
                name="name"
                placeholder="ФИО"
                onChangeFunc={onChangeFunc}
                isFilledFormsTest={isFilledFormsTest}
              />
              <ErrorMessage component="div" className={styles.errorMessage} name="name" />
              {profilesListIcon ? <ProfilesListIcon formData={formData} /> : null}
            </div>

            <div className={styles.formSection}>
              <label htmlFor="birthday">
                <img src={dateIcon} alt="birthdate icon" />
              </label>
              <DateInput
                name="birthday"
                placeholder="Дата рождения (ДД-ММ-ГГГГ)"
                onChangeFunc={onChangeFunc}
                isFilledFormsTest={isFilledFormsTest}
              />
              <ErrorMessage
                component="div"
                className={styles.errorMessage}
                name="birthday"
              />
            </div>

            {page === "profile" ? (
              <Button
                type="submit"
                newStyles={styles.button}
                disabled={
                  // !formik.dirty ||
                  formik.isSubmitting
                }
              >
                Сохранить профиль
              </Button>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

const GoValidateFunc = (props: { isFilledFormsTest: boolean | undefined }) => {
  const { validateForm } = useFormikContext();

  React.useEffect(() => {
    if (props.isFilledFormsTest) validateForm(); // eslint-disable-next-line
  }, [props.isFilledFormsTest]);

  return null;
};
