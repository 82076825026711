import React from "react";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";

import { cartSelector } from "../../redux/cart/selectors";
import { setCartItems } from "../../redux/cart/slice";
import { useAppDispatch } from "../../redux/store";
import { hallsSelector } from "../../redux/halls/selectors";
import { subscriptionsSelector } from "../../redux/subscriptions/selectors";
import { rerenderSelector } from "../../redux/rerender/selectors";
import { setSelectedHalls } from "../../redux/halls/slice";
import { setSelectedSubscr } from "../../redux/subscriptions/slice";

import { Button, Header, LoginModal, Modal, Title } from "../../components";
import { CartItem, AcceptTermsForm } from "./components";

import {
  addCurrencyWord,
  getSelectedSubscrFromStorage,
  setCartItemsToStorage,
  setTotalPriceToStorage,
} from "../../utils";
import { getSelectedHallsFromStorage } from "../../utils/storage/halls";
import { ModalTexts } from "../../resources/locale";

import styles from "./Cart.module.scss";

import { CartPropsType, ITotalCartItems } from "./types";
import { ISelectedSubscr } from "../../redux/subscriptions/types";
import { ISelectedHall } from "../../redux/halls/types";
import { useLocation } from "react-router-dom";

export const Cart: React.FC<CartPropsType> = ({ title }) => {
  const { totalCartItems } = useSelector(cartSelector);
  const { selectedHalls } = useSelector(hallsSelector);
  const { selectedSubscr } = useSelector(subscriptionsSelector);
  const { rerender } = useSelector(rerenderSelector);
  const [isOpenModalNoAdultSbscr, setIsOpenModalNoAdultSbscr] = React.useState(false);
  const [isOpenModalNoAuth, setIsOpenModalNoAuth] = React.useState(false);
  const dispatch = useAppDispatch();

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    const storageSelectedHalls = getSelectedHallsFromStorage();
    const storageSelectedSubscr = getSelectedSubscrFromStorage();

    if (storageSelectedHalls) dispatch(setSelectedHalls(storageSelectedHalls));
    if (storageSelectedSubscr) dispatch(setSelectedSubscr(storageSelectedSubscr));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setTotalCartItems();
  }, [selectedHalls, selectedSubscr]);

  React.useEffect(() => {}, [rerender]);

  const cartItems = renderCartItems(totalCartItems);
  const totalPrice = getTotalPrice(totalCartItems);
  const isCartEmpty = testCartEmpty(totalCartItems);

  const setTotalCartItems = () => {
    const totalCartItems = {
      seats: { ...selectedHalls },
      subscriptions: { ...selectedSubscr },
    };

    dispatch(setCartItems(totalCartItems));
    setCartItemsToStorage(totalCartItems);
  };

  function renderCartItems(cartItems: ITotalCartItems) {
    let arrayCartItemElements: ISelectedSubscr[] | ISelectedHall[] | any = [];

    for (let field in cartItems) {
      if (Object.keys(cartItems[field]).length !== 0) {
        const currentItemsType = cartItems[field];

        const currentItems = Object.values(currentItemsType).map(
          (currentItem: ISelectedSubscr | ISelectedHall | any) => {
            let currentPrice: number = 0

            if (currentItem.subscription?.type === "family") {
              currentPrice = currentItem.subscription.price
            } else {
              currentPrice = currentItem.quantity
                ? currentItem.subscription.price * currentItem.quantity
                : currentItem.price;
            }

            const currentId = currentItem.subscription
              ? currentItem.subscription.id
              : currentItem.hallId;

            const currentKey = nanoid();

            return (
              <CartItem
                key={currentKey}
                itemId={currentId}
                itemType={field}
                date={currentItem?.date}
                time={currentItem?.time}
                quantity={currentItem?.quantity}
                expiration={currentItem?.subscription?.cart_expiration_text}
                price={currentPrice}
                title={
                  field === "seats"
                    ? currentItem.hallTitle
                    : currentItem.subscription?.title
                }
                setIsOpenModalNoAdultSbscr={setIsOpenModalNoAdultSbscr}
              />
            );
          }
        );

        arrayCartItemElements = arrayCartItemElements.concat(currentItems);
      }
    }

    return arrayCartItemElements;
  }

  function getTotalPrice(cartItems: ITotalCartItems | any) {
    let totalPrice = 0;
    for (let field in cartItems) {
      for (let key in cartItems[field]) {
        if (cartItems[field][key].subscription?.type === "family") {
          totalPrice += cartItems[field][key].subscription.price
        } else {
          totalPrice += cartItems[field][key].subscription
            ? cartItems[field][key].subscription.price * cartItems[field][key].quantity
            : cartItems[field][key].price;
        }
      }
    }

    setTotalPriceToStorage(totalPrice);
    return addCurrencyWord(totalPrice);
  }

  function testCartEmpty(cartItems: ITotalCartItems) {
    return !!(
      Object.keys(cartItems.subscriptions || {}).length ||
      Object.keys(cartItems.seats || {}).length
    );
  }

  const handleCloseModalNoAdultSbscr = () => setIsOpenModalNoAdultSbscr(false);

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>

          {isCartEmpty ? (
            <>
              <div className={styles.cartItems}>{cartItems}</div>
              <div className={styles.totalSum}>
                <span className={styles.totalSum_text}>Общая сумма: </span>
                <span className={styles.totalSum_price}>{totalPrice}</span>
              </div>
              <AcceptTermsForm
                totalPrice={totalPrice}
                setIsOpenModalNoAuth={setIsOpenModalNoAuth}
              />
            </>
          ) : (
            <div className={styles.cartEmpty}>Ваша корзина пуста!</div>
          )}
        </section>
      </main>

      {/* Modal - have no authorization */}
      {isOpenModalNoAuth ? (
        <LoginModal isOpen={isOpenModalNoAuth} setIsOpen={setIsOpenModalNoAuth} />
      ) : null}

      {/* Modal - have no adults subscr anymore */}
      {isOpenModalNoAdultSbscr ? (
        <Modal
          title={ModalTexts.cart.noAdultsSubscr.TITLE}
          subTitle={ModalTexts.cart.noAdultsSubscr.SUB_TITLE}
          isOpen={isOpenModalNoAdultSbscr}
          setIsOpen={setIsOpenModalNoAdultSbscr}
        >
          <Button newStyles={styles.button} onClickFunc={handleCloseModalNoAdultSbscr}>
            Ок
          </Button>
        </Modal>
      ) : null}
    </>
  );
};
