import React from "react";
import { useSelector } from "react-redux";

import { Button, CommonErrorModal, Header, Modal, Title } from "../../components";

import { feedbackSelector } from "../../redux/feedback/selectors";
import { FeedbackForm } from "./Forms/FeedbackForm";

import styles from "./Contacts.module.scss";
import { ContactsPropsType } from "./types";
import { ModalTexts } from "../../resources/locale";
import {MapDirection} from "../AboutPage/Components/Map/Map";
import { useLocation } from "react-router-dom";

export const Contacts: React.FC<ContactsPropsType> = ({ title }) => {
  const { status } = useSelector(feedbackSelector);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const confirmModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Header title={title} />
      <main>
        <section className="container">
          <Title>{title}</Title>
          <div className={styles.wrapper}>
            <div className={styles.info}>
              <div className={styles.info_item}>
                <div className={styles.info_item_label}>Телефон</div>
                <a href="tel:+7 (495) 150-55-99" className={`${styles.info_item_content} ` + `${styles.phone}`}>
                  +7 (495) 150-55-99
                </a>
              </div>
              <div className={styles.info_item}>
                <div className={styles.info_item_label}>Адрес</div>
                <a href="https://yandex.ru/maps/org/tennis_ru/1341065211/?ll=37.410421%2C55.570022&z=18.54"  target="_blank" className={`${styles.info_item_content} ` + `${styles.address}`} rel="noreferrer">
                  г. Москва, ул. Летовская Малая, 8
                </a>
              </div>
              <div className={styles.info_item}>
                <div className={styles.info_item_label}>Email</div>
                <a href="mailto:fitnessapp@tennis.ru" className={`${styles.info_item_content} ` + `${styles.email}`}>
                  fitnessapp@tennis.ru
                </a>
              </div>
            </div>
            <div className={styles.form}>
              <FeedbackForm onSubmitFunc={setIsOpen} />
            </div>
          </div>
          <div className={styles.map}>
            <div className={styles.map__wrapper}>
              <MapDirection/>
            </div>
            
          </div>
        </section>
      </main>
      {isOpen ? (
        status === "Success" ? (
          <Modal
            title={ModalTexts.feedbackSuccess.TITLE}
            subTitle={ModalTexts.feedbackSuccess.SUB_TITLE}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <Button newStyles={styles.button} onClickFunc={confirmModal}>
              Ок
            </Button>
          </Modal>
        ) : (
          <CommonErrorModal isOpen={isOpen} setIsOpen={setIsOpen} />
        )
      ) : null}
    </>
  );
};
