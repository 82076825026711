import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IHistoryState, IItem} from "./types";
import {fetchHistory} from "./asyncActions";

const initialState: IHistoryState = {
    items: [],
    isLoading: false
}

const GetCards = createSlice({
  name: "getSubscriptions",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    //get confirmToken
    builder.addCase(fetchHistory.pending, (state) => {
        state.isLoading = true;
    })
    builder.addCase(
        fetchHistory.fulfilled,
        (state, action: PayloadAction<IItem[]>) => {
            state.items = action.payload
            state.isLoading = false;
        }
    );
  },
});

export default GetCards.reducer;

