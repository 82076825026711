import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "./slider.scss";
import { SliderPropsType } from "./types";

interface SliderProps extends SliderPropsType {
    onImageClick?: (image: string) => void;
    newStyle?: 'slider_small_img' | 'slider_medium_img' | 'slider_big_img'
}

export const Slider: React.FC<SliderProps> = ({ images, onImageClick, newStyle = 'slider_small_img'}) => {
    const handleImageClick = (image: string) => {
        if (onImageClick) {
            onImageClick(image);
        }
    };

    return (
        <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={1}
            speed={1000}
            loop
            navigation
            className={newStyle}
        >
            {images.map((image: string, i: number) => (
                <SwiperSlide key={i}>
                    <img
                        src={image}
                        alt="club's foto"
                        onClick={() => handleImageClick(image)}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
