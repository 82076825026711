import React from "react";
import { Button } from "../../../../components";

import styles from "./PersonalTrainingsDescrForm.module.scss";
import { PersonalTrainingPropsType } from "./types";

export const PersonalTrainingsDescrForm: React.FC<PersonalTrainingPropsType> = ({
  onClickFunc,
}) => {
  const handleClick = () => onClickFunc("personal-trainings");

  return (
    <>
      <ul className={styles.list}>
        <li>Улучшение координации движений</li>
        <li>
          В большом теннисе задействованны все группы мышц и красивая спортивная фигура
          при систематических занятиях гарантирована
        </li>
        <li>Развития скорости и быстроты принятия решений</li>
      </ul>
      <Button newStyles={styles.button} onClickFunc={handleClick}>
        <span>Оставить заявку</span>
      </Button>
    </>
  );
};
