export const addNumWord = (value: number, words: string[]) => {
  const decadeValue = Math.abs(value) % 100;
  const num = value % 10;

  if (decadeValue > 10 && decadeValue < 20) return `${value} ${words[2]}`;
  if (num > 1 && num < 5) return `${value} ${words[1]}`;
  if (num === 1) return `${value} ${words[0]}`;
  return `${value} ${words[2]}`;
};

export const addCurrencyWord = (value: number) =>
  addNumWord(value, ["рубль", "рубля", "рублей"]);
