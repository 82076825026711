import React from "react";
import { useSelector } from "react-redux";


import styles from "./HallsPhoto.module.scss";
import {HallsPropsType} from "./types";
import {hallsSelector} from "../../../../redux/halls/selectors";
import {useAppDispatch} from "../../../../redux/store";
import {fetchGetHalls} from "../../../../redux/halls/asyncActions";
import {MediaBreakPoints} from "../../../../resources/constants";
import Styles from "./OurClub/OurClub.module.scss";
import {useCurrentWidth} from "../../../../hooks";
import {Slider} from "../../../../components";
import {useNavigate} from "react-router-dom";


export const HallsPhoto: React.FC<HallsPropsType> = ({ title }) => {
  const { halls } = useSelector(hallsSelector);
  const dispatch = useAppDispatch();
  const windowWidth = useCurrentWidth();
  const breakPoint = MediaBreakPoints.MOBILE_LARGE;
  const navigation = useNavigate()

  React.useEffect(() => {
    dispatch(fetchGetHalls());
  }, []);

  const handleImageClick = (hall: any) => {
    const selectedHall = halls.find((item) =>
        item.images.some((image) => image.url === hall)
    );

    if (selectedHall) {
      sessionStorage.setItem("lastSelectedHall", JSON.stringify(selectedHall));
      navigation("morePhoto");
    }
  };


  const renderHallsSlider = () => {
    return halls.map((hall) => {
      const { id, title, images } = hall;
      const photoLinks = images.map((photo) => photo.url);

      return (
          <div key={id} className={`${Styles.wrapper_heightSlider} ${Styles.wrapper}`}>
            <Slider images={photoLinks} onImageClick={handleImageClick} />
            <h3 className={styles.title}>{title}</h3>
          </div>
      );
    });
  };



  const renderSubTitle = () => {
    if (windowWidth > breakPoint) {
      return <p className={Styles.subTitile}>Фото спортивных залов</p>;
    }
    return null;
  };

  return (
      <div>
        {renderSubTitle()}
        <div className={styles.items}>
          {renderHallsSlider()}
        </div>
      </div>
  );
};
